import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SpartaxAuftragskunde } from 'src/app/data/dto/spartax/spartax-auftragskunde.dto';
import { SpartaxFormModelDto, SpartaxFormModelDtoUtil } from 'src/app/data/dto/spartax/spartax-form-model.dto';
import { SpartaxBehinderung, SpartaxBehinderungenName } from 'src/app/data/dto/spartax/spartax-kunde.dto';

@Component({
  selector: 'app-spartax-disabilities',
  templateUrl: './spartax-disabilities.component.html',
  styleUrls: ['./spartax-disabilities.component.scss'],
})
export class SpartaxDisabilitiesComponent implements OnInit {
  @Input() 
  model: SpartaxFormModelDto;

  @Output()
  modelChange = new EventEmitter<SpartaxFormModelDto>();

  @Input()
  edit: boolean;

  @ViewChild('formElement')
  ngForm!: NgForm;

  public readonly possibleBehinderungen = Object.keys(SpartaxBehinderung);

  ngOnInit() { }

  formIsValid() {
    return this.ngForm.valid;
  }

  markAllControlsAsTouched() {
    for (const control in this.ngForm.controls) {
      this.ngForm.controls[control].markAsTouched();
    }
  }

  getNameGridSize() {
    const size = Math.floor(8 / this.model.auftragskunden.length);
    if(size < 1){
      return 1;
    } else if (size >= 4){
      return 3;
    }
    return size;
  }

  getDescriptionGridSize() { 
    return 12 - this.getNameGridSize() * this.model.auftragskunden.length;
  }

  getDisplayNameOfBehinderung(Behinderung: SpartaxBehinderung | string) {
    return SpartaxBehinderungenName.get(Behinderung);
  }

  orderHasAnyChildren() {
    return this.model.kinder && this.model.kinder.length > 0
  }

  onBehinderungMitGutachtenChange(auftragskunde: SpartaxAuftragskunde, value: boolean) {
    auftragskunde.Kunde.BehinderungMitGutachten = value;
    if(!this.showVerschlechterterGesundheitszustandSubquestion(auftragskunde)) {
      auftragskunde.VerschlechterterGesunheitszustand = false;
    }
  }

  onBehindertenpassChange(auftragskunde: SpartaxAuftragskunde, value: boolean) {
    auftragskunde.Kunde.Behindertenpass = value;
    if(!this.showVerschlechterterGesundheitszustandSubquestion(auftragskunde)) {
      auftragskunde.VerschlechterterGesunheitszustand = false;
    }
  }

  onParkausweisChange(auftragskunde: SpartaxAuftragskunde, value: boolean) {
    auftragskunde.Kunde.Parkausweis = value;
    if(!this.showVerschlechterterGesundheitszustandSubquestion(auftragskunde)) {
      auftragskunde.VerschlechterterGesunheitszustand = false;
    }
  }

  onErhoehteFamilienBeihilfeChange(auftragskunde: SpartaxAuftragskunde, value: boolean) {
    auftragskunde.Kunde.ErhoehteFamilienBeihilfe = value;
    const kind = SpartaxFormModelDtoUtil.getKindForAuftragskunde(this.model, auftragskunde);
    if(kind) {
      if(value) {
        kind.FamilienBeihilfe = true;
      } else {
        auftragskunde.Kunde.Behinderungen = [];
        auftragskunde.FeststellungGradBehinderungNotwendig = false;
        auftragskunde.VerschlechterterGesunheitszustand = false;
      }

      kind.ErhoehteFamilienBeihilfe = value;
    }
  }

  onVerschlechterterGesundheitszustandChange(auftragskunde: SpartaxAuftragskunde, value: boolean) {
    auftragskunde.VerschlechterterGesunheitszustand = value;
    if(!value) {
      auftragskunde.Kunde.Behinderungen = [];
      auftragskunde.FeststellungGradBehinderungNotwendig = false;
    }
  }

  showVerschlechterterGesundheitszustandSubquestion(auftragskunde: SpartaxAuftragskunde) {
    const kind = SpartaxFormModelDtoUtil.getKindForAuftragskunde(this.model, auftragskunde);
    return (!kind || !kind.ErhoehteFamilienBeihilfe) && (
      !!auftragskunde.Kunde.BehinderungMitGutachten || !!auftragskunde.Kunde.Behindertenpass || !!auftragskunde.Kunde.Parkausweis);
  }

  showVerschlechterterGesundheitszustandRow() {
    return this.model.auftragskunden.some(auftragskunde => this.showVerschlechterterGesundheitszustandSubquestion(auftragskunde));
  }

  showBehinderungQuestion(auftragskunde: SpartaxAuftragskunde) {
    const kind = SpartaxFormModelDtoUtil.getKindForAuftragskunde(this.model, auftragskunde);
    const erhoehteBeihilfe = !kind || !kind.ErhoehteFamilienBeihilfe;
    return erhoehteBeihilfe && (!this.showVerschlechterterGesundheitszustandSubquestion(auftragskunde) || auftragskunde.VerschlechterterGesunheitszustand);
  }

  showBehinderungQuestionRow() {
    return this.model.auftragskunden.some(auftragskunde => this.showBehinderungQuestion(auftragskunde));
  }

  isBehinderungValueIsSet(auftragskunde: SpartaxAuftragskunde, value: string) {
    if (!auftragskunde.Kunde.Behinderungen) {
      auftragskunde.Kunde.Behinderungen = [];
    }

    return auftragskunde.Kunde.Behinderungen.includes(SpartaxBehinderung[value]);
  }

  setBehinderung(auftragskunde: SpartaxAuftragskunde, value: string) {
    if (!auftragskunde.Kunde.Behinderungen) {
      auftragskunde.Kunde.Behinderungen = [];
    }
    
    if (!this.isBehinderungValueIsSet(auftragskunde, value)) {
      auftragskunde.Kunde.Behinderungen.push(SpartaxBehinderung[value]);
    } else {
      auftragskunde.Kunde.Behinderungen = auftragskunde.Kunde.Behinderungen.filter(behinderung => behinderung !== SpartaxBehinderung[value]);
    }
  }
}
