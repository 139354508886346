import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../service/auth.service";
import { tap } from "rxjs/operators";
import { NavController } from "@ionic/angular";

@Injectable({
    providedIn: 'root',
})
export class UnauthorizedInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private navCtrl: NavController) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(() => { },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
              this.authService.logout();
              this.navCtrl.navigateRoot('login');
            } else {
              return;
            }
          }
        }));
    }
}