import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserRole } from './data/entities';
import { RoleGuard } from './data/guards/role.guard';
const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/menu/menu.module').then((m) => m.MenuPageModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'follow',
    loadChildren: () =>
      import('./pages/follow/follow.module').then((m) => m.FollowPageModule),
  },
  {
    path: 'matching',
    loadChildren: () =>
      import('./pages/matching/matching.module').then((m) => m.MatchingPageModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./pages/admin/admin.module').then((m) => m.AdminPageModule),
    canActivate: [RoleGuard],
    data: {
      expectedRole: UserRole.admin,
    },
  },
  {
    path: 'verification-process',
    loadChildren: () => import('./pages/contentpages/verification-process/verification-process.module').then( m => m.VerificationProcessPageModule)
  },
  {
    path: 'field-filter',
    loadChildren: () => import('./pages/field-filter/field-filter.module').then( m => m.FieldFilterPageModule)
  },
  {
    path: 'success-field-filter',
    loadChildren: () => import('./pages/success-field-filter/success-field-filter.module').then( m => m.SuccessFieldFilterPageModule)
  },
  {
    path: 'success-matching',
    loadChildren: () => import('./pages/success-matching/success-matching.module').then( m => m.SuccessMatchingPageModule)
  },
  {
    path: 'success-distance',
    loadChildren: () => import('./pages/success-distance/success-distance.module').then( m => m.SuccessDistancePageModule)
  },
  {
    path: 'distance-filter',
    loadChildren: () => import('./pages/distance-filter/distance-filter.module').then( m => m.DistanceFilterPageModule)
  },
  {
    path: 'matching',
    loadChildren: () => import('./pages/matching/matching.module').then( m => m.MatchingPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'play',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'profilestatus',
    loadChildren: () => import('./pages/profilestatus/profilestatus.module').then( m => m.ProfilestatusPageModule)
  },
  {
    path: 'follow',
    loadChildren: () => import('./pages/follow/follow.module').then( m => m.FollowPageModule)
  },
  {
    path: 'confirm-user-reference/:id',
    loadChildren: () => import('./pages/confirm-user-reference/confirm-user-reference.module').then( m => m.ConfirmUserReferencePageModule)
  },
  {
    path: 'success-profile-fill',
    loadChildren: () => import('./pages/success-profile-fill/success-profile-fill.module').then( m => m.SuccessProfileFillPageModule)
  },
  {
    path: 'search-test',
    loadChildren: () => import('./search-test/search-test.module').then( m => m.SearchTestPageModule)
  },
  {
    path: 'applications',
    loadChildren: () => import('./pages/applications/applications.module').then( m => m.ApplicationsPageModule)
  },
  {
    path: 'cancle-newsletter',
    loadChildren: () => import('./pages/cancle-newsletter/cancle-newsletter.module').then( m => m.CancleNewsletterPageModule)
  },
  {
    path: 'magirus-admin',
    loadChildren: () => import('./pages/magirus-admin/magirus-admin.module').then( m => m.MagirusAdminPageModule)
  }



  /*
    Don't add new Routes here - instead add them to pages/menu/menu-routing.module.ts if you wan't the mobile header to work!
  */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
