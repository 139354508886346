import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { bfiFormDto } from '../dto';
import { ContactUserDto } from '../dto/contact-user.dto';
import { UploadProfileImageDto } from '../dto/upload-profile-image.dto';
import { User, UserRole } from '../entities';
import { UserBfi, UserBfiType } from '../entities/user-bfi.entity';
import { AddUserReferenceDto } from '../dto/add-user-reference.dto';
import { ConfirmUserReferenceDto } from '../dto/confirm-user-reference.dto';
import { UserReference } from '../entities/user-reference.entity';
import { CompanySubscriptionService } from './company-subscription.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private timeStampImageLastUpdated: Date = null;

  constructor(private http: HttpClient, private companySubscriptionService: CompanySubscriptionService) { }

  getUser(userId: number, approved?: boolean): Observable<any> {
    // Erstellen eines HttpParams-Objekts mit dem gewünschten Query-Parameter
    let params = new HttpParams();
    if (approved !== undefined) {
      params = params.append('approved', approved.toString());
    }
  
    return this.http.get(`${environment.apiUrl}/users/${userId}`, {
      responseType: 'json',
      params: params, // Hinzufügen der Query-Parameter zum Request
    });
  }

  getUserByUserName(userName: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/getbyusername/${userName}`, {
      responseType: 'json',
    });
  }

  getUserByProfileId(profileId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/getbyprofileid/${profileId}`, {
      responseType: 'json',
    });
  }

  updateUser(userId: number, values: any): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/users/${userId}`, values);
  }

  generateUserCategories(userId: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}/users/generateUserCategories/${userId}`, null);
  }

  getUserImageUrl(userId: number = 0, appendCacheBuster = false): string {
    if (userId === 0) {
      return '';
    }

    //If user load's his/her own image, always append a cache buster 
    //in order to avoid problems with caching
    let cacheBuster = '';
    if (appendCacheBuster) {
      if (!this.timeStampImageLastUpdated) {
        this.timeStampImageLastUpdated = new Date();
      }

      cacheBuster = '?' + this.timeStampImageLastUpdated.getTime() / 1000;
    }

    return `${environment.apiUrl}/users/getImageByuserid/${userId}${cacheBuster}`;
  }

  
  insertCallMailEntry(userId: number): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/company-subscription/insertCallMailEntry/${userId}`,
      {
        responseType: 'json',
      }
    );
  }

  getUserBfi(userId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users-bfi/${userId}`, {
      responseType: 'json',
    });
  }

  uploadUserImage(uploadedImage: UploadProfileImageDto) {
    this.timeStampImageLastUpdated = new Date();
    return this.http.post(`${environment.apiUrl}/users/uploadUserImage/`, uploadedImage);
  }

  getTempBfi(): bfiFormDto {
    return JSON.parse(localStorage.getItem('local_bfi10'));
  }

  saveTempBfi(bfiForm: bfiFormDto) {
    localStorage.setItem('local_bfi10', JSON.stringify(bfiForm));
  }

  clearTempBfi() {
    localStorage.removeItem('local_bfi10');
  }

  calculateBfi(bfiFormDto: bfiFormDto): UserBfi {
    if (bfiFormDto) {
      const userBfi = new UserBfi();
      userBfi.type = UserBfiType.bfi10;

      const values = {
        1: this.reverseValue(bfiFormDto[1]),
        2: bfiFormDto[2],
        3: this.reverseValue(bfiFormDto[3]),
        4: this.reverseValue(bfiFormDto[4]),
        5: this.reverseValue(bfiFormDto[5]),
        6: bfiFormDto[6],
        7: this.reverseValue(bfiFormDto[7]),
        8: bfiFormDto[8],
        9: bfiFormDto[9],
        10: bfiFormDto[10],
      };

      userBfi.bfiE = this.mean([values[1], values[6]]); //  [01r,06]
      userBfi.bfiN = this.mean([values[4], values[9]]); //  [04r,09]
      userBfi.bfiO = this.mean([values[5], values[10]]); // [05r,10]
      userBfi.bfiG = this.mean([values[3], values[8]]); //  [03r,08]
      userBfi.bfiV = this.mean([values[2], values[7]]); //  [02,07r]

      userBfi.bfiOToMaxIfSearching = bfiFormDto.bfiOToMaxIfSearching;

      return userBfi;
    } else {
      return null;
    }
  }

  private mean([...input]: Array<number>) {
    //Find the sum
    let sum = 0;
    for (const i in input) {
      sum += input[i];
    }
    //Get the length of the array
    const numbersCnt = input.length;
    //Return the average / mean.
    return sum / numbersCnt;
  }

  private reverseValue(input: number) {
    return 6 - input;
  }

  async hasBasicProfile(profileid) {

      let subscriptions = await this.companySubscriptionService.getCompaniesSubscriptionsById(profileid).toPromise();

      for (let subscription of subscriptions) {
        if (subscription.subscriptionCode == "d") {
          if (subscription.subscriptionEnd == null
            && subscription.lastPayment == null) {

            const datumMitDreiMonaten: Date = new Date(subscriptions[0].subscriptionStart);
            datumMitDreiMonaten.setMonth(datumMitDreiMonaten.getMonth() + 3);

            if (datumMitDreiMonaten.getTime() < new Date().getTime()) {
              return true;
            } else {
              return false;
            }
          }

        }
      }
    
    return false;
  }

  compareTwoUserBfis(searcherUserBfi: UserBfi, foundUserBfi: UserBfi): number {
    if (searcherUserBfi && foundUserBfi) {
      let res = 1 - ((Math.abs(+foundUserBfi.bfiE - +searcherUserBfi.bfiE) * 2 / 100) +
        (Math.abs(+foundUserBfi.bfiN - +searcherUserBfi.bfiN) * 2 / 100) +
        (Math.abs(+foundUserBfi.bfiO - +(searcherUserBfi.bfiOToMaxIfSearching ? 5 : searcherUserBfi.bfiO)) * 2 / 100) +
        (Math.abs(+foundUserBfi.bfiG - +searcherUserBfi.bfiG) * 2 / 100) +
        (Math.abs(+foundUserBfi.bfiV - +searcherUserBfi.bfiV) * 2 / 100));

      //Für die verständliche Darstellung
      //Ergebnis kann nämlich immer nur zwischen 60 und 100 sein, daher wird diese Range von 40 auf 100% hochgerechnet
      let retVal = (res - 0.6) * 0.025 * 100;
      return +retVal.toFixed(2);
    }
  }

  compareTwoUserBfiValue(a: number, b: number): number {
    return 1 - (+(Math.abs(+b - +a) * 20 / 100).toFixed(2));
  }

  saveBfi(userId, values) {
    values.userId = userId;
    return this.http.post(`${environment.apiUrl}/users-bfi/`, values);
  }

  updatebfiOToMaxIfSearching(value: boolean) {
    return this.http.post(`${environment.apiUrl}/users-bfi/updateBfiOToMaxIfSearching`, { value: value });
  }

  setUserRole(userId: number, role: UserRole | string) {
    return this.http.post(`${environment.apiUrl}/users/setUserRole`, {
      userId: userId,
      role: role
    });
  }

  inviteUser(email: string, assignLicense: boolean) {
    return this.http.post(`${environment.apiUrl}/users/inviteUser`, {
      email: email,
      assignLicense: assignLicense
    });
  }

  contactUser(contactUserDto: ContactUserDto) {
    return this.http.post(`${environment.apiUrl}/users/contactUser`, contactUserDto);
  }

  informAdminAboutCall(userId: number) {
    return this.http.post(`${environment.apiUrl}/users/informAdminAboutCall/${userId}`, null);
  }

  setUserWelcomeConfirmed() {
    return this.http.post(`${environment.apiUrl}/users/setWelcomeConfirmed`, null);
  }

  sendUnlicensedUserContactAttemptMail(userId: number) {
    return this.http.post(`${environment.apiUrl}/users/unlicensedUserContactAttempt/${userId}`, null);
  }

  getMyFollowedUsers(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/users/myfollowed/all`);
  }

  getMyNotFollowedUsers(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/users/mynotfollowed/all`);
  }

  followUser(userId: number) {
    return this.http.post(`${environment.apiUrl}/users/follow/${userId}`, null);
  }

  unfollowUser(userId: number) {
    return this.http.post(`${environment.apiUrl}/users/unfollow/${userId}`, null);
  }

  getUserReference(id: string) {
    return this.http.get<UserReference>(`${environment.apiUrl}/users/getUserReference/${id}`);
  }

  addUserReference(addUserReferenceDto: AddUserReferenceDto) {
    return this.http.post(`${environment.apiUrl}/users/addUserReference`, addUserReferenceDto);
  }

  confirmUserReference(confirmUserReferenceDto: ConfirmUserReferenceDto) {
    return this.http.post(`${environment.apiUrl}/users/confirmUserReference`, confirmUserReferenceDto);
  }
}
