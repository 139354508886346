import * as moment from "moment";
import { BeeexpertModule, BeeexpertModuleName, CompanyLicenseAssignment, Subscription } from ".";
import { Company } from "./company.entity";

export class CompanySubscription {
    companySubscriptionId: number;
    company: Company;
    companyId: number;
    createdAt?: Date;
    createdBy?: number;
    modifiedAt?: Date;
    modifiedBy?: number;
    subscription: Subscription;
    subscriptionCode: string;
    numberOfLicenses: number;
    subscriptionStart: Date;
    subscriptionEnd?: Date;
    licenseAssignments: CompanyLicenseAssignment[];

    get availableLicenses() {
        if(moment() > moment(this.subscriptionEnd)) {
            return 0;
        } else {
            return this.numberOfLicenses - this.licenseAssignments.length;
        }
    }

    get moduleName() {
        if(this.subscription && this.subscription.beeexpertModule) {
            return BeeexpertModuleName.get(this.subscription.beeexpertModule);
        } else {
            return '';
        }
    }
}