import { Component } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { User, UserRole } from 'src/app/data/entities';
import { AuthService } from 'src/app/data/service/auth.service';

@Component({
  selector: 'app-user-popover',
  templateUrl: './user-popover.component.html',
  styleUrls: ['./user-popover.component.scss'],
})
export class UserPopoverComponent {
  user: User;
  userIsAdmin = false;
  hasActiveLicense = false;
  isLoggedIn = false;
  isFullAdmin = false;

  constructor(
    public popoverController: PopoverController,
    public authService: AuthService,
    public navCtrl: NavController,
  ) {}
  
  async dismiss() {
    await this.popoverController.dismiss();
  }

  async logout() {
    this.navCtrl.navigateRoot('login');
    this.authService.logout();
    await this.popoverController.dismiss();
  }

  async ionViewWillEnter() {
   await this.loadMenuItems();
  }

  async loadMenuItems(){
    this.user = await this.authService.getCurrentUserCached();
    this.userIsAdmin = this.user && (
      this.user.role == UserRole.companyAdmin || this.user.role == UserRole.admin
    );
    this.hasActiveLicense = this.user?.hasActiveLicense;
    this.isLoggedIn = this.authService.isLoggedIn();
    if(this.authService.currentUserValue?.role ==='admin'){
      this.isFullAdmin = true;
    }else{
      this.isFullAdmin = false;
    }
  }

  async openAdmin() {
    window.open('/admin', '_blank');
    await this.dismiss();
  }
}
