import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { FormFillout } from 'src/app/data/entities/form-fillout.entity';
import { AuthService } from 'src/app/data/service/auth.service';
import { FormService } from 'src/app/data/service/form.service';

@Component({
  selector: 'app-share-form',
  templateUrl: './share-form.component.html',
  styleUrls: ['./share-form.component.scss'],
})
export class ShareFormComponent implements OnInit {
  isWorking: boolean;
  mailAddressOfInvited: string;
  formId: string;
  filloutId: string;
  success: boolean;

  @ViewChild('formElement')
  ngForm!: NgForm;

  constructor(
    private modalController: ModalController,
    private formService: FormService,
    private authService: AuthService,
  ) { }

  ngOnInit() {}
  
  closeModal() {
    this.modalController.dismiss();
  }

  async onSubmit() {
    if(this.ngForm.valid) {
      this.isWorking = true;
      
      if(!this.filloutId) {
        const form = await this.formService.getById(this.formId).toPromise();
        const fillout = await this.formService.createOrUpdateFillout({
          companyId: form.companyId,
          formId: form.id,
          formlySchema: form.formlySchema,
          data: form.initialModel || {},
          createdBy: this.authService.currentUserValue?.userId,
          modifiedBy: this.authService.currentUserValue?.userId,
        }).toPromise();

        this.filloutId = fillout.id;
      }

      try {
        await this.formService.shareFillout({
          email: this.mailAddressOfInvited,
          formId: this.formId,
          filloutId: this.filloutId
        }).toPromise();
        
        this.success = true;
      } catch(err) {
        console.error(err);
        alert('Beim Teilen ist leider ein Fehler aufgetreten');
      }

      this.isWorking = false;
    }
  }
}
