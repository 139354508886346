import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AppService {
    constructor(private http: HttpClient) {}
    
    public async isMaintenanceActive(): Promise<boolean> {
        let isMaintenanceActive = false;
        if(sessionStorage.getItem('maintenance') === null) {
            isMaintenanceActive = (await this.http.get(`${environment.apiUrl}/app/maintenance`, {
                responseType: 'text',
              }).toPromise()) === 'true';
              sessionStorage.setItem('maintenance', isMaintenanceActive.toString());
        } else {
            isMaintenanceActive = sessionStorage.getItem('maintenance') === 'true';    
        }
        return isMaintenanceActive;
    }
}