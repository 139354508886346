import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { AlertController, AnimationController, IonButton, IonSlides, ModalController, NavController, PopoverController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { bfiFormDto } from 'src/app/data/dto';
import { AuthService } from 'src/app/data/service/auth.service';
import { UserService } from 'src/app/data/service/user.service';
import { CanvasPopupComponent } from '../canvas-popup/canvas-popup.component';
import { FeedbackPopupComponent } from '../feedback-popup/feedback-popup.component';

@Component({
  selector: 'app-bfi10',
  templateUrl: './bfi10.component.html',
  styleUrls: ['./bfi10.component.scss'],
})
export class Bfi10Component implements OnInit {
  @ViewChild('slideMatching', { static: false }) slideMatching: ElementRef | undefined;

  @Input() position: string;
  questions: any;
  bfi10working = false;
  redirectTo: string;
  hideQuestions = false;
  slideOptsMatching = {
    name: 'matching',
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: false,
  };
  backLinkVisible = false;
  finished = false;
  willBeConsultant = false;
  redirectUrl: string = "/distance-filter";
  selectionBlocked = false;

  animateButton1 = true;
  animateButton2 = true;
  animateButton3 = true;
  animateButton4 = true;
  animateButton5 = true;

  public alertButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
      },
    },
    {
      text: 'OK',
      role: 'confirm',
      handler: () => {
      },
    },
  ];




  private bfi10: bfiFormDto = {
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
    9: null,
    10: null,
    userId: 0
  };

  private navigationExtras: NavigationExtras = {
    queryParams: {
      source: "bfi10"
    }
  };

  get sliderIndex() {
    if (this.slideMatching?.nativeElement.swiper.activeIndex) {
      return this.slideMatching.nativeElement.swiper.activeIndex;
    }
    return 0;
  }

  isMobile() {
    if (window.innerWidth < 770) {
      return true;
    };
    return false;
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private navCtrl: NavController,
    private activatedRoute: ActivatedRoute,
    private alertController: AlertController,
    private animationCtrl: AnimationController,
    private popoverController: PopoverController,
    private modalController: ModalController

  ) {

    this.questions = {
      isBeginningSlide: true,
      isEndSlide: false,
      slidesItems: [
        {
          id: 1,
          sort: 3,
          question: 'Ich bin eher zurückhaltend, reserviert.',
          image: '1.gif',
          gratulation: '',
          answered: false
        },
        {
          id: 2,
          sort: 1,
          question: 'Ich schenke anderen leicht Vertrauen, glaube an das Gute im Menschen.',
          image: '2.gif',
          gratulation: '',
          answered: false
        },
        {
          id: 3,
          sort: 4,
          question: 'Ich bin bequem, neige zur Faulheit.',
          image: '3.gif',
          gratulation: '',
          answered: false

        },
        {
          id: 4,
          sort: 2,
          question:
            'Ich bin entspannt, lasse mich durch Stress nicht aus der Ruhe bringen.',
          image: '4.gif',
          gratulation: '',
          answered: false

        },
        {
          id: 5,
          sort: 5,
          question: 'Ich habe nur wenig künstlerisches Interesse.',
          image: '5.gif',
          gratulation: '',
          answered: false

        },
        {
          id: 6,
          sort: 6,
          question: 'Ich gehe aus mir heraus, bin gesellig.',
          image: '6.gif',
          gratulation: '',
          answered: false

        },
        {
          id: 7,
          sort: 7,
          question: 'Ich neige dazu, andere zu kritisieren.',
          image: '7.gif',
          gratulation: '',
          answered: false

        },
        {
          id: 8,
          sort: 8,
          question: 'Ich erledige Aufgaben gründlich.',
          image: '8.gif',
          gratulation: '',
          answered: false

        },
        {
          id: 9,
          sort: 9,
          question: 'Ich werde leicht nervös und unsicher.',
          image: '9.gif',
          gratulation: '',
          answered: false

        },
        {
          id: 10,
          sort: 10,
          question: 'Ich habe eine aktive Vorstellungskraft, bin fantasievoll.',
          image: '10.gif',
          gratulation: 'Gratuliere! Das wars schon.',
          answered: false

        },
      ],
    };

  }



  async readParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params.source) {
        this.redirectTo = params.source;
      } else {
        this.redirectTo = "";
      }
      if (params.backToPage) {
        this.redirectUrl = params.backToPage;
      }
      if (params.willBeConsultant) {
        this.willBeConsultant = params.willBeConsultant;
        this.navigationExtras = {
          queryParams: {
            source: "bfi10",
            willBeConsultant: this.willBeConsultant
          }
        };
      }
    });
  }

  async ionViewDidLoad() {
    this.slideMatching.nativeElement.swiper.lockSwipes(true);
    // Reset the Matching
    this.resetMatching();

  }
  resetMatching() {
    Object.keys(this.questions.slidesItems).forEach((k) => {
      const element = this.questions.slidesItems[k];
      if (element.selected) {
        element.selected = undefined;
      }
    });
  }
  ngOnInit() { }

  async presentChangeMatchingOAlert() {
    const alert = await this.alertController.create({
      header: 'Möchtest du auch mal neue Wege beschreiten?',
      //   message: '<ion-img src="/assets/beeex-4/matching/Wegweiser.gif"</ion-img>',
      cssClass: 'beeex4-changeMatchignO-alert',
      subHeader: "Willst du Berater*innen vorgeschlagen bekommen, die dich aus der Reserve locken und Neuland mit dir betreten? Du kannst diese Einstellung jederzeit ändern.",
      buttons: [
        {
          text: 'Nein',
          role: 'cancel',
          handler: () => {
            this.saveResultAndRedirect(false);
          },
        },
        {
          text: 'Ja',
          role: 'confirm',
          handler: () => {
            this.saveResultAndRedirect(true);
          },
        },
      ],
    });
    await alert.present();

  }

  ionViewDidEnter() {
    setTimeout(() => {
      this.readParams();
    }, 5000); // Wartezeit: 1000 Millisekunden = 1 Sekunde
  }

  async presentSkipAlert() {
    const alert = await this.alertController.create({
      header: 'Achtung',
      //   message: '<ion-img src="/assets/beeex-4/matching/Wegweiser.gif"</ion-img>',
      // cssClass: 'beeex4-changeMatchignO-alert',
      subHeader: "Ein Überspringen des Persönlichkeitsmatchings ist nur für Unternehmer:innen möglich, nicht jedoch für Berater*innen.",
      buttons: [/*
        {
          text: 'Später ausfüllen',
          role: 'cancel',
          handler: () => {
            this.navCtrl.navigateRoot('/companyprofile/edit', this.navigationExtras);
          },
        },*/
        {
          text: 'Fortfahren',
          role: 'confirm',
          handler: () => {
            alert.dismiss();
          },
        },
      ],
    });
    await alert.present();

  }

  async checkBfiForm() {
    let valuesSet = 0;

    Object.keys(this.questions.slidesItems).forEach((k) => {
      const element = this.questions.slidesItems[k];
      if (element.selected) {
        valuesSet++;
        this.bfi10[element.id] = element.selected;
      }
    });
    if (valuesSet === 10) {
      //  this.bfi10working = true;

      //Checken, ob wir ihm das PopUp zur Offenheitsverstellung anzeigen sollen
      let matchingValO = (6 - this.bfi10[4] + this.bfi10[9]) / 2;

      let hasActiveLicense = false;
      if (this.authService.isLoggedIn()) {
        let user = await this.userService
          .getUser(this.authService.currentUserValue?.userId)
          .toPromise();
        hasActiveLicense = user.hasActiveLicense;
      }

      //Wurde auf Wunsch des Kunden entfernt
      //  if (matchingValO < 5 && this.redirectTo != 'payment' && this.redirectTo != 'invite' && !hasActiveLicense) {
      //  this.presentChangeMatchingOAlert();
      //} else {
      this.saveResultAndRedirect(false);
      //}
    }
  }

  async backwards(s){
    this.slideMatching.nativeElement.swiper.slidePrev(500);
    this.startAnimation();

    this.questions.slidesItems[s.id - 2].answered = false;
  }

  async saveResultAndRedirect(oToMax: boolean) {
    localStorage.removeItem('local_skippedConsultants');

    this.bfi10.bfiOToMaxIfSearching = oToMax;

    if (this.authService.isLoggedIn()) {
      switch (this.position) {
        case 'profile':
          await this.readParams();

          this.userService
            .saveBfi(this.authService.currentUserValue.userId, this.bfi10)
            .pipe(first())
            .subscribe(
              (data) => {
                this.bfi10working = false;

                //Kommt direkt über das Profil eines anderen
                if (this.redirectTo.includes('berater')) {
                  this.navCtrl.navigateRoot(this.redirectTo.replace("%2F", "/"), this.navigationExtras);
                } else {
                  //Kommt direkt über den Bezahlprozess
                  if (this.redirectTo === 'payment' || this.redirectTo === 'invite') {
                    this.navCtrl.navigateRoot('/profile/edit', this.navigationExtras);
                  } else if (this.redirectTo === 'matching') {

                    this.navCtrl.navigateRoot('/matching', this.navigationExtras);
                  }
                  else {
                    if (this.willBeConsultant) {
                      this.navCtrl.navigateRoot('/success-profile-fill', this.navigationExtras);
                    } else {
                      this.navCtrl.navigateRoot(this.redirectUrl);
                    }
                  }
                }
              },
              (error) => {
                this.bfi10working = false;
              }
            );

          break;

      }
    } else {
      // nicht eingeloggt => Zwischenspeichern
      await this.readParams();

      this.userService.saveTempBfi(this.bfi10);
      this.bfi10working = false;

      //Kommt direkt über das Profil eines anderen
      if (this.redirectTo.includes('berater')) {
        this.navCtrl.navigateRoot(this.redirectTo.replace("%2F", "/"), this.navigationExtras);
      } else {
        //Kommt über die Suche
        this.navCtrl.navigateRoot('/matching', this.navigationExtras);
      }

      if (this.willBeConsultant) {
        this.navCtrl.navigateRoot('/signup', this.navigationExtras);
      }
    }
  }


  async openPopupCanvas(answer) {
    const popover = await this.modalController.create({
      component: CanvasPopupComponent,
      componentProps: {
        answer: answer
      },
    });

    return await popover.present();
  }

  async openPopupFeedback(answer) {
    const popover = await this.modalController.create({
      component: FeedbackPopupComponent,
      componentProps: {
        answer: answer
      },
    });

    return await popover.present();
  }

  //Move to Next slide
  async selectAnswer(object, slideView: ElementRef | undefined, s, selected?) {
    if (!this.selectionBlocked) {
      this.selectionBlocked = true;

      const index = await this.slideMatching.nativeElement.swiper.activeIndex;
      this.questions.slidesItems[index].selected = selected;

      s.answer = selected;
      s.answered = true;


      if (s.id == 10) {
        s.hideImg = true;
        s.image = "Pokal.gif";
        this.finished = true;
        this.hideQuestions = true;
        await this.delay(250);
        s.hideImg = false;
        await this.delay(2500);

      }
/* Deactivated Popups
      //Drawing Popup
      if (s.id == 5) {
        this.openPopupCanvas(s.answer);
        await this.delay(2500);
      }

      //Drawing Popup
      if (s.id == 7) {
        this.openPopupFeedback(s.answer);
        await this.delay(2500);
      }
*/
      await this.delay(250);

      /*
          if (s.id != 10) {
            await this.delay(2500);
          } else {
            await this.delay(3500);
          }*/
      this.finished = false;

      this.checkBfiForm();


      if (s.id != 10) {


        this.slideMatching.nativeElement.swiper.slideNext(500);
        this.startAnimation();
        /* console.log(document.getElementById('beeex4-button5'));
         document.getElementById('beeex4-button1').style.display = "none";
         document.getElementById('beeex4-button2').style.display = "none";
         document.getElementById('beeex4-button3').style.display = "none";
         document.getElementById('beeex4-button4').style.display = "none";
         document.getElementById('beeex4-button5').style.display = "none";
         console.log(document.getElementById('beeex4-button5'));
   
       //  
         console.log(document.getElementById('beeex4-button5'));
   */
      }

      this.selectionBlocked = false;
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  //Call methods to check if slide is first or last to enable disable navigation
  checkIfNavDisabled(object, slideView) {
    if (!slideView) {
      return;
    }
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object, slideView) {
    if (!slideView) {
      return;
    }
    slideView.isBeginning().then((istrue) => {
      object.isBeginningSlide = istrue;
    });
  }

  checkisEnd(object, slideView) {
    if (!slideView) {
      return;
    }
    slideView.isEnd().then((istrue) => {
      object.isEndSlide = istrue;
    });
  }

  async onBackLink(object, slideView: IonSlides) {
    const index = await slideView.getActiveIndex();
    this.questions.slidesItems[index].selected = null;

    if (index == 1) {
      this.backLinkVisible = false;
    }

    if (index > 0) {
      slideView.slidePrev(500).then(() => {
        this.checkIfNavDisabled(object, slideView);
      });
    }
  }

  async skipMatching() {
    await this.readParams();
    if (this.willBeConsultant) {
      this.presentSkipAlert();
    } else {
      this.navCtrl.navigateRoot(this.redirectUrl);
    }
  }



  startAnimation() {
    setTimeout(() => {
      // document.getElementById('beeex4-button1').style.visibility = "visible";
      this.animateButton1 = true;
    }, 0);
    setTimeout(() => {
      //  document.getElementById('beeex4-button2').style.visibility = "visible";
      this.animateButton2 = true;
    }, 200);
    setTimeout(() => {
      //   document.getElementById('beeex4-button3').style.visibility = "visible";
      this.animateButton3 = true;
    }, 400);
    setTimeout(() => {
      //  document.getElementById('beeex4-button4').style.visibility = "visible";
      this.animateButton4 = true;
    }, 600);
    setTimeout(() => {
      //   document.getElementById('beeex4-button5').style.visibility = "visible";
      this.animateButton5 = true;
    }, 800);

    this.animateButton1 = false;
    this.animateButton2 = false;
    this.animateButton3 = false;
    this.animateButton4 = false;
    this.animateButton5 = false;
  }

}