import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ContactFormDto } from '../dto';
import { StarRatingDto } from '../dto/star-rating.dto';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private http: HttpClient) {}

  sendRequest(contactFormDto: ContactFormDto): Observable<any> {
    return this.http.post(`${environment.apiUrl}/contact/`, contactFormDto);
  }

  sendStarRating(starRatingDto: StarRatingDto): Observable<any> {
    return this.http.post(`${environment.apiUrl}/contact/send-star-rating`, starRatingDto);
  }
}
