import { MainConsultantCategory } from "./main-consultant-category.entity";

export class ConsultantCategory {
  categoryId: number;
  name: string;
  icon: string;
  mainCategoryId: number;
  mainCategory: MainConsultantCategory;
  searchedSum: number;
  name_english: string;
  icon_available: boolean;
  approved: boolean;
}
