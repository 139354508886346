import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-search-popover',
  templateUrl: './search-popover.component.html',
  styleUrls: ['./search-popover.component.scss'],
})
export class SearchPopoverComponent implements OnInit {
  @ViewChild("searchbar")
  search;
  
  constructor(private navCtrl: NavController) {}

  ngOnInit() {}

  ionViewWillEnter() {
    setTimeout(() => {
      this.search.setFocus();
    }, 500);
  }

  async onSearch(ev: any) {
    const searchTerm: string = ev.detail.value;
    this.navCtrl.navigateRoot(['search', searchTerm]);
  }
}
