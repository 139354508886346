import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import * as IBAN from 'iban';
import { from } from 'linq-to-typescript';
import * as moment from 'moment';
import { SpartaxAuftragskunde, SpartaxAuftragskundeArt } from 'src/app/data/dto/spartax/spartax-auftragskunde.dto';
import { SpartaxFormModelDto } from 'src/app/data/dto/spartax/spartax-form-model.dto';
import { SpartaxKind, SpartaxKunde, SpartaxKundeUtil } from 'src/app/data/dto/spartax/spartax-kunde.dto';
import { SpartaxService } from 'src/app/data/service/spartax.service';

@Component({
  selector: 'app-spartax-identity',
  templateUrl: './spartax-identity.component.html',
  styleUrls: ['./spartax-identity.component.scss'],
})
export class SpartaxIdentityComponent {
  @Input() 
  kunde: SpartaxKunde;

  @Input()
  isAuftragskundePage: boolean;

  @Input()
  isHauptkunde: boolean;

  @Input()
  edit: boolean;

  @Input()
  debug: boolean;

  @Input()
  teilDesAuftrages: boolean;

  @Input()
  spartaxPortalKunden: Map<string, SpartaxKunde>;

  @Input()
  auftragskunden: SpartaxAuftragskunde[];

  @Input()
  kinder: SpartaxKind[];
  
  @Output()
  kundeChange = new EventEmitter<SpartaxKunde>();

  @Output()
  modelChange = new EventEmitter<SpartaxFormModelDto>();

  @Output()
  spartaxPortalKundenChange = new EventEmitter<Map<string, SpartaxKunde>>();
  
  @Output()
  auftragskundenChange = new EventEmitter<SpartaxAuftragskunde[]>();

  @Output()
  kinderChange = new EventEmitter<SpartaxKind[]>();

  @ViewChild('formElement')
  ngForm!: NgForm;

  public errorMsg = "";

  public possibleAusweisarten = ['Reisepass', 'Personalausweis', 'Führerschein', 'Behindertenpass'];

  public geburtsjahr: number;
  public geburtsmonat: number;
  public geburtstag: number;
  public ausstellungsjahr: number;
  public ausstellungsmonat: number;
  public ausstellungstag: number;
  public sterbejahr: number;
  public sterbemonat: number;
  public sterbetag: number;
  public svnummer: number;
  public completionExecuted: boolean = false;
  public ibanValid: boolean = true; //"Soft" validation - therefore we assume true here
  public svNummerValid: boolean = true; //"Soft" validation - therefore we assume true here
  public possibleKundenMatches: {key: string, value: string}[] = [];
  public queryingKunden: boolean = false;
  public loadingKunde: boolean = false;
  public existingKundeLinked: boolean = false;

  private kundeBackup: SpartaxKunde;

  constructor(
    private spartaxService: SpartaxService,
    private alertController: AlertController
  ) { }

  markAllControlsAsTouched() {
    for (const control in this.ngForm.controls) {
      this.ngForm.controls[control].markAsTouched();
    }
  }

  formIsValid() {
    this.errorMsg = "";
    if (this.edit) {
      if (!this.ngForm.valid) {
        return false;
      }
      if (this.ngForm.form.value.PLZWrite) {
        if ((this.ngForm.form.value.PLZWrite + "").length != 4) {
          this.errorMsg = "Die Postleitzahl muss 4-stellig sein."
          return false;
        }
      }
      if (!this.isDateValid(this.geburtstag, this.geburtsmonat, this.geburtsjahr)) {
        return false;
      }
      if (!this.isDateValid(this.ausstellungstag, this.ausstellungsmonat, this.ausstellungsjahr)) {
        return false;
      }
    }
    return true;
  }

  ngAfterViewInit() {
    this.initSpecialFields();
  }

  initSpecialFields() {
    if(this.kunde.Geburtstag) {
      this.geburtsjahr = new Date(this.kunde.Geburtstag).getFullYear();
      this.geburtstag = new Date(this.kunde.Geburtstag).getDate();
      this.geburtsmonat = new Date(this.kunde.Geburtstag).getMonth()+1;
    }

    if(this.kunde.VerstorbenAm) {
      this.sterbejahr = new Date(this.kunde.VerstorbenAm).getFullYear();
      this.sterbetag = new Date(this.kunde.VerstorbenAm).getDate();
      this.sterbemonat = new Date(this.kunde.VerstorbenAm).getMonth()+1;
    }

    if(this.kunde.AusstellungsdatumAusweis) {
      this.ausstellungsjahr = new Date(this.kunde.AusstellungsdatumAusweis).getFullYear();
      this.ausstellungstag = new Date(this.kunde.AusstellungsdatumAusweis).getDate();
      this.ausstellungsmonat = new Date(this.kunde.AusstellungsdatumAusweis).getMonth()+1;
    }

    if(this.kunde.SVNummer) {
      this.svnummer = this.kunde.SVNummer;
    }
  }

  get isRequiredInIdentityPageSymbol() {
    return this.isAuftragskundePage ? "" : "*";
  }

  get isRequiredInAuftragskundePageSymbol() {
    return this.isAuftragskundePage && this.teilDesAuftrages ? "*" : "";
  }

  get queryKundenTitle() {
    return this.canQueryKunden ? "In Bestandskunden suchen..." : "Erfassen Sie weitere Daten, um nach Bestandskunden zu suchen...";
  }

  get canQueryKunden() {  
    return !this.queryingKunden && this.kunde.Vorname && this.kunde.Vorname.length >= 3 &&
      this.kunde.Nachname && this.kunde.Nachname.length >= 3 &&
      (this.kunde.PLZ && this.kunde.PLZ.toString().length >= 4);
  }

  onVornameChange(value: string) {
    this.kunde.Vorname = value;
    
    //Because we match the current partners name with the new name, we need to update the partner name as well
    const auftragsKunde = this.auftragskunden.find(x => x.KundeFormModelId === this.kunde.FormModelId);
    if(auftragsKunde && auftragsKunde.AuftragsKundeArt == SpartaxAuftragskundeArt.aktuellerPartner) {
      const hauptkunde = this.auftragskunden.find(x => x.AuftragsKundeArt == SpartaxAuftragskundeArt.hauptkunde);
      hauptkunde.Kunde.Familienstaende[0].VornamePartner = this.kunde.Vorname;

      if(this.kinder) {
        for(const kind of this.kinder) {
          if(this.kunde.Geschlecht == 'Weiblich' && !kind.MutterIstExPartner) {
            kind.Mutter = this.kunde.Vorname;
          }
  
          if(this.kunde.Geschlecht == 'Männlich' && !kind.VaterIstExPartner) {
            kind.Vater = this.kunde.Vorname;
          }
        }
      }
    }
  }

  async queryKunden() {
    if(!this.kunde.ListItemId) {
      if(this.canQueryKunden) {
        try {
          this.queryingKunden = true;
          let query = "";
  
          query += (this.kunde.TitelVorgestellt ? this.kunde.TitelVorgestellt + " " : "")  
            + (this.kunde.Vorname ? this.kunde.Vorname + " " : "")
            + (this.kunde.Nachname ? this.kunde.Nachname : "")
            + (this.kunde.TitelNachgestellt ? " " + this.kunde.TitelNachgestellt : "") 
            + (this.kunde.PLZ ? " " + this.kunde.PLZ : "");
  
          this.possibleKundenMatches = await this.spartaxService.queryCustomers(query);
        } catch(error) {
          console.error(error);
        }
        
        this.queryingKunden = false;
      }
    }
  }
    
  async onExistingCustomerSelected(key: string) {
    if(from(this.auftragskunden).any(x => x.Kunde.ListItemId === +key)) {
      const alert = await this.alertController.create({
        subHeader: 'Der ausgewählte Kunde ist bereits einem anderen Auftragskunden zugeordnet.',
        buttons: ['OK']
      });
      await alert.present();
    } else {
      this.loadingKunde = true;
      this.kundeBackup = {...this.kunde};
      const kundeFromSharePoint = await this.spartaxService.getCustomerById(+key);
      SpartaxKundeUtil.updateKundePropsFromAnother(kundeFromSharePoint, this.kunde);
      this.kunde.procedureDiscussed = true;
      this.kunde.Partner = this.kundeBackup.Partner;
      this.kunde.FormModelIdPartner = this.kundeBackup.FormModelIdPartner;
      this.kunde.ListItemIdPartner = this.kundeBackup.ListItemIdPartner;
      this.kunde.Familienstaende = this.kundeBackup.Familienstaende;
      this.kunde.GemeinsamerHaushalt = this.kundeBackup.GemeinsamerHaushalt;
      this.kundeChange.emit(this.kunde);
  
      //Swap all references to the old formModelId in auftragskunden
      for(const auftragskunde of this.auftragskunden) {
        if(auftragskunde.KundeFormModelId === this.kundeBackup.FormModelId) {
          auftragskunde.KundeFormModelId = kundeFromSharePoint.FormModelId;
        }
  
        if(auftragskunde.Kunde.FormModelIdPartner === this.kundeBackup.FormModelId) {
          auftragskunde.Kunde.FormModelIdPartner = kundeFromSharePoint.FormModelId;
        }
  
        if(auftragskunde.Kunde.ListItemIdPartner === this.kundeBackup.ListItemId) {
          auftragskunde.Kunde.ListItemIdPartner = kundeFromSharePoint.ListItemId;
        }
  
        if(auftragskunde.Kunde.FormModelIdMutter === this.kundeBackup.FormModelId) {
          auftragskunde.Kunde.FormModelIdMutter = kundeFromSharePoint.FormModelId;
        }
        
        if(auftragskunde.Kunde.FormModelIdVater === this.kundeBackup.FormModelId) {
          auftragskunde.Kunde.FormModelIdVater = kundeFromSharePoint.FormModelId;
        }
  
        if(auftragskunde.Kunde.FormModelIdsKinder) {
          for(let i = 0; i < auftragskunde.Kunde.FormModelIdsKinder.length; i++) {
            if(auftragskunde.Kunde.FormModelIdsKinder[i] === this.kundeBackup.FormModelId) {
              auftragskunde.Kunde.FormModelIdsKinder[i] = kundeFromSharePoint.FormModelId;
            }
          }
        }
  
        if(auftragskunde.Kunde.ListItemIdsKinder) {
          for(let i = 0; i < auftragskunde.Kunde.ListItemIdsKinder.length; i++) {
            if(auftragskunde.Kunde.ListItemIdsKinder[i] === this.kundeBackup.ListItemId) {
              auftragskunde.Kunde.ListItemIdsKinder[i] = kundeFromSharePoint.ListItemId;
            }
          }
        }
      }
          
      this.auftragskundenChange.emit(this.auftragskunden);
  
      for(const kind of this.kinder) {
        if(kind.KundeFormModelId === this.kundeBackup.FormModelId) {
          kind.KundeFormModelId = kundeFromSharePoint.FormModelId;
        }
      }
      this.kinderChange.emit(this.kinder);
  
      this.spartaxPortalKunden.set(this.kunde.FormModelId, this.kunde);
      this.spartaxPortalKundenChange.emit(this.spartaxPortalKunden);
      
      this.loadingKunde = false;
      this.existingKundeLinked = true;
      this.initSpecialFields();
    }
  }

  onRemoveExistingCustomerLink() {
    const oldFormModelId = this.kunde.FormModelId;
    this.kunde = {...this.kundeBackup};
    this.kunde.procedureDiscussed = true;
    this.kundeChange.emit(this.kunde);
    from(this.auftragskunden).first(x => x.KundeFormModelId === oldFormModelId).KundeFormModelId = this.kunde.FormModelId;
    this.auftragskundenChange.emit(this.auftragskunden);
    this.spartaxPortalKunden.delete(this.kunde.FormModelId);
    this.spartaxPortalKundenChange.emit(this.spartaxPortalKunden);
    this.existingKundeLinked = false;
  }

  onSVNummerChange() {
    this.svNummerValid = true;

    if (this.svnummer > 999 && this.svnummer < 10000 && this.kunde.Geburtstag && !this.completionExecuted) {
      this.svnummer = +((this.svnummer + "") + moment(this.kunde.Geburtstag).format("DDMMYY"));
      this.completionExecuted = true;
    }
    if (this.svnummer > 99999999 && this.svnummer < 10000000000) {
      this.kunde.SVNummer = this.svnummer;
    }
  }

  get telefon1() {
    return this.kunde.Telefon1?.replace('+43', '');
  }

  onPhoneNumberChange(value: string) {
    if(value && value.length > 0) {
      value = value.replaceAll('[^\\d]', '').replaceAll('_','').replaceAll(' ', '').replace('+43', '');
      if(value.startsWith('0')) {
          value = value.substring(1);
      }
      if(value.length > 0) {
          value = '+43' + value;
      }
  }
    this.kunde.Telefon1 = value;
  }

  saveBirthDate() {
    if (this.isDateValid(this.geburtstag, this.geburtsmonat, this.geburtsjahr)) {
      let gebMonat = this.geburtsmonat + "";
      let gebTag = this.geburtstag + "";

      if (this.geburtsmonat < 10) {
        gebMonat = "0" + this.geburtsmonat;
      }
      if (this.geburtstag < 10) {
        gebTag = "0" + this.geburtstag;
      }

      this.kunde.Geburtstag = new Date(this.geburtsjahr + "-" + gebMonat + "-" + gebTag)
    }
  }

  saveDiedDate() {
    if (this.isDateValid(this.sterbetag, this.sterbemonat, this.sterbejahr)) {
      let sterbeMonat = this.sterbemonat+ "";
      let sterbetag = this.sterbetag + "";

      if (this.sterbemonat < 10) {
        sterbeMonat = "0" + this.sterbemonat;
      }
      if (this.sterbetag < 10) {
        sterbetag = "0" + this.sterbetag;
      }

      this.kunde.VerstorbenAm = new Date(this.sterbejahr + "-" + sterbeMonat + "-" + sterbetag)
    }
  }

  saveAusstellungDate() {
    if (this.isDateValid(this.ausstellungstag, this.ausstellungsmonat, this.ausstellungsjahr)) {
      let monat = this.ausstellungsmonat + "";
      let tag = this.ausstellungstag + "";

      if (this.ausstellungsmonat < 10) {
        monat = "0" + this.ausstellungsmonat;
      }
      if (this.ausstellungstag < 10) {
        tag = "0" + this.ausstellungstag;
      }

      this.kunde.AusstellungsdatumAusweis = new Date(this.ausstellungsjahr + "-" + monat + "-" + tag)
    }
  }

  isDateValid(tag, monat, jahr) {
    if (this.isAuftragskundePage) {
      if (tag || monat || jahr) {
        if (!(tag && monat && jahr)) {
          this.errorMsg = "Ein eingegebenes Datum ist unvollständig."
          return false;
        }
        if (jahr > (new Date()).getFullYear()) {
          this.errorMsg = "Ein eingegebener Jahr-Wert ist ungültig."
          return false;
        }
        if (monat < 1 || monat > 12) {
          this.errorMsg = "Ein eingegebener Monats-Wert ist ungültig."
          return false;
        }
        if (tag < 1 || tag > 31) {
          this.errorMsg = "Ein eingegebener Tag-Wert ist ungültig."
          return false;
        }
      }
    }
    this.errorMsg = "";
    return true;
  }

  onIbanChange() {
    this.ibanValid = true;
  }

  validateIBAN() {
    if(this.kunde.IBAN && this.kunde.IBAN.length > 0) {
      this.ibanValid = IBAN.isValid(this.kunde.IBAN);
    } else { 
      this.ibanValid = true;
    }
  }

  validateSVNummer() {
    const svNummerStr = this.kunde.SVNummer?.toString();
    if(svNummerStr && svNummerStr.length == 10) {
      const pruefZifferCalc = (
        (+svNummerStr.charAt(0) * 3) +
        (+svNummerStr.charAt(1) * 7) +
        (+svNummerStr.charAt(2) * 9) +
        (+svNummerStr.charAt(4) * 5) +
        (+svNummerStr.charAt(5) * 8) +
        (+svNummerStr.charAt(6) * 4) +
        (+svNummerStr.charAt(7) * 2) +
        (+svNummerStr.charAt(8) * 1) +
        (+svNummerStr.charAt(9) * 6)
      ) % 11;

      this.svNummerValid = pruefZifferCalc.toString() == svNummerStr.charAt(3);
    } else {
      this.svNummerValid = true;
    }
  }
}
