import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AuthService } from 'src/app/data/service/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-spartax-header',
  templateUrl: './spartax-header.component.html',
  styleUrls: ['./spartax-header.component.scss'],
})
export class SpartaxHeaderComponent implements OnInit {
  public spartaxProd = environment.spartaxProd;

  constructor(private navCtrl: NavController, private authService: AuthService) { }

  ngOnInit() {}

  isMobile() {
    if (window.innerWidth > 770) {
      return false;
    }
    return true;
  }

  async logout() {
    this.navCtrl.navigateRoot('login');
    this.authService.logout();
  }
}
