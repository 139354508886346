import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { User, UserRole } from 'src/app/data/entities';
import { AuthService } from 'src/app/data/service/auth.service';
import { UserService } from 'src/app/data/service/user.service';

@Component({
  selector: 'app-setuserrole',
  templateUrl: './setuserrole.component.html',
  styleUrls: ['./setuserrole.component.scss'],
})
export class SetUserRoleComponent implements OnInit {
  selectedRole: string;
  user: User;
  userIsAdmin: boolean;
  userIsCurrentUser: boolean;
  isWorking: boolean;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private authService: AuthService,
    private userService: UserService
  ) { }

  ngOnInit() {}

  ionViewWillEnter() {
    this.user = this.navParams.get('user');
    this.selectedRole = this.user.role.toString();
    this.userIsAdmin = this.user.role === UserRole.admin;
    this.userIsCurrentUser = this.authService.currentUserValue?.userId == this.user.userId;
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async onSubmit() {    
    this.isWorking = true;

    if(this.selectedRole !== this.user.role.toString()) {
      await this.userService.setUserRole(this.user.userId, this.selectedRole).toPromise();
    }
    this.modalController.dismiss();
  }
}
