import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SpartaxUserRole, SpartaxUserUtil } from 'src/app/data/dto/spartax/spartax-user.dto';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-spartax-order-success-dialog',
  templateUrl: './spartax-order-success-dialog.component.html',
  styleUrls: ['./spartax-order-success-dialog.component.scss'],
})
export class SpartaxOrderSuccessDialogComponent implements OnInit {
  orderDisplayName: string;
  orderCreationStatus: string;
  formId: string;
  orderListItemId: number;
  currentUserRole: SpartaxUserRole;

  constructor(private modalController: ModalController,) { }

  ngOnInit() {}

  closeModal() {
    this.modalController.dismiss();
  }

  startNewQuestionnaire() {
    window.location.href = `/forms/fillout/${this.formId}?edit=true`;
  }

  viewOrder() {
    const spartaxBaseUrl = environment.spartaxProd ? 'https://spartax.sharepoint.com/sites/portal' : 'https://spartax.sharepoint.com/sites/portaltest';
    const spartaxListId = environment.spartaxProd ? '314363d3-bdf9-46fc-9421-ab57a1a3bfad' : 'e63ba90a-f747-44db-8c9d-038a0f9a3eda';
    window.open(`${spartaxBaseUrl}/_layouts/15/ListForm.aspx?PageType=6&ListID=${spartaxListId}&ID=${this.orderListItemId}`, '_self');
  }

  canViewOrder() {
    return SpartaxUserUtil.hasMinimumRole(SpartaxUserRole.Sachbearbeiter, this.currentUserRole);
  }
}
