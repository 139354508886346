import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

export enum PageTypes {
  article = 'article',
  contact = 'business.business',
  profile = 'profile',
  website = 'website',
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private title: Title,
    private meta: Meta,
  ) { }

  updateMeta(title: string, description: string, visible: boolean, smTitle: string, pagetype: PageTypes, 
    imageUrl = "https://www.beeexpert.at/assets/illustrations/Element%2047PNG.png", imageMimeType?: string) {
    this.title.setTitle(title);

    if (description) {
      this.meta.addTag({ property: 'description', content: description });
      this.meta.addTag({ property: 'og:description', content: description });
    } else {
      this.meta.addTag({ property: 'description', content: "Finde mithilfe unseres wissenschaftlichen Matchings sowohl deine als auch die Persönlichkeitsmerkmale unserer zertifizierten Unernehmensberater*innen heraus." });
      this.meta.addTag({ property: 'og:description', content: "Finde mithilfe unseres wissenschaftlichen Matchings sowohl deine als auch die Persönlichkeitsmerkmale unserer zertifizierten Unernehmensberater*innen heraus." });
    }

    if (visible) {
      this.meta.addTag({ property: 'robots', content: 'index, follow' });
    } else {
      this.meta.addTag({ property: 'robots', content: 'noindex, nofollow' });
    }

    this.meta.addTag({ property: 'og:title', content: smTitle });
    this.meta.addTag({ property: 'og:type', content: pagetype });
    this.meta.addTag({ property: 'og:image', content: imageUrl });

    if(imageMimeType) {
      this.meta.addTag({ property: 'og:image:type', content: imageMimeType});
    } else {
      this.meta.removeTag("name='og:image:type'");
    }

    //this.meta.addTag({ property: 'og:image:secure_url', content: imageUrl });
    /*this.meta.addTag({ property: 'og:image:width', content: "800" });
    this.meta.addTag({ property: 'og:image:height', content: "600" });*/
    
    this.meta.addTag({ property: 'og:url', content: location.href });
  }

  addMeta() {
    this.title.setTitle("beeexpert - Berater*innen finden, die wirklich zu dir passen");
    this.meta.addTag({ property: 'description', content: "Finde mithilfe unseres wissenschaftlichen Matchings sowohl deine als auch die Persönlichkeitsmerkmale unserer zertifizierten Unernehmensberater*innen heraus." });
    this.meta.addTag({ property: 'og:description', content: "Finde mithilfe unseres wissenschaftlichen Matchings sowohl deine als auch die Persönlichkeitsmerkmale unserer zertifizierten Unernehmensberater*innen heraus." });
    this.meta.addTag({ property: 'robots', content: 'index, follow' });
    this.meta.addTag({ property: 'og:title', content: "beeexpert - Berater*innen finden, die wirklich zu dir passen" });
    this.meta.addTag({ property: 'og:type', content: PageTypes.website });
    this.meta.addTag({ property: 'og:image', content: "https://www.beeexpert.at/assets/illustrations/Element%2047PNG.png" });
    this.meta.addTag({ property: 'og:url', content: "https://www.beeexpert.at"});
  }


  public scrollFunction($event) {
    let mybutton = document.getElementById("toTopBtn");

    if ($event.detail.scrollTop >= 800) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }
}
