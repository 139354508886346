import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertController, IonAccordionGroup, IonCheckbox, IonInput } from '@ionic/angular';
import { SpartaxAuftragskunde, SpartaxAuftragskundeArt, SpartaxEinkunftsArt, SpartaxErklaerungsjahr, SpartaxSchaetzungsJahreseinkunftArt } from 'src/app/data/dto/spartax/spartax-auftragskunde.dto';
import { SpartaxFormModelDto, SpartaxFormModelDtoUtil } from 'src/app/data/dto/spartax/spartax-form-model.dto';
import { SpartaxFrage, SpartaxFragenfunktionKeys, SpartaxFragenkategorie } from 'src/app/data/dto/spartax/spartax-frage.dto';
import { FormEventsService } from 'src/app/data/service/form-events.service';
import { from } from "linq-to-typescript";
import * as moment from 'moment';

@Component({
  selector: 'app-spartax-additional-questions',
  templateUrl: './spartax-additional-questions.component.html',
  styleUrls: ['./spartax-additional-questions.component.scss'],
})
export class SpartaxAdditionalQuestionsComponent {
  @Input() 
  model: SpartaxFormModelDto;

  @Output()
  modelChange = new EventEmitter<SpartaxFormModelDto>();

  @Input() 
  questions: SpartaxFrage[];

  @Input()
  edit: boolean;

  @Input()
  debug: boolean;

  @Input()
  overviewMode: boolean;

  @ViewChild('formElement')
  ngForm!: NgForm;

  @ViewChild('categoriesAccordionGroup')
  categoriesAccordionGroup!: IonAccordionGroup;

  private overtakePromptsPresented: string[] = [];
    
  public visible = false;
  public questionCategories: SpartaxFragenkategorie[] = [];
  public relevantQuestions: SpartaxFrage[] = [];
  public questionByCategoryId: { [key: number]: SpartaxFrage[]; } = {};
  public openHelpContainer: number[] = [];
  public expandedCategories: string[] = [];
  
  constructor(
    private formEventService: FormEventsService,
    private alertController: AlertController,
  ) { }

  toggleHelpContainer(question: SpartaxFrage) {
    if(this.openHelpContainer.includes(question.ListItemId)) {
      this.openHelpContainer = this.openHelpContainer.filter(x => x !== question.ListItemId);
    } else {
      this.openHelpContainer.push(question.ListItemId);
    }
  }

  onShowFormStep() {
    this.relevantQuestions = from(this.questions).where(x => this.isQuestionRelevant(x)).toArray();

    if(this.overviewMode) {
      const answeredQuestions = from(this.model.auftragskunden)
        .selectMany(x => x.Erklaerungsjahre)
        .selectMany(x => x.FragenAntworten)
        .where(x => !!x.Antwort)
        .select(x => x.FrageListItemId)
        .distinct()
        .toArray();

      this.relevantQuestions = from(this.relevantQuestions).where(x => answeredQuestions.includes(x.ListItemId)).toArray();
    }

    this.questionCategories = from(this.relevantQuestions)
      .groupBy(x => x.Fragenkategorie.ListItemId)
      .select(x => x.first().Fragenkategorie)
      .orderBy(x => x.Reihenfolge)
      .toArray();

    for(const category of this.questionCategories) {
      this.questionByCategoryId[category.ListItemId] = from(this.relevantQuestions)
        .where(x => x.Fragenkategorie.ListItemId === category.ListItemId)
        .orderBy(x => x.Reihenfolge, ((a, b) => a - b))
        .toArray();
    }

    if(this.overviewMode) {
      this.expandedCategories = this.questionCategories.map(x => x.ListItemId + '');
    }

    this.visible = true;
  }

  onHideFormStep() {
    this.visible = false;
  }
  
  isQuestionRelevant(question: SpartaxFrage) {
    return from(this.model.auftragskunden).any(x => this.model.hideNotRelevantAdditionalQuestions ? this.isAuftragskundeRowVisible(question, x) : true);
  }

  isAuftragskundeRowVisible(question: SpartaxFrage, auftragskunde: SpartaxAuftragskunde) {
    for(let erklaerungsjahr of auftragskunde.Erklaerungsjahre) {
      if(this.isYearRelevant(question, erklaerungsjahr.Jahr, auftragskunde)) {
        return true;
      }
    }
  }
  
  isYearRelevant(question: SpartaxFrage, year: number | string, auftragskunde: SpartaxAuftragskunde) {
    const yearStr = year.toString();
    let relevant = false;
    const einkunftsartenOfThisYear = from(auftragskunde.Erklaerungsjahre).firstOrDefault(x => x.Jahr.toString() === yearStr)?.EinkunftsArten;

    //If overview mode, year is relevant if customer answered it
    if(this.overviewMode) {
      return from(auftragskunde.Erklaerungsjahre)
        .where(x => x.Jahr.toString() === yearStr)
        .selectMany(x => x.FragenAntworten)
        .where(x => !!x.Antwort && x.FrageListItemId === question.ListItemId)
        .any();
    }

    //If answered previously, year is relevant
    if(this.isQuestionAnsweredInPreviousYear(question, year, auftragskunde)) {
      return true;
    }

    //Check if person lived in this year
    if(auftragskunde.Kunde.VerstorbenAm && +year > moment(auftragskunde.Kunde.VerstorbenAm).year()) {
      return false;
    }

    //check if year is within range
    if(!from(auftragskunde.Erklaerungsjahre).any(x => x.Jahr === year)) {
      return false;
    }

    //Check relevance based on question functions
    if(question.Fragenfunktionen && question.Fragenfunktionen.find(x => x.Key === SpartaxFragenfunktionKeys.F123)) {
      return true;
    }

    if (question.Fragenfunktionen && question.Fragenfunktionen.find(x => x.Key === SpartaxFragenfunktionKeys.F122)) {
      if(!this.model.kinder.length) {
        return false;
      }
    }

    if(question.Fragenfunktionen && question.Fragenfunktionen.find(x => x.Key === SpartaxFragenfunktionKeys.F100)) {
      const kind = SpartaxFormModelDtoUtil.getKindForAuftragskunde(this.model, auftragskunde);
      if(kind) {
        relevant = kind.InAusbildung;
      } else {
        relevant = false;
      }
    } else if (question.Fragenfunktionen && question.Fragenfunktionen.find(x => x.Key === SpartaxFragenfunktionKeys.F108)) {
      relevant = einkunftsartenOfThisYear?.includes(SpartaxEinkunftsArt.pension);
    } else if (question.Fragenfunktionen && question.Fragenfunktionen.find(x => x.Key === SpartaxFragenfunktionKeys.F109)) {
      relevant = einkunftsartenOfThisYear?.includes(SpartaxEinkunftsArt.dienstverhaeltnis);
    } else if (question.Fragenfunktionen && question.Fragenfunktionen.find(x => x.Key === SpartaxFragenfunktionKeys.F110)) {
      const schaetzungJahreseinkunft = from(auftragskunde.Erklaerungsjahre)
          .firstOrDefault(x => x.Jahr.toString() === yearStr)?.SchaetzungJahreseinkunft;
      relevant = einkunftsartenOfThisYear?.includes(SpartaxEinkunftsArt.dienstverhaeltnis) && 
        (!!!schaetzungJahreseinkunft || schaetzungJahreseinkunft == SpartaxSchaetzungsJahreseinkunftArt.maximum);
    } else if (question.Fragenfunktionen && question.Fragenfunktionen.find(x => x.Key === SpartaxFragenfunktionKeys.F111)) {
      relevant = einkunftsartenOfThisYear?.length > 0 && auftragskunde.AuftragsKundeArt !== SpartaxAuftragskundeArt.kind;
    } else if (question.Fragenfunktionen && question.Fragenfunktionen.find(x => x.Key === SpartaxFragenfunktionKeys.F125)) {
      relevant = from(einkunftsartenOfThisYear).any(x => x === SpartaxEinkunftsArt.betrieb);
    } else if (question.Fragenfunktionen && question.Fragenfunktionen.find(x => x.Key === SpartaxFragenfunktionKeys.F126)) {
      relevant = from(einkunftsartenOfThisYear).any(x => x === SpartaxEinkunftsArt.vermietung || 
        x === SpartaxEinkunftsArt.kapitalvermoegen);
    } else {
      relevant = from(einkunftsartenOfThisYear).any(x => x === SpartaxEinkunftsArt.dienstverhaeltnis || x === SpartaxEinkunftsArt.pension);
    }
    
    //Last check if question is relevant for this year
    if(relevant) {
      for(const questionRelevantYear of question.Jahre) {
        if(questionRelevantYear.toLowerCase().includes('alle') ||
           questionRelevantYear === yearStr) {
          return true;
        }
      }
    }

    return false;
  }

  formIsValid() {
    return this.ngForm?.valid ?? true;
  }

  markAllControlsAsTouched() {
    for (const control in this.ngForm.controls) {
      this.ngForm.controls[control].markAsTouched();
    }
  }

  onRelevantQuestionsToggleChanged(event: Event) {
    this.model.hideNotRelevantAdditionalQuestions = (event.target as unknown as IonCheckbox).checked;
    this.onShowFormStep();
  }

  async onQuestionAnswerSelected(e: Event, question: SpartaxFrage, year: SpartaxErklaerungsjahr, auftragskunde: SpartaxAuftragskunde) {
    const checked = (e.target as unknown as IonCheckbox).checked;
    this.setQuestionAnswer(checked, question, year);
    
    if(checked && this.isYearRelevant(question, year.Jahr + 1, auftragskunde)) {
      await this.showOvertakePopup(checked, question, year, auftragskunde);
    }
  }

  setQuestionAnswer(value: boolean, question: SpartaxFrage, year: SpartaxErklaerungsjahr) {
    if(!year.FragenAntworten) {
      year.FragenAntworten = [];
    }

    let antwort = year.FragenAntworten.find(x => x.FrageListItemId === question.ListItemId);

    if(!antwort) {
      antwort = {
        FrageListItemId: question.ListItemId,
      };
      year.FragenAntworten.push(antwort);
    }

    antwort.Antwort = value;
  }

  onQuestionCommentEntered(e: Event, question: SpartaxFrage, auftragskunde: SpartaxAuftragskunde) {
    if(!auftragskunde.Kommentare) {
      auftragskunde.Kommentare = []
    }

    let kommentar = auftragskunde.Kommentare.find(x => x.FrageListItemId === question.ListItemId);

    if(!kommentar) {
      kommentar = {
        FrageListItemId: question.ListItemId,
        Kommentar: '',
      };
      auftragskunde.Kommentare.push(kommentar);
    }
    
    kommentar.Kommentar = (e.target as unknown as IonInput).value as string;
  }

  isQuestionAnsweredInPreviousYear(question: SpartaxFrage, year: number | string, auftragskunde: SpartaxAuftragskunde) {
    const yearStr = year.toString();
    const erklaerungsjahrAlt = from(auftragskunde.ErklaerungsjahreAlt || []).firstOrDefault(x => x.Jahr.toString() === yearStr);
    if(erklaerungsjahrAlt) {
      return from(erklaerungsjahrAlt.FragenAntworten).where(x => !!x.Antwort).any(x => x.FrageListItemId === question.ListItemId && !!x.Antwort);
    }
  }

  getQuestionAnswer(question: SpartaxFrage, year: SpartaxErklaerungsjahr) {
    if(!year.FragenAntworten) {
      year.FragenAntworten = [];
    }

    let antwort = year.FragenAntworten.find(x => x.FrageListItemId === question.ListItemId);

    if(antwort) {
      return antwort.Antwort;
    } else {
      return false;
    }
  }

  getQuestionComment(question: SpartaxFrage, auftragskunde: SpartaxAuftragskunde) {
    if(!auftragskunde.Kommentare) {
      auftragskunde.Kommentare = [];
    }

    let kommentar = auftragskunde.Kommentare.find(x => x.FrageListItemId === question.ListItemId);

    if(kommentar) {
      return kommentar.Kommentar;
    }
  }
    
  onQuestionAnswered(question: SpartaxFrage, showNextQuestion: boolean) {
    if(!this.model.sonstigeFragenBeantwortet) {
      this.model.sonstigeFragenBeantwortet = [];
    }

    if(!this.model.sonstigeFragenBeantwortet.includes(question.ListItemId)) {
      this.model.sonstigeFragenBeantwortet.push(question.ListItemId);
    }

    setTimeout(() => {
      this.formEventService.triggerSubmitEvent(false);  
    }, 0);

    if(showNextQuestion) {
      this.showNextQuestion(question);
    }
  }

  isQuestionAnswered(question: SpartaxFrage) {
    return this.model.sonstigeFragenBeantwortet && this.model.sonstigeFragenBeantwortet.includes(question.ListItemId);
  }

  markQuestionAsUnanswered(question: SpartaxFrage) {
    if(!this.model.sonstigeFragenBeantwortet) {
      this.model.sonstigeFragenBeantwortet = [];
    }

    const index = this.model.sonstigeFragenBeantwortet.indexOf(question.ListItemId);

    if(index >= 0) {
      this.model.sonstigeFragenBeantwortet.splice(index, 1);
    }

    setTimeout(() => {
      this.formEventService.triggerSubmitEvent(false);  
    }, 0);
  }

  getAccordionHeaderBadgeCount(questionCategory: SpartaxFragenkategorie) {
    const questionsOfCategory = this.questionByCategoryId[questionCategory.ListItemId];

    let answerCount = 0;
    for(const question of questionsOfCategory) {
      if(this.isQuestionAnswered(question)) {
        answerCount++;
      }
    }

    return `${answerCount}/${questionsOfCategory.length}`;
  }

  isAnyQuestionOfCategoryAnsweredWithYes(questionCategory: SpartaxFragenkategorie) {
    const questionsOfCategory = this.questionByCategoryId[questionCategory.ListItemId];
    for(const question of questionsOfCategory) {
      for(const auftragskunde of this.model.auftragskunden) {
        if(this.isAuftragskundeRowVisible(question, auftragskunde)) {
          for(const erklärungsjahr of auftragskunde.Erklaerungsjahre) {
            if(this.getQuestionAnswer(question, erklärungsjahr)) {
              return true;
            }
          }
        }
      }
    }
  }

  allQuestionsOfCategoryAnswered(questionCategory: SpartaxFragenkategorie) {
    for(const question of this.questionByCategoryId[questionCategory.ListItemId]) {
      if(!this.isQuestionAnswered(question)) {
        return false;
      }
    }

    return true;
  }

  showNextQuestion(currentQuestion: SpartaxFrage) {
    for(let i = 0; i < this.questionCategories.length; i++) {
      const questionsOfCategory = this.questionByCategoryId[this.questionCategories[i].ListItemId];
      for(let j = 0; j < questionsOfCategory.length; j++) {
        if(questionsOfCategory[j].ListItemId === currentQuestion.ListItemId) {
          //if last question of category => move to next category
          if(j + 1 === questionsOfCategory.length) {
            if(i + 1 === this.questionCategories.length) {
              return; //Do nothing on answering of last question
            } else {
              this.categoriesAccordionGroup.value = this.questionCategories[i + 1].ListItemId.toString();
              this.scrollToFirstQuestionOfCategory(this.questionCategories[i + 1]);
              return;
            }
          } else {
            this.scrollToQuestionRow(questionsOfCategory[j + 1]);
            return;
          }
        }
      }
    }
  }

  scrollToFirstQuestionOfCategory(questionCategory: SpartaxFragenkategorie) {
    const questionsOfCategory = this.questionByCategoryId[questionCategory.ListItemId];
    if(questionsOfCategory && questionsOfCategory.length > 0) {
      setTimeout(() => {
        this.scrollToQuestionRow(questionsOfCategory[0]);
      }, 750);
    }
  }

  scrollToQuestionRow(question: SpartaxFrage) {
    if(window.screen.width >= 1200) {
      const nextQuestionRow = document.querySelector("#beeex-question-divider-row-" + question.ListItemId);
      if(nextQuestionRow) {
        nextQuestionRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }

  onQuestionAccordionChange(event: Event) {
    if(event.target === event.currentTarget) {
      if(this.edit) {
        setTimeout(() => {
          this.formEventService.triggerSubmitEvent(false);
        }, 0);
      }

      if(this.categoriesAccordionGroup.value) {
        this.scrollToFirstQuestionOfCategory(this.questionCategories.find(x => x.ListItemId.toString() === this.categoriesAccordionGroup.value));
      }
    }
  }

  getAuftragskundenForQuestion(question: SpartaxFrage) {
    return this.model.auftragskunden.filter(x => this.isAuftragskundeRowVisible(question, x));
  }
  
  setInvisibleQuestionsToFalse() {
    for(const question of this.questions) {
      for(const auftragskunde of this.model.auftragskunden) {
        if(!this.isAuftragskundeRowVisible(question, auftragskunde)) {
          for(const erklärungsjahr of auftragskunde.Erklaerungsjahre) {
            this.setQuestionAnswer(false, question, erklärungsjahr);
          }
        } else {
          for(const erklärungsjahr of auftragskunde.Erklaerungsjahre) {
            if(!this.isYearRelevant(question, erklärungsjahr.Jahr, auftragskunde)) {
              this.setQuestionAnswer(false, question, erklärungsjahr);
            }
          }
        }
      }
    }
  }

  async showOvertakePopup(checked: boolean, question: SpartaxFrage, year: SpartaxErklaerungsjahr, auftragskunde: SpartaxAuftragskunde) {
    const key = `${auftragskunde.KundeFormModelId}_${question.ListItemId}`;
    if(this.overtakePromptsPresented.includes(key)) {
      return;
    } else {
      this.overtakePromptsPresented.push(key);
      const alert = await this.alertController.create({
        subHeader: 'Sollen die gewählte Antwort für die Folgejahre übernommen werden?',
        buttons: [
          {
            text: 'Nein',
            role: 'cancel',
          },
          {
            text: 'Ja',
            role: 'confirm',
            handler: () => {
              for(const erklärungsjahr of auftragskunde.Erklaerungsjahre) {
                if(erklärungsjahr.Jahr >= year.Jahr && this.isYearRelevant(question, erklärungsjahr.Jahr, auftragskunde)) {
                  this.setQuestionAnswer(checked, question, erklärungsjahr);
                }
              }
            },
          },
        ],
      });
  
      await alert.present();
      await alert.onDidDismiss();
    }
  }

  async showWiederaufnahmePopup(question: SpartaxFrage, year: SpartaxErklaerungsjahr, auftragskunde: SpartaxAuftragskunde) {
    let answer = false;
    const alert = await this.alertController.create({
      subHeader: 'Wiederaufnahme?',
      message: `Sind Sie sicher, dass die Frage für ${auftragskunde.Kunde.Vorname} im Jahr ${year.Jahr} wieder aufgerollt werden soll?`,
      buttons: [
        {
          text: 'Nein',
          role: 'cancel',
        },
        {
          text: 'Ja',
          role: 'confirm',
          handler: () => {
            answer = true;
          },
        },
      ],
    });

    await alert.present();
    await alert.onDidDismiss();

    //Automatically copy einkunftsarten und schätzung jahreseinkunft if wiederaufnahme
    if (answer) {
      if (!year.EinkunftsArten || year.EinkunftsArten.length === 0) {
        const erklaerungsjahrAlt = from(auftragskunde.ErklaerungsjahreAlt || []).firstOrDefault(x => x.Jahr === year.Jahr);
        if (erklaerungsjahrAlt) {
          year.EinkunftsArten = erklaerungsjahrAlt.EinkunftsArten;
          year.SchaetzungJahreseinkunft = erklaerungsjahrAlt.SchaetzungJahreseinkunft;
        }
      }
    }

    //Because ion-checkbox maintains checked state, we need to set it to false and then to true
    this.setQuestionAnswer(true, question, year);
    setTimeout(() => {
      this.setQuestionAnswer(answer, question, year);  
    }, 10);
  }
}
