import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-createnewcategory-popup',
  templateUrl: './createnewcategory-popup.component.html',
  styleUrls: ['./createnewcategory-popup.component.scss'],
})
export class CreatenewcategoryPopupComponent implements OnInit {

  @Input() availableCategories: any[];
  @Input() searchTerm: string;
  @Input() allCategories: any[];
  @Output() onDismiss = new EventEmitter<any>(); // Erstellt einen Event Emitter
  searchTermValue: string;


  constructor(private modalController: ModalController) { }
  ngOnInit(): void {
    this.searchTermValue = this.searchTerm;
  }


  capitalizeFirstLetter(value: string): string {
    if (value && typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }

  getCategoryName(categoryId: number): string {
    for (let cat of this.allCategories) {
      if (cat.categoryId === categoryId) {
        return cat.name;
      }
    }
  }

  // Methode, um Daten zurückzusenden und das Popover zu schließen
  async sendDataBack(id) {
    let data =
      {
        categoryId: id,
        name: this.searchTermValue
      };

    if (id !== -1) {
      data =
      {
        categoryId: id,
        name: this.getCategoryName(id)
      };
    }


      // Schließe hier das Popover, wenn notwendig
      this.modalController.dismiss(data); // Schließt das Popover
    }


  }
