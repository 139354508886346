import { ITokenPayload } from '../interfaces/token-payload';

export class TokenPayload implements ITokenPayload {
  userId: number;
  userName: string;
  role: string;
  exp: number;
  iat: number;
  refreshToken: string;
}
