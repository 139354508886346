import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ContactService } from 'src/app/data/service/contact.service';

@Component({
  selector: 'app-feedback-popup',
  templateUrl: './feedback-popup.component.html',
  styleUrls: ['./feedback-popup.component.scss'],
})
export class FeedbackPopupComponent implements OnInit {
  answer: number;

  selectedStars: number = 0;
  starselectionmade: boolean = false;

  constructor(public modalCtrl: ModalController, public contactService: ContactService) { }

  ngAfterViewInit() {
    if (this.answer > 3) {
      this.setwillAnswer(true);
    } else {
      this.setwillAnswer(false);
      document.getElementById("sub-head").style.display = "none";
    }
  }

  ngOnInit() {

  }

  get willAnswerMessage() {
    switch (this.answer) {
      case 1:
        return 'Oje, dann frage ich dich besser nicht, ob du bewerten möchtest wie dir beeexpert bisher gefällt, oder?';
      case 2:
        return 'Oje, dann frage ich dich eher nicht, ob du bewerten möchtest wie dir beeexpert bisher gefällt, oder?';
      case 3:
        return 'Unentschlossen? Möchtest du dann vielleich bewerten, wie dir beeexpert bisher gefällt?';
    }
  }

  setwillAnswer(value, forward = false) {
    if (value) {
      document.getElementById("not-answer-container").style.display = "none";
      document.getElementById("answer-container").style.display = "block";
    } else {
      document.getElementById("not-answer-container").style.display = "block";
      document.getElementById("answer-container").style.display = "none";
      if (forward) {
        this.modalCtrl.dismiss();
      }
    }
  }

  isMobile() {
    if (window.innerWidth > 770) {
      return false;
    }
    return true;
  }

  async sendFeedback(){
    document.getElementById("send-msg").style.display = "block";
    document.getElementById("button-container").style.display = "none";

    this.contactService
    .sendStarRating({
      stars: this.selectedStars
    })
    .toPromise();
    this.modalCtrl.dismiss();
  }


  starType(star: number): string {
    return star <= this.selectedStars ? 'star' : 'star-outline';
  }

  highlightStars(star: number): void {
    if (!this.starselectionmade) {
      if (this.selectedStars === star) {
        return; // Keine Aktion, wenn der Stern bereits markiert ist
      }
      this.selectedStars = star;
    }
  }

  close(){
    this.modalCtrl.dismiss();
  }

  resetStars(): void {
    if (!this.starselectionmade) {
      if (this.selectedStars === 0) {
        return; // Keine Aktion, wenn keine Sterne markiert sind
      }
      this.selectedStars = 0;
    }
  }

  rate(star: number): void {
    this.selectedStars = star;
    this.starselectionmade = true;
  }


}
