import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { User } from 'src/app/data/entities';
import { AuthService } from 'src/app/data/service/auth.service';
import { CompanyService } from 'src/app/data/service/company.service';
import { ScriptService } from 'src/app/data/service/script.service';
import { UserService } from 'src/app/data/service/user.service';

declare function profilePicture(cssSelector: string, imageUrl?: string, options?: any): void;

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
  @Input()
  public mode: 'profile' | 'company';

  @Input()
  public page: string;

  @Input()
  public willBeConsultant: boolean = false;

  public working = false;
  public errorMessage = '';

  private pp: any;
  private imageChanged = false;
  private imageCleared = true;
  private user: User;
  public redirectUrl: string;

  constructor(
    private userService: UserService,
    private navCtrl: NavController,
    private authService: AuthService,
    private scriptService: ScriptService,
    private companyService: CompanyService,
    private modalController: ModalController
  ) { }

  async ngOnInit() {
    if (!this.authService.currentUserValue?.userId) {
      this.navCtrl.navigateRoot('/login');
    }



    this.user = await this.userService.getUser(this.authService.currentUserValue?.userId).toPromise<User>();

    // Remove the code that sets the initial image data from existingImageUrl
    let existingImageUrl = null;

    await this.scriptService.load('jquery');
    await this.scriptService.load('profile-picture');

    // Comment out the lines that fetch the existing image and create the imageDataUrl
    // const getImageResponse = await fetch(existingImageUrl, {
    //   headers: {
    //     'Content-Type': 'application/octet-stream'
    //   },
    //   cache: "no-store"
    // });
    // const imageBlob = await getImageResponse.blob();
    // const imageDataUrl = URL.createObjectURL(imageBlob);

    this.pp = new profilePicture('.profile', null, { onChange: () => { this.imageChanged = true; this.imageCleared = false; } });
  }

  clearImage() {
    this.imageCleared = true;
  }

  forward() {
    setTimeout(async () => {
      let navigationExtras = {
        queryParams: {
          willBeConsultant: true
        }
      };
      await this.modalController.dismiss();


      // Timeout benötigt, da das Backend sonst das Alte Bild nochmals liefert.
      if (this.page == 'company-edit') {
        if (this.willBeConsultant) {
          this.navCtrl.navigateRoot('/profile/edit', navigationExtras);
        }
        else {
          this.navCtrl.navigateRoot('/companyprofile');
        }

      } else {
        if (this.page == 'profile-edit') {
          if (this.willBeConsultant) {
            this.navCtrl.navigateRoot('/success-field-filter', navigationExtras);
          } else {
       //     this.navCtrl.navigateRoot('/profile');

          }

        } else {
          this.navCtrl.navigateRoot(this.redirectUrl);
        }
      }
    }, 300);
  }

  async submitForm() {
    this.working = true;

    try {
      if (this.imageChanged || this.imageCleared) {
        switch (this.mode) {
          case 'profile':
            this.redirectUrl = '/profile';
            await this.userService.uploadUserImage({ imageAsDataUri: this.imageCleared ? null : this.pp.getAsDataURL() }).pipe(first()).toPromise();
            break;
          case 'company':
            this.redirectUrl = '/companyprofile';
            await this.companyService.uploadCompanyImage({ imageAsDataUri: this.imageCleared ? null : this.pp.getAsDataURL() }).pipe(first()).toPromise();
            break;
        }
      }

      await this.forward();
    } catch (err) {
      console.error(err);
      this.errorMessage =
        'Beim Speichern des Bildes ist ein Problem aufgetreten';
    }

    this.working = false;
  }
}
