import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FormFilloutDto } from "../dto";
import { GenerateDocumentDto } from "../dto/generate-document.dto";
import { SendFormFilloutMailDto } from "../dto/send-form-fillout-mail.dto";
import { ShareFilloutDto } from "../dto/share-fillout.dto";
import { BeeexpertFormsForm } from "../entities/form.entity";

@Injectable({
    providedIn: 'root'
})
export class FormService {
    constructor(private http: HttpClient) {}

    getMyCompaniesForms(): Observable<BeeexpertFormsForm[]> {
        return this.http.get<BeeexpertFormsForm[]>(`${environment.apiUrl}/form/myCompanies/all`, {
            responseType: 'json',
        });
    }

    getById(id: string): Observable<BeeexpertFormsForm> {
        return this.http.get<BeeexpertFormsForm>(`${environment.apiUrl}/form/${id}`, {
            responseType: 'json',
        });
    }

    getByIdPublic(id: string): Observable<BeeexpertFormsForm> {
        return this.http.get<BeeexpertFormsForm>(`${environment.apiUrl}/form/public/${id}`, {
            responseType: 'json',
        });
    }

    createOrUpdateFillout(filloutDto: FormFilloutDto): Observable<any> {
        if(filloutDto.id) {
            return this.http.patch(`${environment.apiUrl}/form/fillout/${filloutDto.id}`, filloutDto);
        } else {
            return this.http.post(`${environment.apiUrl}/form/fillout/`, filloutDto);
        }
    }

    copyFillout(id: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/form/fillout/${id}/copy`, null, {
            responseType: 'json',
        });
    }

    getFilloutById(id: string, formId: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/form/${formId}/fillout/${id}`, {
            responseType: 'json',
        });
    }

    getFilloutByIdPublic(id: string, formId: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/form/public/${formId}/fillout/${id}`, {
            responseType: 'json',
        });
    }

    generateDocument(generateDocumentDto: GenerateDocumentDto): Observable<any> {
        return this.http.post(`${environment.apiUrl}/form/generatedocument/`, generateDocumentDto,
            {responseType: 'arraybuffer'});
    }

    sendMail(sendFormFilloutMailDto: SendFormFilloutMailDto): Observable<any> {
        return this.http.post(`${environment.apiUrl}/form/sendMail/`, sendFormFilloutMailDto);
    }

    getFilloutsByForm(id: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/form/${id}/fillouts`, {
            responseType: 'json',
        });
    }

    shareFillout(shareFilloutDto: ShareFilloutDto): Observable<any> {
        return this.http.post(`${environment.apiUrl}/form/share/`, shareFilloutDto);
    }
}