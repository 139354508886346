export enum TrackingPoint {
    profileVisit = 'profileVisit', //Aufruf eines Profils
    searchResult = 'searchResult', //Darstellung in Suchergebnissen
    socialMedia = 'socialMedia', //Buttons zur Kontaktaufnahme (zB Social Media)
    userContactForm = 'userContactForm', //Anfrage stellen button
    readMoreProfile = 'readMoreProfile', //Mehr lesen button
    seeAllCompanyDetails = 'seeAllCompanyDetails', //Alle Details ansehen button
    readReference = 'readReference', //Referenz lesen button
    call = 'call', //Anruf getätigt
    recall = 'recall', //Rückruf angefordert
    message = 'message', //Nachricht gesendet
}

export class TrackingDto {
    id?: string;
    userId: number;
    companyId?: number;
    trackingPoint: TrackingPoint;
    visitorUserId?: number;
    timestamp: Date;
    //calculated properties from backend service:
    readonly year?: number;
    readonly month?: string;
    readonly week?: string;
    readonly unix?: number;
}