import { Component, Input, OnInit } from '@angular/core';
import { SpartaxAuftragskunde, SpartaxAuftragskundeArt } from 'src/app/data/dto/spartax/spartax-auftragskunde.dto';
import { SpartaxFormModelDto } from 'src/app/data/dto/spartax/spartax-form-model.dto';
import { SpartaxKundeUtil } from 'src/app/data/dto/spartax/spartax-kunde.dto';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-spartax-bottom',
  templateUrl: './spartax-bottom.component.html',
  styleUrls: ['./spartax-bottom.component.scss'],
})
export class SpartaxBottomComponent implements OnInit {

  @Input()
  model: SpartaxFormModelDto;

  public spartaxProd = environment.spartaxProd;

  private persArray;

  isExpanded: boolean = true;

  ngOnInit(): void { }

  toggleComponent() {
    this.isExpanded = !this.isExpanded;
  }

  personsChanged(arr) {
    this.persArray = arr;
  }

  isChild(auftragskunde){
    return auftragskunde.AuftragsKundeArt == SpartaxAuftragskundeArt.kind;
  }

  getName(auftragskunde: SpartaxAuftragskunde) {
    return SpartaxKundeUtil.getVollstaendigerNameOfKunde(auftragskunde.Kunde, true);
  }
}
