import { Component, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from 'src/app/data/service/auth.service';

@Component({
  selector: 'app-pricingcomp',
  templateUrl: './pricingcomp.component.html',
  styleUrls: ['./pricingcomp.component.scss'],
})
export class PricingcompComponent implements OnInit {

  isLoggedIn: boolean;

  constructor(private authService: AuthService, public nav: NavController) { }

  ngOnInit() {

  }

  navigateToPackge(amount: number, pack: string) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        amount: amount,
        subCode: pack
      }
    };

    if (this.authService.isLoggedIn()) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }

    if(this.isLoggedIn){
      this.nav.navigateRoot('/company-checkout/' + pack, navigationExtras);
    }else{
      this.nav.navigateRoot('/signup', navigationExtras);
    }

  }

  redirectPromotion() {
    
    let navigationExtras: NavigationExtras;

    navigationExtras = {
      queryParams: {
        amount: 1,
        couponcode: "1Monat",
        subCode: "a",
      }
    };


    if (this.authService.isLoggedIn()) {
      this.nav.navigateRoot('/company-checkout/a', navigationExtras);
    } else {
      this.nav.navigateRoot('/signup', navigationExtras);
    }
  }


}
