import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { SpartaxAuftragskunde } from 'src/app/data/dto/spartax/spartax-auftragskunde.dto';
import { SpartaxFormModelDto, SpartaxFormModelDtoUtil } from 'src/app/data/dto/spartax/spartax-form-model.dto';
import { SpartaxFamilienstandArt, SpartaxFamilienstandArtenName, SpartaxKunde, SpartaxKundeUtil } from 'src/app/data/dto/spartax/spartax-kunde.dto';
import { SpartaxFamilienstand } from 'src/app/data/dto/spartax/spartax-kunde.dto';

@Component({
  selector: 'app-spartax-maritial-status',
  templateUrl: './spartax-maritial-status.component.html',
  styleUrls: ['./spartax-maritial-status.component.scss'],
})
export class SpartaxMaritialStatusComponent implements OnInit {
  @Input()
  model: SpartaxFormModelDto;

  @Input()
  edit: boolean;

  @Output()
  modelChange = new EventEmitter<SpartaxFormModelDto>();

  @ViewChild('formElement')
  ngForm!: NgForm;

  public currentYear = new Date().getFullYear();
  public error: string;

  public get hauptkunde(): SpartaxAuftragskunde {
    return SpartaxFormModelDtoUtil.getHauptkundeAuftragskunde(this.model);
  }

  public get partner(): SpartaxAuftragskunde {
    return SpartaxFormModelDtoUtil.getPartnerAuftragskunde(this.model);
  }

  public get hauptkundeVollstaendigerName(): string {
    return SpartaxKundeUtil.getVollstaendigerNameOfKunde(this.hauptkunde.Kunde);
  }

  ngOnInit() { }

  setDetailedDateKnown(familienstand) {
    familienstand.SeitDatumNichtBekannt = !familienstand.SeitDatumNichtBekannt;
  }

  markAllControlsAsTouched() {
    for (const control in this.ngForm.controls) {
      this.ngForm.controls[control].markAsTouched();
    }
  }

  get possibleFamilienstaende() {
    let values = Object.keys(SpartaxFamilienstandArt);
    let retval = [];

    if (this.hauptkunde.Kunde?.Familienstaende?.length < 2) {
      if (this.hauptkunde.Kunde.GemeinsamerHaushalt == true) {
        for (let value of values) {
          if (value == SpartaxFamilienstandArt.verheiratet || value == SpartaxFamilienstandArt.lebensgemeinschaft) {
            retval.push(value);
          }
        }
      } else {
        for (let value of values) {
          if (value != SpartaxFamilienstandArt.verheiratet && value != SpartaxFamilienstandArt.lebensgemeinschaft) {
            retval.push(value);
          }
        }
      }
      return retval;

    } else {
      return values;
    }
  }

  get possibleGenders() {
    return {
      "Männlich": "Männlich",
      "Weiblich": "Weiblich"
    };
  }

  getFamilienstandTimeLabel(familienstaende: SpartaxFamilienstand[], index: number) {
    let time = '';
    let timePrevious = '';

    if(familienstaende[index].SeitDatumNichtBekannt && familienstaende[index].Seit) {
      time = familienstaende[index].Seit.toString();
    } else if(!familienstaende[index].SeitDatumNichtBekannt && familienstaende[index].SeitDatum) {
      time = moment(familienstaende[index].SeitDatum).format('DD.MM.YYYY');
    }

    if(familienstaende[index - 1]?.SeitDatumNichtBekannt && familienstaende[index - 1]?.Seit) {
      timePrevious = familienstaende[index - 1].Seit.toString();
    } else if(!familienstaende[index - 1]?.SeitDatumNichtBekannt && familienstaende[index - 1]?.SeitDatum) {
      timePrevious = moment(familienstaende[index-1].SeitDatum).format('DD.MM.YYYY');
    }

    if(time.length < 4) {
      time = '?';
    }

    if(timePrevious.length < 4) {
      timePrevious = '?';
    }

    if(index == 0) {
      return `Seit ${time}`;
    } else {
      return `Von ${time} bis ${timePrevious}`; 
    }
  }

  validateFamilienstaendeOfPerson(familienstaende: SpartaxFamilienstand[], validateForFormValidation = true): boolean {
    let currentFamilienstandYear = Number.MAX_SAFE_INTEGER;

    for (let familienstand of familienstaende || []) {
      if (!familienstand.SeitDatumNichtBekannt) {
        if (new Date() < new Date(familienstand.SeitDatum)) {
          return false;
        }

        if (!this.validateFamilienstaendeOfPerson(familienstand.FamilienstaendePartner)) {
          return false;
        }
      } else {
        if (currentFamilienstandYear < familienstand.Seit) {
          return false;
        }

        if (validateForFormValidation) {
          if ((familienstand.Seit + "").length !== 4 || familienstand.Seit < 1900 || familienstand.Seit > new Date().getFullYear()) {
            return false;
          }
        }

        currentFamilienstandYear = familienstand.Seit;
        if (!this.validateFamilienstaendeOfPerson(familienstand.FamilienstaendePartner)) {
          return false;
        }
      }
    }

    return true;
  }

  validateYearEntries(validateForFormValidation = true): boolean {
    if (this.validateFamilienstaendeOfPerson(this.hauptkunde.Kunde.Familienstaende, validateForFormValidation)) {
      this.error = "";
      return true;
    } else {
      this.error = "Bitte überprüfe die Jahreszahlen/Zeiträume auf deren Richtigkeit!";
      return false;
    }
  }

  formIsValid() {
    if (!this.ngForm.valid) {
      return false;
    } else {
      return this.validateYearEntries(true);
    }
  }

  getDisplayNameOfFamilienstandArt(familienstandArt: SpartaxFamilienstandArt | string) {
    return SpartaxFamilienstandArtenName.get(familienstandArt);
  }

  selectedGemeinsamerHaushalt() {
    if (this.hauptkunde.Kunde.Familienstaende.length < 1) {
      this.hauptkunde.Kunde.Familienstaende.push({})
    }
    this.hauptkunde.Kunde.Familienstaende[0].Art = null;
  }

  familienstandYearChanged(array: SpartaxFamilienstand[], idx: number) {
    if (!array[idx].SeitDatumNichtBekannt) {
      const currentDate = new Date();
      const sixYearsAgo = new Date();
      sixYearsAgo.setFullYear(currentDate.getFullYear() - 6);

      if (array.length - 1 == idx) {
        if (new Date(array[idx].SeitDatum) > sixYearsAgo && new Date(array[idx].SeitDatum) < new Date()) {
          array.push({ SeitDatumNichtBekannt: true })
        }
      } else {
        if (new Date(array[idx].SeitDatum) <= sixYearsAgo || new Date(array[idx].SeitDatum) > new Date()) {
          array.length = idx + 1;
        }
      }
    } else {
      if (array[idx].Seit > 1900) {
        let minYear = new Date().getFullYear() - 6;
        if (array.length - 1 == idx) {
          if (array[idx].Seit > minYear && array[idx].Seit <= new Date().getFullYear()) {
            array.push({})
          }
        } else {
          if (array[idx].Seit <= minYear || array[idx].Seit > new Date().getFullYear()) {
            array.length = idx + 1;
          }
        }
      }
    }

    this.validateYearEntries(false);
  }

  showPartner(idx: number) {
    const familienstand = this.hauptkunde.Kunde.Familienstaende[idx];
    let show = false;
    if (familienstand && familienstand.Art) {
      if(familienstand.Art === SpartaxFamilienstandArt.verheiratet || familienstand.Art === SpartaxFamilienstandArt.lebensgemeinschaft) {
        show = true;
      } else {
        if(familienstand.Art === SpartaxFamilienstandArt.verwitwet) {
          if(familienstand.Seit && familienstand.SeitDatumNichtBekannt) {
            show = familienstand.Seit > new Date().getFullYear() - 6;
          } else if (familienstand.SeitDatum) {
            show = moment(familienstand.SeitDatum).isAfter(moment().subtract(6, 'years'));
          }
        }
      }
    }
    return show;
  }

  getEx(idx: number) {
    if (this.hauptkunde.Kunde.Familienstaende[idx].Art) {
      if (this.hauptkunde.Kunde.Familienstaende[idx].Art === SpartaxFamilienstandArt.geschieden) {
        return "Ex-"
      }
    }
    return "";
  }

  partnerNameEntered(idx: number) {
    if (!this.hauptkunde.Kunde.Familienstaende[idx]?.FamilienstaendePartner) {
      this.hauptkunde.Kunde.Familienstaende[idx].FamilienstaendePartner = [];
    }

    if (this.hauptkunde.Kunde.Familienstaende[idx].FamilienstaendePartner.length < 1) {
      this.hauptkunde.Kunde.Familienstaende[idx].FamilienstaendePartner.push({
        Seit:  this.hauptkunde.Kunde.Familienstaende[idx].Seit,
        Art: this.hauptkunde.Kunde.Familienstaende[idx].Art,
        SeitDatum: this.hauptkunde.Kunde.Familienstaende[idx].SeitDatum,
        SeitDatumNichtBekannt: this.hauptkunde.Kunde.Familienstaende[idx].SeitDatumNichtBekannt
      });

      const minYear = new Date().getFullYear() - 6;
      if (this.hauptkunde.Kunde.Familienstaende[idx].FamilienstaendePartner[0].Seit > minYear) {
        this.hauptkunde.Kunde.Familienstaende[idx].FamilienstaendePartner.push({ SeitDatumNichtBekannt: true });
      }
    }
  }
}
