//WARNING - Types MUST be kept in Sync between the Spartax Azure Function Project and with the ones in Beeexpert manually

export type SpartaxFragenkategorie = {
    ListItemId: number;
    Title: string;
    Reihenfolge: number;
}

export type SpartaxFragenfunktion = {
    ListItemId: number;
    Key: string;
    Title: string;
    Hinweis: string;
}

export type SpartaxFrage = {
    ListItemId: number;
    Title: string;
    Reihenfolge: number;
    Hinweis: string;
    Hilfetext: string;
    Belegtitel: string;
    Belegbeschreibung: string;
    Loeschabfrage: string;
    Pflichtveranlagung: boolean;
    Fragenkategorie: SpartaxFragenkategorie;
    Jahre: string[];
    Fragenfunktionen: SpartaxFragenfunktion[];
}

export enum SpartaxFragenfunktionKeys {
    F100 = 'F100', //Frage ist nur für Kinder in Ausbildung relevant. Alle anderen Personen werden ausgeblendet
    F101 = 'F101', //Diese Frage wurde aufgrund einer Antwort in der Masterfrage "Kinder" vorbefüllt.
    F102 = 'F102', //Masterfrage Behinderungen - Fragen A und/oder B
    F103 = 'F103', //Masterfrage Behinderungen - Frage C
    F104 = 'F104', //Masterfrage Behinderungen - Frage E
    F105 = 'F105', //Masterfrage Behinderungen - Beeinträchtigung in Kommentar schreiben
    F106 = 'F106', //Masterfrage Behinderungen - NICHT MEHR IM EINSATZ
    F107 = 'F107', //Diese Frage wurde aufgrund einer Antwort in der Masterfrage "Kinder" vorbefüllt.
    F108 = 'F108', //Diese Frage wurde aufgrund einer Antwort in der Masterfrage "Einkunftsarten" vorbefüllt.
    F109 = 'F109', //Diese Fragen sollten nur bei jenen Personen und Jahren ausgegeben werden, die Einkünfte aus einem Dienstverhältnis erzielt haben. Nicht bei Einkünften aus Betrieb, Vermietung / Verpachtung oder Kapitalvermögen.
    F110 = 'F110', //Diese Fragen sollten nur bei jenen Personen und Jahren ausgegeben werden, die Einkünfte aus einem Dienstverhältnis hatten UND in "Jahreseinkünfte" die Antwort "Weiß ich nicht" bzw. keine Antwortmöglichkeit gewählt wurde.
    F111 = 'F111', //Die Fragen sollten für alle Personen und Jahre mit Einkünften, egal welche, ausgegeben werden. Nicht jedoch für Kinder!
    F112 = 'F112', //Masterfrage Behinderungen - NICHT MEHR IM EINSATZ
    F113 = 'F113', //Masterfrage Behinderungen - NICHT MEHR IM EINSATZ
    F114 = 'F114', //Masterfrage Behinderungen - NICHT MEHR IM EINSATZ
    F115 = 'F115', //Masterfrage Behinderungen - NICHT MEHR IM EINSATZ
    F116 = 'F116', //Masterfrage Behinderungen - NICHT MEHR IM EINSATZ
    F117 = 'F117', //Masterfrage Behinderungen - NICHT MEHR IM EINSATZ
    F118 = 'F118', //Diese Frage wurde aufgrund einer Antwort in der Masterfrage "Kinder" vorbefüllt. (Familienbeihilfe)
    F119 = 'F119', //Diese Frage wurde aufgrund einer Antwort in der Masterfrage "Kinder" vorbefüllt. (Alimente bezahlt)
    F120 = 'F120', //Diese Frage wurde aufgrund einer Antwort in der Masterfrage "Einkunftsarten" vorbefüllt. (Einkünfte aus Nebenverdienst)
    F121 = 'F121', //Diese Frage wurde aufgrund einer Antwort in der Masterfrage "Einkunftsarten" vorbefüllt. (Einkünfte aus Beteiligungen)
    F122 = 'F122', //Nur wenn Kinder erfasst wurden
    F123 = 'F123', //Frage immer stellen
    F124 = 'F124', //Frage im Auftrag auch dann relevant wenn Kunde nicht Teil des Auftrags
    F125 = 'F125', //Relevant wenn Einkunftsart "Betrieb" gewählt wurde
    F126 = 'F126', //Relevant wenn Einkunftsart "Vermietung / Verpachtung" gewählt wurde
}