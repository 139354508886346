import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/data/entities';
import { UserService } from 'src/app/data/service/user.service';
import { UserBfi } from 'src/app/data/entities/user-bfi.entity';
import { AuthService } from 'src/app/data/service/auth.service';
import { NavigationExtras, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { state } from '@angular/animations';
import { ArgumentOutOfRangeError } from 'rxjs';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss'],
})
export class UserlistComponent implements OnInit {
  @Input() users: User[];
  @Input() quotePosition;
  @Input() catId;
  @Input() searchTerm;

  currentUserBfi: UserBfi;
  selectedQuoteIdx: number;
  quote = [
    {
      "quote": "Es gibt auch nichts Schöneres, wenn sich unterschiedliche Menschen gemeinsam aufmachen ein Anliegen zu verfolgen, denn dann wird’s wunderschön bunt und reich",
      "author": "Gerald Hüther",
      "action": ""
    },
    {
      "quote": "Wer um Rat bittet, sucht fast immer einen Komplizen.",
      "author": "Joseph-Louis de La Grange",
      "action": ""
    },
    {
      "quote": "Man ist schlecht beraten, wenn man sich nur von Leuten beraten läßt, die nie widersprechen.",
      "author": "Ludwig Rosenberg",
      "action": ""
    },
    {
      "quote": "Ich benütze nicht nur das Gehirn, das ich besitze, sondern ich borge mir noch zusätzlich, was ich bekommen kann.",
      "author": "Thomas Woodrow Wilson",
      "action": ""
    },
    {
      "quote": "Es ist eine große Dummheit, allein klug sein zu wollen.",
      "author": "François de La Rochefoucauld",
      "action": ""
    },
    {
      "quote": "Das Schwierigste, was es in der Welt zu verstehen gilt, ist die Einkommensteuer.",
      "author": "Albert Einstein",
      "action": "",
      "catId": "1"
    },
    {
      "quote": "Wer aufhört zu werben, um Geld zu sparen, kann genauso gut seine Uhr anhalten, um Zeit zu sparen.",
      "author": "Henry Ford",
      "action": "",
      "catId": "7"
    },
    {
      "quote": "Ich war noch nie in einer Situation, wo Geld zu besitzen die Situation schlimmer gemacht hätte.",
      "author": "Clinton Jones",
      "action": "",
      "catId": "1"
    },
    {
      "quote": "Marketing kann man in einem Tag lernen. Leider braucht man aber ein Leben lang, um es zu beherrschen.",
      "author": "Peter Drucker",
      "action": "",
      "catId": "7"
    },
    {
      "quote": "Am Anfang eines großen Erfolges steht immer eine Vision.",
      "author": "Hermann Simon",
      "action": "",
      "catId": "2"
    },
    {
      "quote": "Wer hohe Türme bauen will, muss lange beim Fundament verweilen.",
      "author": "Josef Anton Bruckner",
      "action": "",
      "catId": "2"
    },
    {
      "quote": "Unternehmensführung ist nicht die Beschäftigung mit Gegenwartsproblemen, sondern die Gestaltung der Zukunft.",
      "author": "Daniel Goeudevert",
      "action": "",
      "catId": "3"
    },
    {
      "quote": "Wir haben gelernt, bei der Planung technischer Abläufe immer wieder zu fragen: Wo bleibt der Mensch?",
      "author": "Detlef Brochhaus",
      "action": "",
      "catId": "3"
    },
    {
      "quote": "Wer nicht jeden Tag etwas für seine Gesundheit aufbringt, muss eines Tages sehr viel Zeit für die Krankheit opfern.",
      "author": "Sebasitan Kneipp",
      "action": "",
      "catId": "4"
    },
    {
      "quote": "Du kannst all die Reichtümer und Erfolge der Welt haben, aber wenn Du nicht gesund bist, hast Du nichts.",
      "author": "Steven Adler",
      "action": "",
      "catId": "4"
    },
    {
      "quote": "Wenn du im Recht bist, kannst du dir leisten, die Ruhe zu bewahren; und wenn du im Unrecht bist, kannst du dir nicht leisten, sie zu verlieren.",
      "author": "Mahatma Gandhi",
      "action": "",
      "catId": "5"
    },
    {
      "quote": "Den Namen des Rechtes würde man nicht kennen, wenn es das Unrecht nicht gäbe.",
      "author": "Heraklit",
      "action": "",
      "catId": "5"
    },
    {
      "quote": "Glückliche Mitarbeiter machen Kunden glücklich – und umgekehrt.",
      "author": "Hübner, Sabine",
      "action": "",
      "catId": "6"
    },
    {
      "quote": "Mitarbeiter können alles: wenn man sie weiterbildet, wenn man ihnen Werkzeuge gibt, vor allem aber, wenn man es ihnen zutraut.",
      "author": "Hans-Olaf Henkel",
      "action": "",
      "catId": "6"
    },
    {
      "quote": "Ordnung, ist die dunkle Seite der Seele.",
      "author": "Sonnenstern, Ziggy",
      "action": "",
      "catId": "8"
    },
    {
      "quote": "Organisation ist ein Mittel, die Kräfte des einzelnen zu vervielfältigen.",
      "author": "Peter F. Drucker",
      "action": "",
      "catId": "8"
    },
    {
      "quote": "Das Leben besteht hauptsächlich darin, dass man mit dem Unvorhergesehenen fertig werden muss.",
      "author": "John Steinbeck",
      "action": "",
      "catId": "9"
    },
    {
      "quote": "Das Problem zu erkennen ist wichtiger als die Lösung zu erkennen, denn die genaue Darstellung des Problems führt zur Lösung.",
      "author": "Albert Einstein",
      "action": "",
      "catId": "9"
    },
    {
      "quote": "Die Techniker haben die Welt nur verändert, es kommt darauf an, sie zu verstehen.",
      "author": "Jacques Wirion",
      "action": "",
      "catId": "10"
    },
    {
      "quote": "Wege entstehen dadurch, dass man sie geht.",
      "author": "Franz Kafka",
      "action": "",
      "catId": "10"
    },
    {
      "quote": "Wir müssen nicht weniger wachsen und wirtschaften, sondern anders!",
      "author": "Hans-Dietrich Reckhaus",
      "action": "",
      "catId": "11"
    },
    {
      "quote": "Die Nachhaltigkeitsunternehmer*innen werden die Role Models im Neuen Land.",
      "author": "Verena Pausder",
      "action": "",
      "catId": "11"
    }
  ];

  constructor(private authService: AuthService,
    private userService: UserService,
    public nav: NavController,
    public router: Router

  ) {
  }

  async ngOnInit() {
    for (let user of this.users) {
      if (!user.image) {
        user.image = this.userService.getUserImageUrl(user.userId);
      }
    }

    if (this.authService.isLoggedIn()) {
      const currentUser = await this.userService
        .getUser(this.authService.currentUserValue?.userId)
        .toPromise();

      this.currentUserBfi = currentUser.bfi;
    } else {
      this.currentUserBfi = this.userService.calculateBfi(this.userService.getTempBfi());
    }


    if (this.quotePosition > 0) {
      this.users.splice(this.quotePosition, 0, new User());

      let foundQuote = false;
      let possibleQuotes = [];

      if (!(this.catId === "undefined")) {
        for (let pQ of this.quote) {
          if (pQ.catId == this.catId) {
            possibleQuotes.push(pQ);
          }
        }
      }

      if (possibleQuotes.length > 0) {
        foundQuote = true;
      }


      while (!foundQuote) {
        for (let pQ of this.quote) {
          if (!pQ.catId) {
            possibleQuotes.push(pQ);
          }
        }

        if (this.quote.length > 0) {
          foundQuote = true;
        }
      }

      this.selectedQuoteIdx = this.genrateRandomNumber(0, possibleQuotes.length - 1);

    }

  }

  genrateRandomNumber(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getMatchingVal(bfi1, bfi2) {
    return Math.round(this.userService.compareTwoUserBfis(bfi1, bfi2) * 100);
  }

  getShortOffering(offering) {
    if (offering) {
      if (offering.length > 170) {
        return offering.substring(0, 170) + "...";
      } else {
        return offering.substring(0, 170);
      }
    } else {
      return "";
    }
  }

  redirectToMatchingFromUserProfile(profileId: string) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        source: "/berater/" + profileId
      }
    };

    this.nav.navigateRoot('/profile/bfi10', navigationExtras);
  }

  async redirectToProfile(profileId: string) {
    await this.router.navigateByUrl('/berater/' + profileId, { state: { userlist: this.users } });
  }

}
