import { Component, Input, OnInit } from '@angular/core';
import { te } from 'date-fns/locale';

@Component({
  selector: 'app-spartax-loading',
  templateUrl: './spartax-loading.component.html',
  styleUrls: ['./spartax-loading.component.scss'],
})
export class SpartaxLoadingComponent implements OnInit {

  @Input('loadingtext') loadingtext;

  constructor() { }

  ngOnInit() {}

}
