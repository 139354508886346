import { Company, CompanySubscription, User } from ".";

export class CompanyLicenseAssignment {
    companyLicenseAssignmentId: number;
    companySubscription: CompanySubscription;
    companySubscriptionId: number;
    user: User;
    userId: number;
    company: Company;
    companyId: number;
    active: boolean;
    createdAt?: Date;
    createdBy?: number;
    modifiedAt?: Date;
    modifiedBy?: number;
}
