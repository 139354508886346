import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByConfirmed'
})
export class FilterByConfirmedPipe implements PipeTransform {
  transform(items: any[], confirmed: boolean): any[] {
    if (!items) {
      return [];
    }

    return items.filter(item => item.confirmed === confirmed);
  }
}