export class PaymentMethod {
  paymentMethodId: number;
  companyId: number;
  selected: boolean;
  deleted: boolean;
  type: string;
  panpattern: string;
  expiry: string;
  tokenregstatus: string;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;
}
