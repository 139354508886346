import { createAnimation } from "@ionic/angular";

 const getIonPageElement = (element: HTMLElement) => {
    if (element.classList.contains('ion-page')) {
      return element;
    }
  
    const ionPage = element.querySelector(
      ':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs'
    );
    if (ionPage) {
      return ionPage;
    }
    // idk, return the original element so at least something animates
    // and we don't have a null pointer
    return element;
};

export function fadeInTransition (_: HTMLElement, opts) {      
    const DURATION = 500;
  
    // root animation with common setup for the whole transition
    const rootTransition = createAnimation()
      .duration(opts.duration || DURATION)
      .easing('cubic-bezier(0.3,0,0.66,1)');
        
    // ensure that the entering page is visible from the start of the transition
    const enteringPage = createAnimation()
      .addElement(getIonPageElement(opts.enteringEl))
      .delay(opts.duration || DURATION)
      .beforeRemoveClass('ion-page-invisible');
  
    // create animation for the leaving page
    const leavingPage = createAnimation().addElement(
      getIonPageElement(opts.leavingEl)
    );
  
    leavingPage.fromTo('opacity', '1', '0');
    enteringPage.fromTo('opacity', '0', '1');
   
    rootTransition.addAnimation(enteringPage);
    rootTransition.addAnimation(leavingPage);
    return rootTransition;
} 