import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, ScrollBaseDetail, ScrollDetail } from '@ionic/angular';


@Component({
  selector: 'app-footercomp',
  templateUrl: './footercomp.component.html',
  styleUrls: ['./footercomp.component.scss'],
})
export class FootercompComponent implements  OnInit  {

  constructor() { }
  isScrolling: boolean;


  ngOnInit() { }

}
