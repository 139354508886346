import { Pipe, PipeTransform } from "@angular/core";
import { User, UserRole } from "../data/entities";

@Pipe({name: 'beeexpertUser'})
export class BeeexpertUserPipe implements PipeTransform {
    transform(value: User, ...args: any[]) {
        if(value) {
            return value.displayName;
        } else {
            return '';
        }
    }
}

@Pipe({name: 'userRole'})
export class UserRolePipe implements PipeTransform {
    transform(value: User, ...args: any[]) {
        switch(value.role) {
            case UserRole.admin:
                return "Beeexpert Administrator";
            case UserRole.companyAdmin:
                return "Unternehmensadministrator";
            case UserRole.editor:
                return "Standardbenutzer";
            default:
                return "";
        }
    }
}