import { IUser } from '../interfaces/user.interface';
import { Company } from './company.entity';
import { UserBfi } from './user-bfi.entity';
import { UserReference } from './user-reference.entity';

export enum UserRole {
  admin = 'admin',
  companyAdmin = 'companyAdmin',
  editor = 'editor',
}

export class User implements IUser {
  public userId: number;
  public companyId?: number;
  public firstName?: string;
  public lastName?: string;
  public titlePrepended?: string;
  public titleAppended?: string;
  public userName: string;
  public displayName: string;
  public password: string;
  public role?: UserRole;
  public company?: Company;
  public offeringConsultantCategories?: any[];
  public isLocked?: boolean;
  public hasActiveLicense?: boolean;
  public hasActiveFormsLicense?: boolean;
  public image?: string;
  public bfi?: UserBfi;
  public phone?: string;
  public myCharacter?: string;
  public references?: string;
  public career?: string;
  public consultationOptions?: string;
  public fieldOfActivity?: string;
  public offering?: string;
  public freeInitialConsultation?: string;
  public xingUrl?: string;
  public facebookUrl?: string;
  public linkedInUrl?: string;
  public profileId?: string;
  public welcomeDashboardConfirmedAt?: Date;
  public userImage?: any;
  public interimManagement?: boolean;
  public yearsOfExperience?: string;
  public yearsOfConsultingExperience?: string;
  public languages?: string;
  public verified?: boolean;
  public lastSelectedFieldId: string;
  public lastSelectedDistance: number;
  public lastLongitude: number;
  public lastLatitude: number;
  public lastLocationName: string;
  public lastSelectedSubFieldId: string[];
  public confirmedUserReferences: UserReference[];
  public consultantReminder: boolean;
  public registrationCompletedAt: boolean;
  public invisible: boolean;


  static hasMinimumRole(usersRole: UserRole, requiredMinimumRole: UserRole) {
    if(requiredMinimumRole == UserRole.editor) {
      return true;
    } else if(requiredMinimumRole == UserRole.companyAdmin) {
      return usersRole == UserRole.admin || usersRole == UserRole.companyAdmin;
    } else if (requiredMinimumRole == UserRole.admin) {
      return usersRole == UserRole.admin;
    } else {
      return false;
    }
  }


}
