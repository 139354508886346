import { User } from ".";

export class Company {
  companyId: number;
  companyName: string;
  companyName2?: string;
  companyName3?: string;
  street: string;
  postcode: string;
  city: string;
  country: string;
  phone?: string;
  email?: string;
  homepage?: string;
  vatNumber?: string;
  description?: string;
  users?: User[];
  longitude?: number;
  latitude?: number;
}
