import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UploadProfileImageDto } from '../dto/upload-profile-image.dto';
import { Company } from '../entities';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private timeStampImageLastUpdated: Date = null;
  constructor(private http: HttpClient) {}

  createCompany(values: any): Observable<Company> {
    return this.http.post<Company>(`${environment.apiUrl}/companys`, values);
  }

  updateCompany(companyId: number, values: any): Observable<Company> {
    return this.http.patch<Company>(
      `${environment.apiUrl}/companys/${companyId}`,
      values
    );
  }

  getCompany(companyId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/companys/${companyId}`, {
      responseType: 'json',
    });
  }

  getCompanyImageUrl(companyId: number): string {
    let cacheBuster = '';
    if (this.timeStampImageLastUpdated !== null) {
      cacheBuster = '?' + this.timeStampImageLastUpdated.getTime() / 1000;
    }
    return `${environment.apiUrl}/companys/getImageByCompanyId/${companyId}${cacheBuster}`;
  }

  uploadCompanyImage(uploadedImage: UploadProfileImageDto) {
    this.timeStampImageLastUpdated = new Date();
    return this.http.post(`${environment.apiUrl}/companys/uploadCompanyImage/`, uploadedImage);
  }
}
