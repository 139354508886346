//WARNING - Types MUST be kept in Sync between the Spartax Azure Function Project and with the ones in Beeexpert manually

export type SpartaxUserGraphProperties = {
    UserPrincipalName: string;
    BusinessPhone?: string;
};

export type SpartaxUser = {
    UserPrincipalName: string;
    DisplayName: string;
    Email?: string;
    Id?: number;
    Groups?: number[];
    GraphProperties?: SpartaxUserGraphProperties;
}

export enum SpartaxUserRole {
    None = 'None',
    B2C = 'B2C',
    Terminverwalter = 'Terminverwalter',
    Partner = 'Partner',
    Sachbearbeiter = 'Sachbearbeiter',
    Admin = 'Admin'
}

export enum SpartaxSensitivityLevel {
    showAll = 'showAll',
    hidenSensitive = 'hidenSensitive',
    onlyIds = 'onlyIds',
}

export abstract class SpartaxUserUtil {
    public static getAnzeigenameWithEmail(user: SpartaxUser): string {
        return user.DisplayName + 
            (user.Email ? ` (${user.Email})` : '');
    }

    public static hasMinimumRole(minimumRole: SpartaxUserRole, usersHighestRole: SpartaxUserRole): boolean {
        const allEnumValues = Object.values(SpartaxUserRole);
        return allEnumValues.indexOf(usersHighestRole) >= allEnumValues.indexOf(minimumRole);
    }
}