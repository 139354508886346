import { SpartaxUser } from "./spartax-user.dto";

//WARNING - Types MUST be kept in Sync between the Spartax Azure Function Project and with the ones in Beeexpert manually
export enum SpartaxDocumentType {
    Vollmacht = 'Vollmacht',
    Bankgeheimnis = 'Bankgeheimnis',
    SEPA = 'SEPA',
    Datenschutzerklaerung = 'Datenschutzerklaerung',
    AGB = 'AGB',
    Honorarrichtlinie = 'Honorarrichtlinie',
    Belegerfassung = 'Belegerfassung',
    UnterlagenBereitstellen = 'UnterlagenBereitstellen',
    VorlageUnterlagenBereitstellenHeader1 = 'VorlageUnterlagenBereitstellenHeader1',
    VorlageUnterlagenBereitstellenHeader2 = 'VorlageUnterlagenBereitstellenHeader2',
    VorlageUnterlagenBereitstellenPosition = 'VorlageUnterlagenBereitstellenPosition',
    Belegurgenz = 'Belegurgenz',
    VorlageBelegurgenzHeader = 'VorlageBelegurgenzHeader',
    VorlageBelegurgenzPosition = 'VorlageBelegurgenzPosition',
    VorlageBelegurgenzFooter = 'VorlageBelegurgenzFooter',
    UnterlagenVollstaendig = 'UnterlagenVollstaendig',
    BegleitschreibenRechnung = 'BegleitschreibenRechnung',
    VorlageBegleitschreibenRechnungHeader = 'VorlageBegleitschreibenRechnungHeader',
    VorlageBegleitschreibenRechnungPosition = 'VorlageBegleitschreibenRechnungPosition',
    VorlageBegleitschreibenRechnungFooter = 'VorlageBegleitschreibenRechnungFooter',
    Behindertenpass = 'Behindertenpass',
    Parkausweis = 'Parkausweis',
    Pflegegeldbescheid = 'Pflegegeldbescheid',
    Sachverstaendigengutachten = 'Sachverstaendigengutachten',
    Unterhaltsvereinbarung = 'Unterhaltsvereinbarung',
    LztAnmeldebestaetigung = 'LztAnmeldebestaetigung',
    LztTerminerinnerung = 'LztTerminerinnerung',
    LztEinladungTerminverwalter = 'LztEinladungTerminverwalter',
    FamilienbeihilfeBestaetigungErh = 'FamilienbeihilfeBestaetigungErh',
};

export const SpartaxDocumentTypeName = new Map<string, string>([
    [SpartaxDocumentType.Vollmacht, 'Dauerauftrag und Vollmacht'],
    [SpartaxDocumentType.Bankgeheimnis, 'Erklärung über die Entbindung vom Bankgeheimnis'],
    [SpartaxDocumentType.SEPA,  'SEPA-Lastschriftmandat'],
    [SpartaxDocumentType.Datenschutzerklaerung, 'Datenschutzerklärung'],
    [SpartaxDocumentType.AGB, 'Allgemeine Geschäftsbedingungen'],
    [SpartaxDocumentType.Honorarrichtlinie, 'Honorarrichtlinie'],
    [SpartaxDocumentType.Belegerfassung, 'Belegerfassung'],
    [SpartaxDocumentType.UnterlagenBereitstellen, 'Unterlagen bereitstellen'],
    [SpartaxDocumentType.VorlageUnterlagenBereitstellenHeader1, 'Vorlage Unterlagen bereitstellen (Kopfbereich 1)'],
    [SpartaxDocumentType.VorlageUnterlagenBereitstellenHeader2, 'Vorlage Unterlagen bereitstellen (Kopfbereich 2)'],
    [SpartaxDocumentType.VorlageUnterlagenBereitstellenPosition, 'Vorlage Unterlagen bereitstellen (Position)'],
    [SpartaxDocumentType.Belegurgenz, 'Belegurgenz'],
    [SpartaxDocumentType.VorlageBelegurgenzHeader, 'Vorlage Belegurgenz (Kopfbereich)'],
    [SpartaxDocumentType.VorlageBelegurgenzPosition, 'Vorlage Belegurgenz (Position)'],
    [SpartaxDocumentType.VorlageBelegurgenzFooter, 'Vorlage Belegurgenz (Fußbereich)'],
    [SpartaxDocumentType.UnterlagenVollstaendig, 'Unterlagen vollständig'],
    [SpartaxDocumentType.BegleitschreibenRechnung, 'Begleitschreiben Rechnung'],
    [SpartaxDocumentType.VorlageBegleitschreibenRechnungHeader, 'Vorlage Begleitschreiben Rechnung (Kopfbereich)'],
    [SpartaxDocumentType.VorlageBegleitschreibenRechnungPosition, 'Vorlage Begleitschreiben Rechnung (Position)'],
    [SpartaxDocumentType.VorlageBegleitschreibenRechnungFooter, 'Vorlage Begleitschreiben Rechnung (Fußbereich)'],
    [SpartaxDocumentType.Behindertenpass, 'Behindertenpass'],
    [SpartaxDocumentType.Parkausweis, 'Parkausweis'],
    [SpartaxDocumentType.Pflegegeldbescheid, 'Pflegegeldbescheid'],
    [SpartaxDocumentType.Sachverstaendigengutachten, 'Sachverständigengutachten'],
    [SpartaxDocumentType.Unterhaltsvereinbarung, 'Unterhaltsvereinbarung / Scheidungsvergleich'],
    [SpartaxDocumentType.LztAnmeldebestaetigung, 'Anmeldebestätigung LZT Termin'],
    [SpartaxDocumentType.LztTerminerinnerung, 'Terminerinnerung LZT Termin'],
    [SpartaxDocumentType.LztEinladungTerminverwalter, 'Einladung Terminverwalter LZT Termin'],
    [SpartaxDocumentType.FamilienbeihilfeBestaetigungErh, 'Bestätigung erhöhte Familienbeihilfe'],
]);

export type SpartaxDocument = {
    Title: string;
    ServerRelativeUrl: string;
    AbsoluteUrl: string;
    LinkingUrl: string;
    TimeCreated: Date;
    TimeLastModified: Date;
    DocumentType?: SpartaxDocumentType;
    DocumentTypeName?: string;
    TemplateValidFrom?: Date;
    Editor?: SpartaxUser;
};

export enum SpartaxFileType {
    Word = 'Word',
    Excel = 'Excel',
    ExcelWithMacros = 'Excel',
}

export const SpartaxFileTypeExtension = new Map<string, string>([
    [SpartaxFileType.Word, 'docx'],
    [SpartaxFileType.Excel, 'xlsx'],
    [SpartaxFileType.ExcelWithMacros, 'xlsm'],
]);

export const SpartaxFileTypeProtocol = new Map<string, string>([
    [SpartaxFileType.Word, 'word:ofv|u|'],
    [SpartaxFileType.Excel, 'ms-excel:ofv|u|'],
    [SpartaxFileType.ExcelWithMacros, 'ms-excel:ofe|u|'],
]);

export const SpartaxDocumentTypeFileType = new Map<string, SpartaxFileType>([
    [SpartaxDocumentType.Vollmacht, SpartaxFileType.Word],
    [SpartaxDocumentType.Bankgeheimnis, SpartaxFileType.Word],
    [SpartaxDocumentType.SEPA, SpartaxFileType.Word],
    [SpartaxDocumentType.Datenschutzerklaerung, SpartaxFileType.Word],
    [SpartaxDocumentType.AGB, SpartaxFileType.Word],
    [SpartaxDocumentType.Honorarrichtlinie, SpartaxFileType.Word],
    [SpartaxDocumentType.Belegerfassung, SpartaxFileType.ExcelWithMacros],
    [SpartaxDocumentType.VorlageUnterlagenBereitstellenHeader1, SpartaxFileType.Word],
    [SpartaxDocumentType.VorlageUnterlagenBereitstellenHeader2, SpartaxFileType.Word],
    [SpartaxDocumentType.VorlageUnterlagenBereitstellenPosition, SpartaxFileType.Word],
    [SpartaxDocumentType.VorlageBelegurgenzHeader, SpartaxFileType.Word],
    [SpartaxDocumentType.VorlageBelegurgenzPosition, SpartaxFileType.Word],
    [SpartaxDocumentType.VorlageBelegurgenzFooter, SpartaxFileType.Word],
    [SpartaxDocumentType.UnterlagenBereitstellen, SpartaxFileType.Word],
    [SpartaxDocumentType.VorlageBegleitschreibenRechnungHeader, SpartaxFileType.Word],
    [SpartaxDocumentType.VorlageBegleitschreibenRechnungPosition, SpartaxFileType.Word],
    [SpartaxDocumentType.VorlageBegleitschreibenRechnungFooter, SpartaxFileType.Word],
    [SpartaxDocumentType.Belegurgenz, SpartaxFileType.Word],
    [SpartaxDocumentType.UnterlagenVollstaendig, SpartaxFileType.Word],
    [SpartaxDocumentType.BegleitschreibenRechnung, SpartaxFileType.Word],
]);
