import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { SearchDto } from '../dto';
import { UserService } from './user.service';
import arrayShuffle from 'array-shuffle';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private http: HttpClient,
    private readonly authService: AuthService,
    private readonly userService: UserService
  ) { }

  async searchByTerm(searchTerm: string, onlyFollowed = false, calculateDistance = false, maximumDistance?: number): Promise<any> {
    let searchResult: any[] = [];

    const searchDto: SearchDto = {
      searchTerm: searchTerm
    };

    if (this.authService.isLoggedIn()) {
      searchResult = await this.http.post<any[]>(`${environment.apiUrl}/search/l/`, searchDto).toPromise() || [];
      if (onlyFollowed) {
        searchResult = await this.filterOnMyFollowedUsers(searchResult);
      }
    } else {
      searchResult = await this.http.post<any[]>(`${environment.apiUrl}/search/`, searchDto).toPromise() || [];
    }

    if (calculateDistance) {
      try {
        await this.calculateDistance(searchResult);
      } catch (error) {
        console.log(error);
      }
    }

    if (maximumDistance) {
      searchResult = searchResult.filter(x => x._source.distance <= maximumDistance);
    }

    return await this.sortSearchResultByBfi(searchResult);
  }

  async searchAll(onlyFollowed = false, calculateDistance = false, maximumDistance?: number, address = {}, coords = {}): Promise<any> {
    let searchResult: any[] = await this.http.post<any[]>(`${environment.apiUrl}/search/searchAll`, null).toPromise() || [];

    if (this.authService.isLoggedIn() && onlyFollowed) {
      searchResult = await this.filterOnMyFollowedUsers(searchResult);
    }

    if (calculateDistance) {
      try {
        await this.calculateDistance(searchResult, address, coords);
      } catch (error) {
        console.log(error);
      }
    }

    if (maximumDistance) {
      searchResult = searchResult.filter(x => x._source.distance <= maximumDistance);
    }

    return await this.sortSearchResultByBfi(searchResult);
  }

  async searchByMainConsultantCategories(mainConsultantCategoryIds: number[], subcategoryids: string[] = [], searchTerm: string,
    onlyFollowed = false, calculateDistance = false, maximumDistance?: number, address = {}, coords = {}): Promise<any> {

    let subcategoryidsConverted;
    // convert the subcategoryids to numbers
    if (Array.isArray(subcategoryids)) {
      subcategoryidsConverted = subcategoryids.map(x => parseInt(x));
    } else {
      let subcategoryidsArray = Array.isArray(subcategoryids) ? subcategoryids : [subcategoryids];
      subcategoryidsConverted = subcategoryidsArray.map(x => parseInt(x));
    }

    const searchDto: SearchDto = {
      mainConsultantCategoryIds: mainConsultantCategoryIds,
      searchTerm: searchTerm
    };

    let searchResult: any[] = await this.http.post<any[]>(`${environment.apiUrl}/search/searchByMainCategory/`, searchDto).toPromise() || [];

    if (calculateDistance) {
      try {
        await this.calculateDistance(searchResult, address, coords);
      } catch (error) {
        console.log(error);
      }
    }

    if (maximumDistance) {
      searchResult = searchResult.filter(x => x._source.distance <= maximumDistance);
    }


    if (this.authService.isLoggedIn() && onlyFollowed) {
      searchResult = await this.filterOnMyFollowedUsers(searchResult);
    }

    searchResult = await this.sortSearchResultByBfi(searchResult);

    searchResult = await this.sortSearchResultByReferences(searchResult);

    searchResult = await this.sortSearchResultByVerification(searchResult);



    if (subcategoryids.length > 0) {
      searchResult = await this.filterAndSortOnSubCategories(searchResult, subcategoryidsConverted);
    }

    return searchResult;
  }

  private async sortSearchResultByVerification(searchResult: any[]): Promise<any[]> {
    if (Array.isArray(searchResult) && searchResult.length > 0) {
      searchResult.sort((a, b) => {
        if (a._source.verified && !b._source.verified) return -1;
        if (!a._source.verified && b._source.verified) return 1;
        return 0;
      });
    } else {
      console.error("searchResult is not a valid array for sorting by verification");
    }
    return searchResult;
  }

  private async sortSearchResultByReferences(searchResult: any[]): Promise<any[]> {
    if (Array.isArray(searchResult) && searchResult.length > 0) {
      searchResult.sort((a, b) => b._source.confirmedUserReferences?.length - a._source.confirmedUserReferences?.length);
    } else {
      console.error("searchResult is not a valid array for sorting by references");
    }
    return searchResult;
  }

  private async sortSearchResultByBfi(searchResult: any[]): Promise<any[]> {
    let currentUserBfi;

    if (this.authService.isLoggedIn()) {
      currentUserBfi = await this.userService.getUserBfi(this.authService.currentUserValue.userId).toPromise();
    }

    if (!currentUserBfi && this.userService.getTempBfi()) {
      currentUserBfi = this.userService.calculateBfi(this.userService.getTempBfi());
    }

    if (currentUserBfi) {
      for (let result of searchResult) {
        result.matchingVal = this.userService.compareTwoUserBfis(currentUserBfi, result._source.bfi);
      }
      searchResult.sort((a, b) => b.matchingVal - a.matchingVal);
    } else {
      searchResult = arrayShuffle(searchResult);
    }

    return searchResult;
  }

  private async filterOnMyFollowedUsers(searchResult: any[]): Promise<any[]> {
    let myFollowedUsers = await this.userService.getMyFollowedUsers().toPromise();
    return searchResult.filter(x => myFollowedUsers.includes(x._id));
  }

  private async filterAndSortOnSubCategories(searchResult: any[], subcategoryids: number[]): Promise<any[]> {
    let categorizedResults: { [key: number]: any[] } = {};

    // Initialize the categorizedResults object with the subcategory ids
    subcategoryids.forEach(id => {
      categorizedResults[id] = [];
    });

    // Categorize results based on subcategory ids
    for (let result of searchResult) {
      let addedToCategory = false;
      for (let subcategoryId of subcategoryids) {
        if (result._source.offeringConsultantCategories.some(category => category.categoryId === subcategoryId)) {
          categorizedResults[subcategoryId].push(result);
          addedToCategory = true;
          break;
        }
      }
      if (!addedToCategory) {
        if (!categorizedResults['others']) {
          categorizedResults['others'] = [];
        }
        categorizedResults['others'].push(result);
      }
    }

    // Flatten the categorized results maintaining the desired order
    let sortedResults = [];
    for (let subcategoryId of subcategoryids) {
      if (categorizedResults[subcategoryId]) {
        sortedResults = sortedResults.concat(categorizedResults[subcategoryId]);
      }
    }
    if (categorizedResults['others']) {
      sortedResults = sortedResults.concat(categorizedResults['others']);
    }

    return sortedResults;
  }

  async getAdressForGeoCode(coords: any): Promise<any> {
    return await this.http.post(`${environment.apiUrl}/search/getAddressForGeoCode`, coords, { responseType: 'text' }).toPromise();
  }

  async getGeoCodeForAdress(address: string): Promise<any> {
    return await this.http.post(`${environment.apiUrl}/search/getGeoCodeForAddress`, { address: address }, { responseType: 'json' }).toPromise();
  }

  private async calculateDistance(searchResult: any[], address = {}, coords = {}): Promise<void> {
    let userCoords;

    if ('address' in address) {
      userCoords = await this.http.post<any>(`${environment.apiUrl}/search/getGeoCodeForAddress`, { address: address }).toPromise();
    } else {
      userCoords = coords;
    }

    for (let result of searchResult) {
      result._source.distance = this.calculateDistanceBetweenTwoPoints(userCoords.latitude, userCoords.longitude, result._source.companyLatitude, result._source.companyLongitude);
    }
  }

  async getCurrentLocation(): Promise<any> {
    return await new Promise<GeolocationCoordinates>((resolve, reject) =>
      navigator.geolocation.getCurrentPosition((position) => {
        resolve(position.coords);
      }, reject));
  }

  public calculateDistanceBetweenTwoPoints(lat1, lon1, lat2, lon2) {
    if (lat1 && lon1 && lat2 && lon2) {
      const deg2rad = (deg) => {
        return deg * (Math.PI / 180);
      }

      const R = 6371; // Radius of the earth in km
      const dLat = deg2rad(lat2 - lat1);
      const dLon = deg2rad(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d;
    } else {
      return null;
    }
  }

  async getAutocompleteResults(input: string): Promise<any> {
    return await this.http.post<any>(`${environment.apiUrl}/search/getAdressAutocompleteResults?input=${input}`, null).toPromise();
  }
}
