import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ConsultantCategoryService {
  constructor(private http: HttpClient) {}
  getCategories(approved = true): Observable<any> {
    return this.http.get(`${environment.apiUrl}/consultant-categories?approved=${approved}`, {
      responseType: 'json',
    });
  }
  getCategoriesById(id: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/consultant-categories/${+id}`, {
      responseType: 'json',
    });
  }
  getMainCategories(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/main-consultant-categories`, {
      responseType: 'json',
    });
  }
  getMainCategoriesById(id: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/main-consultant-categories/${+id}`, {
      responseType: 'json',
    });
  }
  getSearchResults(mainCategoryId: number, query: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/consultant-categories/search/${+mainCategoryId}/${query}`, {
      responseType: 'json',
    });
  }

 getSearchPredictions(mainCategoryId: number, query: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/consultant-categories/searchprediction/${+mainCategoryId}/${query}`, {
      responseType: 'json',
    });
  }
    
  createCategory(values: any): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/consultant-categories/`,
      values
    );
  }

  getMainCategoryAndEnglishNameForNewCategory(query: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/consultant-categories/mainCategoryAndEnglishNameForNewCategory/${query}`, {
      responseType: 'json',
    });
  }
  

  updateCategory(categoryId: number, values: any): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/consultant-categories/${categoryId}`,
      values
    );
  }

  findSimilarCategories(query: string){
    return this.http.get(`${environment.apiUrl}/consultant-categories/similarcategories/${query}`, {
      responseType: 'json',
    });
  }
}
