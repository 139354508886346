import { EventEmitter, Injectable } from '@angular/core';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FormEventsService {
  private submitSubscriber: Function;
  private copyFilloutSubscriber: Function;

  subscribeToSubmit(callback: Function) {
    this.submitSubscriber = callback;
  }

  subscribeToCopyFillout(callback: Function) {
    this.copyFilloutSubscriber = callback;
  }
  
  triggerSubmitEvent(setIsWorking: boolean = true) {
    if(this.submitSubscriber) {
      this.submitSubscriber(setIsWorking);
    }
  }

  triggerCopyFilloutEvent(queryParams: Params) {
    if(this.copyFilloutSubscriber) {
      this.copyFilloutSubscriber(queryParams);
    }
  }
}
