import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  UserSignupDto,
  UserLoginDto,
  UserVerifyEmailDto,
  UserPasswordForgottenDto,
  UserRecoverPasswordDto,
  UserChangePasswordDto,
} from '../dto';
import { environment } from '../../../environments/environment';
import { IUser } from '../interfaces/user.interface';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAuthUser } from '../interfaces/auth-user.interface';
import decode from 'jwt-decode';
import { TokenPayload, User } from '../entities';
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUserTokenPayload: Observable<TokenPayload>;
  private currentUserObj: User;
  private currentUserSubject: BehaviorSubject<TokenPayload>;

  constructor(private http: HttpClient, private userService: UserService) {
    this.currentUserSubject = new BehaviorSubject<TokenPayload>(
      JSON.parse(localStorage.getItem('token_meta'))
    );
    this.currentUserTokenPayload = this.currentUserSubject.asObservable();
  }

  async signupUser(userSignupDto: UserSignupDto): Promise<IUser> {
    var res = this.http
      .post<IUser>(`${environment.apiUrl}/auth/signup`, userSignupDto)
      .toPromise();

    return res;
  }

  async verifyEmail(userVerifyEmailDto: UserVerifyEmailDto): Promise<IUser> {
    return this.http
      .post<IUser>(`${environment.apiUrl}/auth/verifyEmail`, userVerifyEmailDto)
      .toPromise();
  }

  async passwordForgotten(userPasswordForgottenDto: UserPasswordForgottenDto) {
    return this.http
      .post(
        `${environment.apiUrl}/auth/passwordForgotten`,
        userPasswordForgottenDto
      )
      .toPromise();
  }

  async recoverPassword(userRecoverPasswordDto: UserRecoverPasswordDto) {
    return this.http
      .post(
        `${environment.apiUrl}/auth/recoverPassword`,
        userRecoverPasswordDto
      )
      .toPromise();
  }

  async changePassword(userChangePasswordDto: UserChangePasswordDto) {
    return this.http
      .post(`${environment.apiUrl}/auth/changePassword`, userChangePasswordDto)
      .toPromise();
  }

  async login(userLoginDto: UserLoginDto) {
    const response = await this.http.post<IAuthUser>(`${environment.apiUrl}/auth/login`, userLoginDto).toPromise();
    this.setSession(response);
    this.currentUserObj = await this.userService.getUser(this.currentUserValue.userId).toPromise();
    return response;
  }

  logout() {
    this.currentUserSubject.next(null);
    this.currentUserObj = null;
    localStorage.removeItem('access_token');
    localStorage.removeItem('token_meta');
    localStorage.removeItem('expires_at');
  }

  async logoutFromAllDevices() {
    await this.http.post(`${environment.apiUrl}/auth/logoutFromAllDevices`, null).toPromise();
    this.logout();
  }

  async pingBackend() {
    this.http.get(`${environment.apiUrl}/app/ping`).toPromise();
  }

  public getAccessToken() {
    return localStorage.getItem('access_token');
  }

  public isLoggedIn() {
    const result =
      this.getAccessToken() !== '' && moment().isBefore(this.getExpiration());
    if(!result) {
      this.logout();
    }
    return result;
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  public get currentUserValue(): TokenPayload {
    return this.currentUserSubject.value;
  }

  public async getCurrentUserCached() {
    if(!this.currentUserObj) {
      this.currentUserObj = await this.userService.getUser(this.currentUserValue.userId).toPromise();
    }

    return this.currentUserObj;
  }

  private setSession(authResult: any) {
    const tokenDecoded: TokenPayload = decode(authResult.access_token);
    const expiresAt = moment(tokenDecoded.exp * 1000);
    this.storeAccessToken(authResult.access_token);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
    localStorage.setItem('token_meta', JSON.stringify(tokenDecoded));
    this.currentUserSubject.next(tokenDecoded);
  }

  private storeAccessToken(jwt: string) {
    localStorage.setItem('access_token', jwt);
  }
}
