import { Component, OnInit, ViewChild } from '@angular/core';
import { IonDatetime, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/fp';

@Component({
  selector: 'app-select-timeperiod',
  templateUrl: './select-timeperiod.component.html',
  styleUrls: ['./select-timeperiod.component.scss'],
})
export class SelectTimeperiodComponent  {
  date = new Date();
  startDate = new Date(this.date.getFullYear(), this.date.getMonth(), 11);
  endDate = new Date(this.date.getFullYear(), this.date.getMonth(), 17);

  constructor(private navParams: NavParams, private popoverController: PopoverController) {
  }

  saveAvailability() {
    // Hier kannst du den Code hinzufügen, um die ausgewählten Werte zu speichern
    // z.B. sende die Werte an eine API, speichere sie lokal, etc.

    // Schließe das Popup
    this.popoverController.dismiss();
  }
}