import { LOCALE_ID, NgModule, } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedComponentsModule } from './components/shared-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { JwtModule } from '@auth0/angular-jwt';
import { fadeInTransition } from './utils/fade-animation.transition';

import localeDE from '@angular/common/locales/de';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { BeeexpertUserPipe } from './utils/custom-pipes-pipe';
import { FilterByConfirmedPipe } from './utils/filter-by-confirmed.pipe';
import { UnauthorizedInterceptor } from './data/interceptors/UnauthorizedInterceptor';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FilterModule } from './components/filter-component/filter.module';
import { DragDropModule } from '@angular/cdk/drag-drop';


registerLocaleData(localeDE);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    SharedComponentsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    DragDropModule,
    ReactiveFormsModule,    FormsModule,
    IonicModule.forRoot({ navAnimation: fadeInTransition}),
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
    AppRoutingModule,
    FontAwesomeModule,
    CommonModule,
    FilterModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('access_token'),
        allowedDomains: `${environment.JWTAllowedDomains}`.split(','),
        disallowedRoutes: ['/auth/login'],
      },
    }),
    ScullyLibModule,
    ],
  providers: [
    { 
      provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy 
    },
    {
      provide: LOCALE_ID,
      useValue: 'de'
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    BeeexpertUserPipe,
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: UnauthorizedInterceptor, 
      multi: true 
    },
    FilterByConfirmedPipe,
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: UnauthorizedInterceptor, 
      multi: true 
    }

  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}
