import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-project-popover',
  templateUrl: './project-popover.component.html',
  styleUrls: ['./project-popover.component.scss'],
})
export class ProjectPopoverComponent implements OnInit {

  @Input() projectTitle: string;
  @Input() projectDescription: string;
  @Input() referenceCompanyName: string;
  @Input() referenceCompanyContactName: string;
  @Input() reviewerNote: string;

  constructor(private modalController: ModalController) { }

  ngOnInit() {
    if (this.reviewerNote.length == 0) {
      document.getElementById("reviewerNote").style.display = "none";
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
