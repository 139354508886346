import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass, plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CompanySubscription } from '../entities';
import { sl } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class CompanySubscriptionService {

  constructor(private http: HttpClient) { }

  addSubscription(subscriptionCode: string, numberOfLicenses: number, companyId: number): Observable<any> {
    const values = {
      subscriptionCode,
      numberOfLicenses,
      companyId
    };
    return this.http.post(
      `${environment.apiUrl}/company-subscription/addSubscription`,
      values
    );
  }

  addPremiumTrialSubscription(companyId: number): Observable<any> {
    const values = {
      companyId
    };
    return this.http.post(
      `${environment.apiUrl}/company-subscription/addPremiumTrialSubscription`,
      values
    );
  }

  getMyCompaniesSubscriptions(): Observable<any> {

    return this.http.get(
      `${environment.apiUrl}/company-subscription/getMyCompaniesSubscriptions`,
      {
        responseType: 'json',
      }
    ).pipe(
      map(subscription => plainToInstance(CompanySubscription, subscription))
    );

  }

  getCompaniesSubscriptionsById(companyid): Observable<any> {
    let res = this.http.get(
      `${environment.apiUrl}/company-subscription/getCompaniesSubscriptions/` + companyid,
      {
        responseType: 'json',
      }
    ).pipe(
      map(subscription => plainToInstance(CompanySubscription, subscription))
    );

    return res;
  }

  cancelSubscription(subscriptionid: number): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/company-subscription/cancelSubscription/${subscriptionid}`, null
    );
  }

  unassignLicense(userId: number): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}/company-subscription/unassignLicense/${userId}`, null
    );
  }

  getAvailablePaymentMethods(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/company-subscription/getAvailablePaymentMethods`,
      {
        responseType: 'json',
      }
    );
  }

  assignLicense(companySubscriptionId: number, userId: number, companyId: number) {
    const values = {
      companySubscriptionId,
      userId,
      companyId
    };

    return this.http.post(
      `${environment.apiUrl}/company-subscription/assignLicense`,
      values
    );
  }

  validateVoucher(subscriptionType: string, numberOfLicenses: number, voucherCode: string): Observable<any> {
    const values = {
      subscriptionType: subscriptionType,
      numberOfLicenses: +numberOfLicenses,
      voucherCode: voucherCode
    };

    return this.http.post(
      `${environment.apiUrl}/company-subscription/validateVoucher`,
      values
    );
  }

  getAvailableSubscriptions(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/company-subscription/getavailablesubscriptions`,
      {
        responseType: 'json',
      }
    );
  }

  getVoucher(voucherCode: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/company-subscription/getVoucherByCode/${voucherCode}`,
      {
        responseType: 'json',
      }
    );
  }


  getFormForPaymentRedirection(subscriptionCode: string, numberOfLicenses: number, subscriptionStart: Date, selectedSubscriptionId, voucherCode?: string): Observable<any> {


    const values = {
      subscriptionType: subscriptionCode,
      numberOfLicenses: +numberOfLicenses,
      voucherCode: voucherCode,
      subscriptionStart: subscriptionStart,
      selectedSubscriptionIdToCancel: selectedSubscriptionId
    };


    return this.http.post(
      `${environment.apiUrl}/company-subscription/getFormForPaymentRedirection`,
      values
    );
  }

  getFormForAddingNewPaymentMethodRedirection(): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/company-subscription/getFormForAddingNewPaymentMethodRedirection`,
      {}
    );
  }

  parseGPResult(gpResult: any): Observable<any> {
    const values = gpResult;
    return this.http.post(
      `${environment.apiUrl}/company-subscription/parseGPResult`,
      values
    );
  }
}
