import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { User, UserRole } from 'src/app/data/entities';
import { RoleGuard } from 'src/app/data/guards/role.guard';
import { AuthService } from 'src/app/data/service/auth.service';
import { UserService } from 'src/app/data/service/user.service';

@Component({
  selector: 'app-warnings',
  templateUrl: './warnings.component.html',
  styleUrls: ['./warnings.component.scss'],
})
export class WarningsComponent implements OnInit {
  @Input() ownProfile: string;
  @Input() position: string;

  user: User;
  loggedInUserIsViewingItsOwnProfile = false;
  showdetails: boolean;
  showShoppingThankYou = false;
  userThumbnailAvailable = true;
  showMatchingHint: boolean;
  tempMatching: boolean;
  loadedFinally = false;


  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    public nav: NavController,
    private router: Router,

  ) {
  }

  async ngOnInit() {

    if (this.authService.isLoggedIn()) {
      this.user = await this.userService
        .getUser(this.authService.currentUserValue?.userId)
        .toPromise();
    }

    if ((/true/i).test(this.ownProfile)) {
      this.loggedInUserIsViewingItsOwnProfile = true;
    }


    if ((this.user?.bfi && (this.user.lastName?.length > 0 && this.user.offeringConsultantCategories?.length > 0) && this.user.company)) {
      this.showdetails = false;
    } else {
      this.showdetails = true;
    }
    if (this.position == 'start' || this.position == 'search') {
      this.showdetails = false;
    }

    this.route.queryParams.subscribe(params => {
      if (params.source === "payment") {
        this.showShoppingThankYou = true;
      }
      if(params.source === 'bfi10' && (this.position == 'start' || this.position == 'search')){
        this.showMatchingHint = true;
        if(this.userService.getTempBfi && !this.authService.isLoggedIn()){
          this.tempMatching = true;
        }else{
          this.tempMatching = false;
        }
      }
    });

    let userThumbnail = this.userService.getUserImageUrl(this.user?.userId);
    let defThumbnail = '<?xml version="1.0" encoding="UTF-8"?>';

    let data = ''
    //await fetch(userThumbnail).then((response) => response.text()).then((text) => data = text);

    try {
      if (data.includes(defThumbnail)) {
      //  this.userThumbnailAvailable = false;
      } else {
        this.userThumbnailAvailable = true;
      }
    } catch (error) {

    }


    this.loadedFinally = true;

  }

  redirectToMatchingFromUserProfile() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        source: this.router.url
      }
    };

    this.nav.navigateRoot('/profile/bfi10', navigationExtras);
  }


  redirectToMatchingFromWizard() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        source: "profile"
      }
    };

    this.nav.navigateRoot('/profile/bfi10', navigationExtras);
  }

  profilePublished(): boolean {
    return this.user && this.user.lastName?.length > 0 && this.user.hasActiveLicense && this.user.bfi &&
      this.user.offeringConsultantCategories?.length > 0 && !!this.user.company;
  }

  get bfiMissing() {
    if (this.user != null) {
      return this.user.bfi == null && !this.user.hasActiveLicense;
    } else if (this.userService.getTempBfi()) {
      return false;
    } else {
      return true;
    }
  }

  get hasActiveLicense() {
    if (this.user != null) {
      return this.user.hasActiveLicense;
    } else {
      return false;
    }
  }

  get isCompanyAdmin() {
    if (this.user.role == UserRole.companyAdmin) {
      return true;
    } else {
      return false;
    }
  }


}
