import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import decode from 'jwt-decode';
import { TokenPayload } from '../entities/token-payload.entity';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree 
  {
    let expectedRoles: string[] = [];
    
    if(route.data.expectedRole) {
      expectedRoles.push(route.data.expectedRole);
    } else if (route.data.expectedRoles) {
      expectedRoles = route.data.expectedRoles as string[];
    }
    
    const token = this.auth.getAccessToken();
    if (token === '' || token === null) {
      this.router.navigate(['login']);
      return false;
    }
    const tokenPayload: TokenPayload = decode(token);

    if(this.auth.isLoggedIn()) {
      for(let role of expectedRoles) {
        if(tokenPayload.role === role) {
          return true;
        }
      }
    }

    this.router.navigate(['login']);
    return false;
  }
}
