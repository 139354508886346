import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { User, UserRole } from 'src/app/data/entities';
import { AuthService } from 'src/app/data/service/auth.service';
import { UserService } from 'src/app/data/service/user.service';

@Component({
  selector: 'app-edit-profile-popover',
  templateUrl: './edit-profile-popover.component.html',
  styleUrls: ['./edit-profile-popover.component.scss'],
})
export class EditProfilePopoverComponent {
  user: User;
  
  constructor(
    private popoverController: PopoverController,
    private authService: AuthService,
    private userService: UserService,
  ) { }

  async dismiss() {
    await this.popoverController.dismiss();
  }
  
  async ionViewWillEnter() {
    this.user = await this.userService
        .getUser(this.authService.currentUserValue?.userId)
        .toPromise();
  }

  get userIsAdmin() {
    return this.user && (
      this.user.role == UserRole.companyAdmin || this.user.role == UserRole.admin
    );
  }

  async logoutFromAllDevices() {
    await this.authService.logoutFromAllDevices();
    await this.dismiss();
  }
}
