import { User } from "./user.entity";

export enum UserBfiType {
    bfi10 = 'bfi10',
}

export class UserBfi {
  userBfiId: number;
  user: User;
  userId: number;
  type: UserBfiType;
  createdAt: Date;
  createdBy: number;
  modifiedAt?: Date;
  modifiedBy?: number;

  /* Extraversion */
  bfiE: number;

  /* Neurotizismus */
  bfiN: number;

  /* Offenheit */
  bfiO: number;

  /* Gewissenhaftigkeit */
  bfiG: number;

  /* Verträglichkeit */
  bfiV: number;

  /* BFI for Offenheit always 5 if searching */
  bfiOToMaxIfSearching?: boolean;
}
