import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SpartaxAuftragskunde, SpartaxAuftragskundeArt } from 'src/app/data/dto/spartax/spartax-auftragskunde.dto';
import { SpartaxFormModelDto } from 'src/app/data/dto/spartax/spartax-form-model.dto';
import { SpartaxIdentityComponent } from '../spartax-identity/spartax-identity.component';
import { SpartaxKunde, SpartaxKundeUtil } from 'src/app/data/dto/spartax/spartax-kunde.dto';
import { SpartaxService } from 'src/app/data/service/spartax.service';
import * as moment from 'moment';
import { SpartaxDocumentType, SpartaxDocumentTypeName } from 'src/app/data/dto/spartax/spartax-document.dto';
import { AlertController, ModalController } from '@ionic/angular';
import { FormEventsService } from 'src/app/data/service/form-events.service';
import { from } from 'linq-to-typescript';
import { SpartaxLoadingComponent } from 'src/app/components/spartax-loading/spartax-loading.component';

@Component({
  selector: 'app-spartax-place-order',
  templateUrl: './spartax-place-order.component.html',
  styleUrls: ['./spartax-place-order.component.scss'],
})
export class SpartaxPlaceOrderComponent implements OnInit {
  @Input()
  model: SpartaxFormModelDto;

  @Output()
  modelChange = new EventEmitter<SpartaxFormModelDto>();

  @Input()
  edit: boolean;

  @Input()
  debug: boolean;

  @ViewChild('formElement')
  ngForm!: NgForm;

  @ViewChildren('spartaxIdentity')
  spartaxIdentityForms!: QueryList<SpartaxIdentityComponent>;

  @Input()
  spartaxPortalKunden: Map<string, SpartaxKunde>;

  working = false;
  workingStatus: string;
  error = false;
  private popover: HTMLIonModalElement;

  constructor(
    private spartaxService: SpartaxService,
    private alertController: AlertController,
    private formEventService: FormEventsService,
    private modalController: ModalController
  ) { }

  async ngOnInit() { }

  anyPersonHasIncome() {
    for (let auftragskunde of this.model.auftragskunden) {
      if (this.personHasIncome(auftragskunde)) {
        return true;
      }
    }

    return false;
  }

  personHasIncome(auftragskunde: SpartaxAuftragskunde) {
    for (let jahr of auftragskunde.Erklaerungsjahre) {
      if (jahr.EinkunftsArten.length > 0) {
        return true;
      }
    }

    return false;
  }

  formIsValid() {
    return this.ngForm?.valid && from(this.spartaxIdentityForms?.toArray()).all(x => x?.formIsValid());
  }

  isHauptkunde(auftragskunde) {
    if (auftragskunde.AuftragsKundeArt == SpartaxAuftragskundeArt.hauptkunde) {
      return true;
    }
    return false;
  }

  markAllControlsAsTouched() {
    for (const control in this.ngForm.controls) {
      this.ngForm.controls[control].markAsTouched();
    }

    for (const identityForm of this.spartaxIdentityForms?.toArray()) {
      identityForm?.markAllControlsAsTouched();
    }
  }

  isDocumentAvailable(auftragskunde: SpartaxAuftragskunde, documentType: SpartaxDocumentType) {
    const portalKunde = this.spartaxPortalKunden.get(auftragskunde.Kunde.FormModelId);

    if (portalKunde) {
      return portalKunde.Dokumente?.find((d) => d.DocumentType == documentType) != null;
    } else {
      return false;
    }
  }

  isDocumentExpired(auftragskunde: SpartaxAuftragskunde, documentType: SpartaxDocumentType) {
    const portalKunde = this.spartaxPortalKunden.get(auftragskunde.Kunde.FormModelId);

    if (portalKunde) {
      const document = portalKunde.Dokumente?.find((d) => d.DocumentType == documentType);
      if (document) {
        return moment(document.TimeLastModified).isBefore(document.TemplateValidFrom);
      }
    }

    return false;
  }

  async createDocument(auftragskunde: SpartaxAuftragskunde, documentType: SpartaxDocumentType) {
    this.markAllControlsAsTouched();
    const formOfKunde = this.spartaxIdentityForms.toArray().find((f) => f.kunde.FormModelId == auftragskunde.Kunde.FormModelId);

    if (!formOfKunde.formIsValid()) {
      const identityControlOfKunde = document.querySelector('#identity-' + auftragskunde.Kunde.FormModelId);
      if (identityControlOfKunde) {
        identityControlOfKunde.querySelector('ion-input.ng-invalid').scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    } else {
      this.error = false;

      if (!this.spartaxPortalKunden.get(auftragskunde.Kunde.FormModelId) && !await this.showCreateKundePrompt()) {
        await this.dismissPopOver();
        return;
      }
      
      try {
        await this.createOrUpdateKunde(auftragskunde);
      } catch (error) {
        console.error(error);
        this.error = true;
        this.workingStatus = 'Fehler beim Speichern des Kunden  (' + moment().format('HH:mm:ss') + ')';
        await this.reloadPopOver();
        return;
      }

      this.formEventService.triggerSubmitEvent();
      this.working = true;
      this.workingStatus = 'Dokument wird erstellt...';
      await this.reloadPopOver();

      try {
        const fileName = `${SpartaxDocumentTypeName.get(documentType)} ${SpartaxKundeUtil.getVollstaendigerNameOfKunde(auftragskunde.Kunde)}.pdf`;
        const fileBlob = await this.spartaxService.getCustomerDocument(auftragskunde.Kunde.FormModelId, documentType);
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(fileBlob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.working = false;
        this.workingStatus = '';
        await this.dismissPopOver();
      } catch (error) {
        console.error(error);
        this.error = true;
        this.workingStatus = 'Fehler beim Erstellen des Dokuments  (' + moment().format('HH:mm:ss') + ')';
        await this.reloadPopOver();
      }
    }
  }

  private async createOrUpdateKunde(auftragskunde: SpartaxAuftragskunde) {
    this.working = true;
    this.workingStatus = 'Kunde wird im Spartax Portal gespeichert...';
    await this.reloadPopOver();

    try {
      const editor = { DisplayName: this.model.editor, UserPrincipalName: this.model.editor };
      if (auftragskunde.Kunde.ListItemId) {
        auftragskunde.Kunde.Author = editor;
      }
      auftragskunde.Kunde.Editor = editor;
      const createdKunde = await this.spartaxService.createOrUpdateCustomer(auftragskunde.Kunde);
      this.model.auftragskunden.find((ak) => ak.Kunde.FormModelId == auftragskunde.Kunde.FormModelId).Kunde.ListItemId = createdKunde.ListItemId;
      this.spartaxPortalKunden.set(auftragskunde.Kunde.FormModelId, await this.spartaxService.getCustomer(auftragskunde.Kunde.FormModelId));
      this.working = false;
      this.workingStatus = '';
      await this.dismissPopOver();
    } catch (error) {
      console.error(error);
      this.error = true;
      this.workingStatus = 'Fehler beim Speichern des Kunden  (' + moment().format('HH:mm:ss') + ')';
      await this.reloadPopOver();
    }
  }

  async showCreateKundePrompt(): Promise<boolean> {
    let result = false;

    const alert = await this.alertController.create({
      subHeader: 'Der Kunde ist noch nicht im Spartax Portal vorhanden. Soll er jetzt angelegt werden?',
      buttons: [
        {
          text: 'Nein',
          role: 'cancel',
          handler: () => {
            result = false;
          }
        },
        {
          text: 'Ja',
          role: 'confirm',
          handler: () => {
            result = true;
          },
        },
      ],
    });

    await alert.present();
    await alert.onDidDismiss();
    return result;
  }

  async uploadDocument(event, auftragskunde: SpartaxAuftragskunde, documentType: SpartaxDocumentType) {
    this.error = false;
    const input = document.createElement('input');
    input.type = 'file';

    input.click();

    input.onchange = async () => {
      const file = input.files[0];

      if (file) {
        this.working = true;
        await this.reloadPopOver();

        this.workingStatus = 'Dokument wird hochgeladen...';
        await this.reloadPopOver();

        try {
          await this.spartaxService.createOrUpdateCustomerDocument(auftragskunde.Kunde.FormModelId, documentType, file.name, file);
          this.working = false;
          this.workingStatus = '';
          this.spartaxPortalKunden.set(auftragskunde.Kunde.FormModelId, await this.spartaxService.getCustomer(auftragskunde.Kunde.FormModelId));
          await this.dismissPopOver();
        } catch (error) {
          console.error(error);
          this.error = true;
          this.workingStatus = 'Fehler beim Hochladen des Dokuments (' + moment().format('HH:mm:ss') + ')';
          await this.reloadPopOver();
        }
      }
    };
  }

  isVollmachtRequired(auftragskunde: SpartaxAuftragskunde) {
    return auftragskunde.TeilDesAuftrags && auftragskunde.Kunde.Geburtstag && moment(auftragskunde.Kunde.Geburtstag).toDate() < moment().subtract(18, 'years').toDate();
  }

  isVollmachtAvailable(auftragskunde: SpartaxAuftragskunde) {
    return this.isDocumentAvailable(auftragskunde, SpartaxDocumentType.Vollmacht);
  }

  isVollmachtExpired(auftragskunde: SpartaxAuftragskunde) {
    return this.isDocumentExpired(auftragskunde, SpartaxDocumentType.Vollmacht);
  }

  async createVollmacht(auftragskunde: SpartaxAuftragskunde) {
    await this.createDocument(auftragskunde, SpartaxDocumentType.Vollmacht);
  }

  async reloadPopOver() {
    try {
      await this.popover.dismiss();
    } catch (error) { }

    this.popover = await this.modalController.create({
      component: SpartaxLoadingComponent,
      componentProps: {
        loadingtext: this.workingStatus
      }
    });

    setTimeout(async () => {
      await this.popover.present();
    }, 100);
  }

  async dismissPopOver() {
    try {
      await this.popover.dismiss();
    } catch (err) { }
  }

  uploadVollmacht(event, auftragskunde: SpartaxAuftragskunde) {
    this.uploadDocument(event, auftragskunde, SpartaxDocumentType.Vollmacht);
  }

  isBankgeheimnisAvailable(auftragskunde: SpartaxAuftragskunde) {
    return this.isDocumentAvailable(auftragskunde, SpartaxDocumentType.Bankgeheimnis);
  }

  isBankgeheimnisExpired(auftragskunde: SpartaxAuftragskunde) {
    return this.isDocumentExpired(auftragskunde, SpartaxDocumentType.Bankgeheimnis);
  }

  async createBankgeheimnis(auftragskunde: SpartaxAuftragskunde) {
    await this.createDocument(auftragskunde, SpartaxDocumentType.Bankgeheimnis);
  }

  uploadBankgeheimnis(event, auftragskunde: SpartaxAuftragskunde) {
    this.uploadDocument(event, auftragskunde, SpartaxDocumentType.Bankgeheimnis);
  }

  isSepaAvailable(auftragskunde: SpartaxAuftragskunde) {
    return this.isDocumentAvailable(auftragskunde, SpartaxDocumentType.SEPA);
  }

  isSepaExpired(auftragskunde: SpartaxAuftragskunde) {
    return this.isDocumentExpired(auftragskunde, SpartaxDocumentType.SEPA);
  }

  async createSepa(auftragskunde: SpartaxAuftragskunde) {
    await this.createDocument(auftragskunde, SpartaxDocumentType.SEPA);
  }

  uploadSepa(event, auftragskunde: SpartaxAuftragskunde) {
    this.uploadDocument(event, auftragskunde, SpartaxDocumentType.SEPA);
  }
}
