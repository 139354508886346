export enum VatCode {
  code20 = 'code20',
}

export enum BeeexpertModule {
  beeexpert = 'beeexpert',
  beeexpertForms = 'beeexpertForms'
}

export const BeeexpertModuleName = new Map<string, string> ([
  [BeeexpertModule.beeexpert, 'Beeexpert'],
  [BeeexpertModule.beeexpertForms, 'Beeexpert Formulare']
]);

export class Subscription {
  _subscriptionCode: string;
  subscriptionType: string;
  duration: string;
  description: string;
  price: number;
  pricePerMonth: number;
  vatCode: VatCode;
  minSubscriptions: number;
  beeexpertModule: BeeexpertModule;

  public static durationAsMomentObject(subscription: Subscription) {
    const values = subscription.duration.split(' ');
    if (values.length != 2) {
      throw new Error('duration cannot be parsed ' + subscription.duration);
    }

    const unit = values[1];
    const value: number = +values[0];
    let returnobject = {};
    returnobject[unit] = value;

    return returnobject;
  }
}  