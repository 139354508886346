import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CompanySubscriptionService } from 'src/app/data/service/company-subscription.service';
import { UserService } from 'src/app/data/service/user.service';

@Component({
  selector: 'app-inviteuser',
  templateUrl: './inviteuser.component.html',
  styleUrls: ['./inviteuser.component.scss'],
})
export class InviteUserComponent implements OnInit {
  isWorking: boolean;
  mailAddressOfInvited: string;
  licenseAvailable: boolean;
  userAlreadyExists: boolean;

  @ViewChild('formElement')
  ngForm!: NgForm;

  constructor(
    private modalController: ModalController,
    private companySubscriptionService: CompanySubscriptionService,
    private userService: UserService
  ) { }

  ngOnInit() {}
  
  async ionViewWillEnter() {
    let subscriptions = await this.companySubscriptionService.getMyCompaniesSubscriptions().toPromise();
    if(subscriptions.length > 0) {
      for (let subscription of subscriptions) {
        if(subscription.availableLicenses > 0){
          this.licenseAvailable = true;
        }
      }
    }
    this.isWorking = false;
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async onSubmit() {
    this.userAlreadyExists = false;

    if(this.ngForm.valid) {
      this.isWorking = true;

      try{
        const user = await this.userService.getUserByUserName(this.mailAddressOfInvited).toPromise();
                
        if(user) {
          this.userAlreadyExists = true;
          this.isWorking = false;
          return;
        }
      } catch(err) {
        //ignore error, because we actually would like to fail here
      }

      await this.userService.inviteUser(this.mailAddressOfInvited, this.licenseAvailable).toPromise();
      this.isWorking = false;
      this.closeModal();
    }
  }
}
