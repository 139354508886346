import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, IonCheckbox } from '@ionic/angular';
import { from } from 'linq-to-typescript';
import { first } from 'rxjs/operators';
import { SpartaxAuftragskunde, SpartaxAuftragskundeArt, SpartaxAuftragskundeUtil, SpartaxEinkunftsArt, SpartaxEinkunftsArtenDescription, SpartaxEinkunftsArtenName, SpartaxErklaerungsjahr, SpartaxErklaerungsjahrUtil, SpartaxSchaetzungsJahreseinkunftArt } from 'src/app/data/dto/spartax/spartax-auftragskunde.dto';
import { SpartaxFormModelDto } from 'src/app/data/dto/spartax/spartax-form-model.dto';
import { SpartaxKonfigJahreseinkunft } from 'src/app/data/dto/spartax/spartax-konfig.dto';
import { SpartaxService } from 'src/app/data/service/spartax.service';

@Component({
    selector: 'app-spartax-income',
    templateUrl: './spartax-income.component.html',
    styleUrls: ['./spartax-income.component.scss'],
})
export class SpartaxIncomeComponent implements OnInit {
    @Input() 
    model: SpartaxFormModelDto;

    @Output()
    modelChange = new EventEmitter<SpartaxFormModelDto>();

    @Input()
    edit: boolean;

    @ViewChild('formElement')
    ngForm!: NgForm;

    public readonly possibleEinkunftsarten = Object.keys(SpartaxEinkunftsArt);
    public readonly possibleEinkuenfte = Object.keys(SpartaxSchaetzungsJahreseinkunftArt);

    public working: boolean = true;
    public error: string;
    
    private nocache = true;
    private configJahreseinkuenfte: SpartaxKonfigJahreseinkunft[];
    private disableUpdate = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private spartaxService: SpartaxService,
    ) { }
    
    ngOnInit() { }

    async ngAfterViewInit(): Promise<void> {
        const queryParams = await this.activatedRoute.queryParams.pipe(first()).toPromise();
        this.nocache = (/true/i).test(queryParams.nocache);
        this.configJahreseinkuenfte = await this.spartaxService.getJahreseinkuenfteConfig(this.nocache);
        this.working = false;
    }

    formIsValid() {
        for (const auftragskunde of this.model.auftragskunden) {
            for (const erklaerungsjahr of auftragskunde.Erklaerungsjahre) {
                const erklaerungsjahrAlt = from(auftragskunde.ErklaerungsjahreAlt || []).firstOrDefault(x => x.Jahr === erklaerungsjahr.Jahr);
                if (!erklaerungsjahr.SchaetzungJahreseinkunft && (!erklaerungsjahrAlt || !erklaerungsjahrAlt.SchaetzungJahreseinkunft)) {
                    return false;
                }
            }
        }
        return this.ngForm?.valid;
    }

    markAllControlsAsTouched() {
        for (const control in this.ngForm.controls) {
            this.ngForm.controls[control].markAsTouched();
        }

        this.error = null;
        if(!this.formIsValid()) {
            this.error = 'Bitte prüfen Sie ob Sie für alle Personen eine gültige Eingabe vorgenommen haben.';
        }
    }

    get erklaerungsjahre() {
        return from(this.model.auftragskunden).selectMany(x => x.Erklaerungsjahre)
            .select(x => x.Jahr).distinct().orderBy(x => x).toArray();
    }

    getDisplayNameOfEinkunftsArt(Einkunftsart: SpartaxEinkunftsArt | string) {
        return SpartaxEinkunftsArtenName.get(Einkunftsart);
    }
      
    getDisplayDescriptionOfEinkunftsArt(Einkunftsart: SpartaxEinkunftsArt | string) {
        return SpartaxEinkunftsArtenDescription.get(Einkunftsart);
    }
    
    isEinkunftsArtSelectedInCurrentOrder(erklaerungsjahr: SpartaxErklaerungsjahr, incomeType: SpartaxEinkunftsArt | string) {
        return erklaerungsjahr.EinkunftsArten.includes(incomeType as SpartaxEinkunftsArt);
    }

    isEinkunftsArtSelectedInPreviousOrder(jahr: number, auftragskunde: SpartaxAuftragskunde, incomeType: SpartaxEinkunftsArt | string) {
        const erklaerungsjahr = from(auftragskunde.ErklaerungsjahreAlt || []).firstOrDefault(x => x.Jahr === jahr);
        return erklaerungsjahr && erklaerungsjahr.EinkunftsArten.includes(incomeType as SpartaxEinkunftsArt);
    }
    
    onEinkunftsArtChange(erklaerungsjahr: SpartaxErklaerungsjahr, auftragskunde: SpartaxAuftragskunde, incomeType: SpartaxEinkunftsArt | string) {
        if (!this.disableUpdate) {
            if(erklaerungsjahr.EinkunftsArten.length === 0) {
                erklaerungsjahr.SchaetzungJahreseinkunft = null;
            }

            if (erklaerungsjahr.EinkunftsArten.includes(incomeType as SpartaxEinkunftsArt)) {
                erklaerungsjahr.EinkunftsArten.splice(erklaerungsjahr.EinkunftsArten.indexOf(incomeType as SpartaxEinkunftsArt), 1);
            } else {
                erklaerungsjahr.EinkunftsArten.push(incomeType as SpartaxEinkunftsArt);
                if (!erklaerungsjahr.SchaetzungJahreseinkunft) {
                    const erklaerungsjahrAlt = from(auftragskunde.ErklaerungsjahreAlt || []).firstOrDefault(x => x.Jahr === erklaerungsjahr.Jahr);
                    if (erklaerungsjahrAlt) {
                        erklaerungsjahr.SchaetzungJahreseinkunft = erklaerungsjahrAlt.SchaetzungJahreseinkunft;
                    }
                }
            }
        }
    }

    onWiederaufnahmeEinkunftsart(jahr: number, auftragskunde: SpartaxAuftragskunde, incomeType: SpartaxEinkunftsArt | string) {
        const erklaerungsjahr = from(auftragskunde.Erklaerungsjahre || []).first(x => x.Jahr === jahr);
        if(!erklaerungsjahr.EinkunftsArten.includes(incomeType as SpartaxEinkunftsArt)) {
            erklaerungsjahr.EinkunftsArten.push(incomeType as SpartaxEinkunftsArt);
        }
    }

    erklaerungsjahrAltExists(jahr: number, auftragskunde: SpartaxAuftragskunde) {
        return from(auftragskunde.ErklaerungsjahreAlt || []).any(x => x.Jahr === jahr);
    }

    isKeineEinkuenfteSelectedCurrentYear(erklaerungsjahr: SpartaxErklaerungsjahr) {
        return erklaerungsjahr.EinkunftsArten.length === 0;
    }

    isKeineEinkuenfteSelectedInPreviousOrder(jahr: number, auftragskunde: SpartaxAuftragskunde) {
        const erklaerungsjahr = from(auftragskunde.ErklaerungsjahreAlt || []).firstOrDefault(x => x.Jahr === jahr);
        return erklaerungsjahr && erklaerungsjahr.EinkunftsArten.length === 0;
    }

    clearAllEinkunftsArten(erklaerungsjahr: SpartaxErklaerungsjahr, auftragskunde: SpartaxAuftragskunde) {
        this.disableUpdate = true;
        erklaerungsjahr.EinkunftsArten.splice(0, erklaerungsjahr.EinkunftsArten.length);
        const erklaerungsjahrAlt = from(auftragskunde.ErklaerungsjahreAlt || []).firstOrDefault(x => x.Jahr === erklaerungsjahr.Jahr);
        if (erklaerungsjahrAlt) {
            erklaerungsjahr.SchaetzungJahreseinkunft = null;
        } else {
            erklaerungsjahr.SchaetzungJahreseinkunft = SpartaxSchaetzungsJahreseinkunftArt.wert1;
        }
        setTimeout(() => {
            this.disableUpdate = false;    
        }, 100);
    }
    
    getSchaetzungJahreseinkunftDisplayName(year: number, value: SpartaxSchaetzungsJahreseinkunftArt) {
        const configValue = from(this.configJahreseinkuenfte).firstOrDefault(x => x.Jahr === year);
        if (configValue) {
            let incomeValue = configValue['Wert' + (value === SpartaxSchaetzungsJahreseinkunftArt.maximum ? 3 : value.replace('wert', ''))];
            const incomeFormatted = new Intl.NumberFormat('de-AT', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(incomeValue);
            if(value === SpartaxSchaetzungsJahreseinkunftArt.maximum) {
                return `> ${incomeFormatted} bzw. weiß ich nicht`;
            } else {
                return `< ${incomeFormatted}`;
            }
        } else {
            return value;
        }
    }

    isSchaetzungJahreseinkunftSelectedInCurrentOrder(year: number, value: SpartaxSchaetzungsJahreseinkunftArt, kunde: SpartaxAuftragskunde) {
        const erklaerungsjahr = kunde.Erklaerungsjahre.find(x => x.Jahr === year);
        return erklaerungsjahr && erklaerungsjahr.SchaetzungJahreseinkunft === value;
    }

    isSchaetzungJahreseinkunftSelectedInPreviousOrder(year: number, value: SpartaxSchaetzungsJahreseinkunftArt, kunde: SpartaxAuftragskunde) {
        const erklaerungsjahr = from(kunde.ErklaerungsjahreAlt || []).firstOrDefault(x => x.Jahr === year);
        return erklaerungsjahr && erklaerungsjahr.SchaetzungJahreseinkunft === value;
    }
    
    onSchaetzungJahreseinkunftChange(year: number, value: SpartaxSchaetzungsJahreseinkunftArt, kunde: SpartaxAuftragskunde) {
        kunde.Erklaerungsjahre.find(x => x.Jahr === year).SchaetzungJahreseinkunft = value;
        this.modelChange.emit(this.model);
    }

    isChild(auftragskunde: SpartaxAuftragskunde){
        return auftragskunde.AuftragsKundeArt == SpartaxAuftragskundeArt.kind;
    }

    onUnterbrechungChanged(auftragskunde: SpartaxAuftragskunde){
        auftragskunde.Unterbrechung = !auftragskunde.Unterbrechung;
    }

    getIncomeRelevantErklaerungsjahre(auftragskunde: SpartaxAuftragskunde) {
        return SpartaxAuftragskundeUtil.getIncomeRelevantErklaerungsjahre(auftragskunde);
    }

    showDienstverhaeltnisUnterbrechungForPerson(auftragskunde: SpartaxAuftragskunde) {
        return from(auftragskunde.Erklaerungsjahre).any(x => x.EinkunftsArten.includes(SpartaxEinkunftsArt.dienstverhaeltnis));
    }

    showYearForDienstverhaeltnis(year: SpartaxErklaerungsjahr) {
        return from(year.EinkunftsArten).any(x => x == SpartaxEinkunftsArt.dienstverhaeltnis);
    }
    
    showYearForGesellschaft(year: SpartaxErklaerungsjahr) {
        return from(year.EinkunftsArten).any(x => x == SpartaxEinkunftsArt.betrieb || x == SpartaxEinkunftsArt.vermietung);
    }

    changeStateBoolean(year: SpartaxErklaerungsjahr, prop: string, kunde: SpartaxAuftragskunde) {
        year[prop] = !year[prop];
    }
}
