import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController, Platform, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-canvas-popup',
  templateUrl: './canvas-popup.component.html',
  styleUrls: ['./canvas-popup.component.scss'],
})
export class CanvasPopupComponent {
  @ViewChild('imageCanvas', { static: false }) canvas: any;
  @ViewChild('content') content: IonContent;
  canvasElement: any;
  saveX: number;
  saveY: number;
  willDraw = false;
  answer: number;

  selectedColor = '#9e2956';
  colors = ['#9e2956', '#c2281d', '#de722f', '#edbf4c', '#5db37e', '#459cde', '#4250ad', '#802fa3'];

  drawing = false;
  lineWidth = 5;

  constructor(public modalCtrl: ModalController) { }

  ngAfterViewInit() {

  }

  ngOnInit() {
  }

  get willDrawMessage() {
    switch (this.answer) {
      case 1:
        return 'Sehr gut, möchtest du dann vielleicht zur Auflockerung etwas zeichnen?';
      case 2:
        return 'Alles klar! Möchtest du dann vielleicht zur Auflockerung etwas zeichnen?';
      case 3:
        return 'Unentschlossen? Möchtest du dann vielleicht zur Auflockerung etwas zeichnen?';
      case 4:
        return 'In Ordnung! Dann möchtest du wahrscheindlich nichts zur Auflockerung zeichnen, oder?';
      case 5:
        return 'Verstehe! Dann frage ich dich jetzt besser nicht ob du zur Auflockerung etwas zeichnen möchtest, oder?';
    }
  }

  setWillDraw(value) {
    if (value) {
      this.willDraw = true;
      //sleep for 0.1 second
      setTimeout(() => {
        // Set the Canvas Element and its size
        this.canvasElement = this.canvas.nativeElement;
        this.canvasElement.width = 412;
        this.canvasElement.height = 380;
      }, 100);
    } else {
      this.willDraw = false;
      this.modalCtrl.dismiss();
    }
  }


  clearDrawing() {
    let ctx = this.canvasElement.getContext('2d');
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  isMobile() {
    if (window.innerWidth > 770) {
      return false;
    }
    return true;
  }

  downloadDrawing(shouldDownload: boolean) {
    if (shouldDownload) {
      const canvas: HTMLCanvasElement = this.canvas.nativeElement;
      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = 'beeexpert-Zeichnung.png';
      link.click();
    }

    // this.dismiss();
    this.modalCtrl.dismiss();
  }

  startDrawing(ev) {
    this.drawing = true;
    var canvasPosition = this.canvasElement.getBoundingClientRect();


    if (this.isMobile()) {
      this.saveX = ev.touches[0].pageX - canvasPosition.x;
      this.saveY = ev.touches[0].pageY - canvasPosition.y;
    } else {
      this.saveX = ev.pageX - canvasPosition.x;
      this.saveY = ev.pageY - canvasPosition.y;
    }
  }

  endDrawing() {
    this.drawing = false;
  }

  selectColor(color) {
    this.selectedColor = color;
  }

  setBackground() {
    var background = new Image();
    background.src = './assets/code.webp';
    let ctx = this.canvasElement.getContext('2d');

    background.onload = () => {
      ctx.drawImage(background, 0, 0, this.canvasElement.width, this.canvasElement.height);
    }
  }

  moved(ev) {
    if (!this.drawing) return;

    var canvasPosition = this.canvasElement.getBoundingClientRect();
    let ctx = this.canvasElement.getContext('2d');



    let currentX;
    let currentY;

    if (this.isMobile()) {
      currentX = ev.touches[0].pageX - canvasPosition.x;
      currentY = ev.touches[0].pageY - canvasPosition.y;
    } else {
      currentX = ev.pageX - canvasPosition.x;
      currentY = ev.pageY - canvasPosition.y;
    }

    ctx.lineJoin = 'round';
    ctx.strokeStyle = this.selectedColor;
    ctx.lineWidth = this.lineWidth;

    ctx.beginPath();
    ctx.moveTo(this.saveX, this.saveY);
    ctx.lineTo(currentX, currentY);
    ctx.closePath();

    ctx.stroke();

    this.saveX = currentX;
    this.saveY = currentY;
  }
}