import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SpartaxAuftragskunde } from 'src/app/data/dto/spartax/spartax-auftragskunde.dto';
import { SpartaxFormModelDto, SpartaxFormModelDtoUtil } from 'src/app/data/dto/spartax/spartax-form-model.dto';
import { SpartaxKind } from 'src/app/data/dto/spartax/spartax-kunde.dto';

@Component({
  selector: 'app-spartax-children',
  templateUrl: './spartax-children.component.html',
  styleUrls: ['./spartax-children.component.scss'],
})
export class SpartaxChildrenComponent implements OnInit {
  @Input() 
  model: SpartaxFormModelDto;

  @Output()
  modelChange = new EventEmitter<SpartaxFormModelDto>();

  @Input()
  edit: boolean;

  @ViewChild('formElement')
  ngForm!: NgForm;

  public working: boolean = true;

  public get hauptkunde(): SpartaxAuftragskunde {
    return SpartaxFormModelDtoUtil.getHauptkundeAuftragskunde(this.model);
  }

  constructor() { }

  ngOnInit() { }

  formIsValid() {
    return this.ngForm.valid;
  }

  addChild() {
    const newKind: SpartaxKind = {};
    newKind.FamilienBeihilfeIchErhalten = false;
    newKind.AlimenteIchBezahlt = false;
    newKind.VaterIstExPartner = this.hauptkunde.Kunde.Geschlecht === 'Weiblich' && 
      !this.hauptkunde.Kunde.Familienstaende[0].VornamePartner ? true : false;
    newKind.MutterIstExPartner = this.hauptkunde.Kunde.Geschlecht === 'Männlich' && 
      !this.hauptkunde.Kunde.Familienstaende[0].VornamePartner ? true : false;
    this.model.kinder.push(newKind);
  }

  ngAfterViewInit() {
    this.working = false;
  }

  get possibleFathers(){
    const result = new Map<number, string>();
    if (this.hauptkunde.Kunde.Geschlecht === 'Männlich'){
      result.set(0, this.hauptkunde.Kunde.Vorname,);
      if (this.hauptkunde.Kunde.Familienstaende[0].VornamePartner) {
        result.set(1, "Ex-Partner von " + this.hauptkunde.Kunde.Familienstaende[0].VornamePartner);
      }
    } else {
      if (this.hauptkunde.Kunde.Familienstaende[0].VornamePartner){
        result.set(0, this.hauptkunde.Kunde.Familienstaende[0].VornamePartner);
      }
      result.set(1, "Ex-Partner von " + this.hauptkunde.Kunde.Vorname);
    }
    result.set(2, "Pflegekind");
    return result;
  }
  
  getFatherValue(kind: SpartaxKind) {
    if(kind.VaterIstExPartner) {
      return 1;
    } else if (kind.VaterIstPflegevater) {
      return 2;
    } else {
      return 0;
    }
  }

  onFatherChange(kind: SpartaxKind, value: number) {
    if(value === 0) {
      kind.VaterIstExPartner = false;
      kind.VaterIstPflegevater = false;
    } else if (value === 1) {
      kind.VaterIstExPartner = true;
      kind.VaterIstPflegevater = false;
    } else if (value === 2) {
      kind.VaterIstPflegevater = true;
      kind.VaterIstExPartner = false;
      this.onMotherChange(kind, 2);
    }
  }

  get possibleMothers() {
    const result = new Map<number, string>();
    if (this.hauptkunde.Kunde.Geschlecht === 'Weiblich'){
      if (this.hauptkunde.Kunde.Familienstaende[0].VornamePartner) {
        result.set(1, "Ex-Partnerin von " + this.hauptkunde.Kunde.Familienstaende[0].VornamePartner);
      }
      result.set(0, this.hauptkunde.Kunde.Vorname);
    } else {
      result.set(1, "Ex-Partnerin von " + this.hauptkunde.Kunde.Vorname);
      if (this.hauptkunde.Kunde.Familienstaende[0].VornamePartner) {
        result.set(0, this.hauptkunde.Kunde.Familienstaende[0].VornamePartner);
      }
    }
    result.set(2, "Pflegekind");
    return result;
  }

  getMotherValue(kind: SpartaxKind) {
    if(kind.MutterIstExPartner) {
      return 1;
    } else if (kind.MutterIstPflegemutter) {
      return 2;
    } else {
      return 0;
    }
  }

  onMotherChange(kind: SpartaxKind, value: number) {
    if(value === 0) {
      kind.MutterIstExPartner = false;
      kind.MutterIstPflegemutter = false;
    } else if (value === 1) {
      kind.MutterIstExPartner = true;
      kind.MutterIstPflegemutter = false;
    } else if (value === 2) {
      kind.MutterIstPflegemutter = true;
      kind.MutterIstExPartner = false;
      this.onFatherChange(kind, 2);
    }
  }

  removeItem(array, idx) {
    this.working = true;
    let newarray = [];
    let currentIdx = 0;
    for (let val of array) {
      if (currentIdx != idx) {
        newarray.push(val);
      }
      currentIdx++;
    }
    this.model.kinder = newarray;
    setTimeout(() => {
      this.working = false;
    }, 50);
  }

  markAllControlsAsTouched() {
    for (const control in this.ngForm.controls) {
      this.ngForm.controls[control].markAsTouched();
    }
  }

  onFamilienbeihilfeChange(kind: SpartaxKind, value: boolean) {
    kind.FamilienBeihilfe = value;
    if (kind.FamilienBeihilfe) {
      if (kind.MutterIstExPartner || kind.VaterIstExPartner) {
        kind.FamilienBeihilfeIchErhalten = true;
      }
    } else {
      kind.FamilienBeihilfeIchErhalten = false;
      kind.ErhoehteFamilienBeihilfe = false;
      kind.AlimenteErhalten = false;
      kind.AlimenteIchErhalten = false;
    }
  }

  onFamilienBeihilfeIchErhaltenChange(kind: SpartaxKind, value: boolean) {
    kind.FamilienBeihilfeIchErhalten = value;
    kind.AlimenteIchErhalten = value;
  }

  onAlimenteBezahltChange(kind: SpartaxKind, value: boolean) {
    kind.AlimenteBezahlt = value;
    if (kind.AlimenteBezahlt) {
      if (kind.MutterIstExPartner || kind.VaterIstExPartner) {
        kind.AlimenteIchBezahlt = true;
      }
    } else {
      kind.AlimenteIchBezahlt = false;
    }
  }

  onAlimenteErhaltenChange(kind: SpartaxKind, value: boolean) {
    kind.AlimenteErhalten = value;
    if (kind.AlimenteErhalten) {
      if (kind.MutterIstExPartner || kind.VaterIstExPartner) {
        kind.AlimenteIchErhalten = true;
      }
    } else {
      kind.AlimenteIchErhalten = false;
    }
  }
}
