import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController, NavController, PopoverController, ToastController } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import { EditProfilePopoverComponent } from 'src/app/components/profile/edit-profile-popover/edit-profile-popover.component';
import { Company, User } from 'src/app/data/entities';
import { ISearchResult } from 'src/app/data/interfaces/search-result';
import { AuthService } from 'src/app/data/service/auth.service';
import { UserService } from 'src/app/data/service/user.service';
import * as countries from 'i18n-iso-countries';
import { UserBfi } from 'src/app/data/entities/user-bfi.entity';
import { TrackingService } from 'src/app/data/service/tracking.service';
import { TrackingPoint } from 'src/app/data/dto/tracking.dto';
import { CompanyService } from 'src/app/data/service/company.service';
import { SeoService, PageTypes } from 'src/app/data/service/seo.service';
import { BeeexpertUserPipe } from 'src/app/utils/custom-pipes-pipe';
import { ScullyRoutesService } from '@scullyio/ng-lib';
import { SearchService } from 'src/app/data/service/search.service';
import { ProjectPopoverComponent } from '../profile/project-popover/project-popover.component';

@Component({
  selector: 'app-profilecomp',
  templateUrl: './profilecomp.component.html',
  styleUrls: ['./profilecomp.component.scss'],
})
export class ProfilecompComponent implements OnInit {
  public loggedInUserIsViewingItsOwnProfile = false;
  public user: User;
  public profileid: number;
  public userThumbnail: string;
  public currentUser: User;
  public currentUserBfi: UserBfi;
  public loading = true;
  public searchResult = [];
  public matchingvalue;
  public ismobile: boolean;
  public bigBfi: boolean;
  public biggerMatchingView: boolean = false;
  public companyImageUrl: string;
  public companyThumbnailAvailable: boolean = false;
  public distance: number = -1;
  public hideAll = false;
  public showLoadingScreen = false;
  public followed = false;
  public hasBasicProfile: boolean = true;
  public offeringConsultantCategories = [];
  maxCatToShow = 5;
  followingLoading = false;
  @Input() hideLoadingscreen: boolean = false;
  showTooltip = false;




  showMoreConsultantCategories = false;
  showMoreReferences = false;
  showMoreReferencesButton = false;
  showMoreConsultantCategoriesButton = false;
  isMobileView = false;
  imageWidth = '100%';
  yValueReached = false; //true if the user scrolled to point 468
  chaningConsultant = false;
  readMoreContent = false;
  seeCompanyContent = false;

  public loaded = false;
  public showN = true;
  public showV = true;
  public showE = true;
  public showG = true;
  public showO = true;
  public bfislider = [
    {
      shortName: 'O',
      name: 'Offenheit für Erfahrungen',
      img: '/assets/illustrations/Element 2120px.png',
      text: 'Bei einer schwachen Ausprägung ist die Person eher konservativ und vorsichtig, die starke Ausprägung führt zu Erfindungsreichtum und Neugierde.',
      val: 0,
      colorid: '1',
    },
    {
      shortName: 'G',
      name: 'Gewissenhaftigkeit',
      img: '/assets/illustrations/Element 3120px.png',
      text: 'Ist dieser Faktor bei einer Person schwach ausgeprägt, ist sie unbekümmert und nachlässig; im Gegenteil handelt sie effektiv und ist organisiert. ',
      val: 0,
      colorid: '2',
    },
    {
      shortName: 'E',
      name: 'Extraversion',
      img: '/assets/illustrations/Element 1120px.png',
      text: 'Eine Person verhält sich zurückhaltend und reserviert oder sie ist gesellig.',
      val: 0,
      colorid: '3',
    },
    {
      shortName: 'V',
      name: 'Verträglichkeit',
      img: '/assets/illustrations/Element 4120px.png',
      text: 'Hier stehen Wettbewerbsorientierung und Antagonismus im Gegensatz zu kooperativem Verhalten und Empathie.',
      val: 0,
      colorid: '4',
    },
    {
      shortName: 'N',
      name: 'Negative Emotionalität',
      img: '/assets/illustrations/Element 5120px.png',
      text: 'Die unterschiedlichen Ausprägungen zeigen sich darin, dass eine Person selbstsicher und ruhig ist oder emotional und verletzlich.',
      val: 0,
      colorid: '5',
    },
  ]

  constructor(
    public route: ActivatedRoute,
    public userService: UserService,
    public authService: AuthService,
    public popoverController: PopoverController,
    public trackingService: TrackingService,
    public companyService: CompanyService,
    public seoService: SeoService,
    public router: Router,
    public nav: NavController,
    public userPipe: BeeexpertUserPipe,
    public srs: ScullyRoutesService,
    private alertController: AlertController,
    public toastController: ToastController,
    public searchService: SearchService,
    private modalCtrl: ModalController,
  ) {

    var intervalId = setInterval(function () {
      var element = document.getElementsByClassName("beeex4-left-side-image");
      if (element.length === 0) {
        clearInterval(intervalId);

        //set overall-container to display block
        let overallContainer = document.getElementById("overall-container");
        console.log("overallContainer", overallContainer);

        if (overallContainer) {
          overallContainer.style.display = "block";
        }
      }
    }, 100); // Überprüfe alle 100 Millisekunden

    //If Userlist is empty
    try {
      this.searchResult = this.router.getCurrentNavigation().extras.state.userlist;
    } catch (error) { }

    srs.getCurrent().subscribe(user => {
      if (user && user.userId) {
        this.user = user as any;
        this.setSeoData();
      }
    });



    countries.registerLocale(require('i18n-iso-countries/langs/de.json'));

  }
  


  async generateOfferingConsultantCategories() {
    let selectedSubCategorieIds: string[] = [];
    let selectedCategoryObj;
    if (this.authService.isLoggedIn() && this.currentUser?.lastSelectedSubFieldId) {
      selectedSubCategorieIds = Array.isArray(this.currentUser.lastSelectedSubFieldId)
        ? this.currentUser.lastSelectedSubFieldId
        : [this.currentUser.lastSelectedSubFieldId];
    } else {
      let localsubVal = localStorage.getItem('local_subfield');
      if (localsubVal) {
        selectedSubCategorieIds = JSON.parse(localsubVal);
      }
    }

    if (selectedSubCategorieIds.length > 0) {
      for (let subcatId of selectedSubCategorieIds) {
        for (let cat of this.user?.offeringConsultantCategories) {
          if (cat.categoryId == subcatId) {
            selectedCategoryObj = cat;
            break;
          }
        }
      }
    }

    if (this.showMoreConsultantCategories) {
      this.maxCatToShow = this.user?.offeringConsultantCategories.length;
    } else {
      if (this.isMobile()) {
        this.maxCatToShow = 5;
      } else {
        this.maxCatToShow = 8;
      }
    }

    return this.user?.offeringConsultantCategories;
  }

  get confirmedUserReferences() {
    if (this.showMoreReferences || this.isMobile()) {
      return this.user?.confirmedUserReferences;
    } else {
      return this.user?.confirmedUserReferences?.slice(0, 4);
    }
  }

  async setShowMoreConsultantCategories(values) {
    this.showMoreConsultantCategories = values;
    this.offeringConsultantCategories = await this.generateOfferingConsultantCategories();
  }

  async openProjectModal(reference) {
    const modal = await this.modalCtrl.create({
      component: ProjectPopoverComponent,
      componentProps: {
        projectTitle: reference.projectTitle,
        projectDescription: reference.projectDescription,
        referenceCompanyName: reference.referenceCompanyName,
        referenceCompanyContactName: reference.referenceCompanyContactName,
        reviewerNote: reference.reviewerNote,
      },
    });

    await this.trackingService.addTrackingRecord({
      timestamp: new Date(),
      trackingPoint: TrackingPoint.readReference,
      userId: this.user.userId,
      companyId: this.user.company?.companyId,
      visitorUserId: this.currentUser?.userId
    }).toPromise();
    return await modal.present();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 992) {
      this.ismobile = false;
    } else {
      this.ismobile = true;
    }

    if (window.innerWidth > 1509) {
      this.bigBfi = false;
    } else {
      this.bigBfi = true;
    }

    for (let slide of this.bfislider) {
      switch (slide.shortName) {
        case 'G':
          if ((window.innerWidth > 1509 && window.innerWidth < 1952) || window.innerWidth < 406) {
            slide.name = "Gewissen- haftigkeit";
          } else {
            slide.name = "Gewissenhaftigkeit";
          }
          break;
        case 'V':
          if (window.innerWidth > 1509 && window.innerWidth < 1597) {
            slide.name = "Verträg- lichkeit";
          } else {
            slide.name = "Verträglichkeit";
          }
          break;
      }
    }
  }

  increaseImage(currentPosition: number) {
    if (!this.yValueReached) {
      if (currentPosition > 11668) {
        this.yValueReached = true;
      }
    } else {
      if (currentPosition < 380) {
        let newImageWidth = 100 - (currentPosition / 6.17);
        this.imageWidth = newImageWidth + '%';
      } else {
        this.imageWidth = '60%';
      }
    }
  }

  sortConsultantCategories(categories) {
    let prioritizedIds = null;

    if (this.currentUser && this.currentUser.lastSelectedSubFieldId) {
      prioritizedIds = new Set(this.user.lastSelectedSubFieldId);
    } else {
      const localSubfield = localStorage.getItem('local_subfield');
      let localsubVal = localSubfield ? JSON.parse(localSubfield) : [];

      prioritizedIds = new Set(localsubVal);

    }

    // Separate categories that match the prioritized IDs
    const prioritizedCategories = [];
    const otherCategories = [];

    categories.forEach(category => {
      if (prioritizedIds.has(category.id)) {
        prioritizedCategories.push(category);
      } else {
        otherCategories.push(category);
      }
    });

    // Sort both prioritized and other categories by name length
    prioritizedCategories.sort((a, b) => a.name.length - b.name.length);
    otherCategories.sort((a, b) => a.name.length - b.name.length);

    // Combine prioritized categories at the front with the other categories
    const sortedCategories = [...prioritizedCategories, ...otherCategories];

    // Now group them into lines of max 28 characters length
    const lines = [[]];
    let currentLine = 0;

    sortedCategories.forEach(category => {
      const categoryData = { ...category };
      if (lines[currentLine].reduce((acc, curr) => acc + curr.name.length, 0) + categoryData.name.length <= 28) {
        lines[currentLine].push(categoryData);
      } else {
        currentLine++;
        lines[currentLine] = [categoryData];
      }
    });

    // Flatten the lines array and return it
    const retval = lines.flat();
    return retval;
  }

  public isLoading() {
    return this.loading && !this.loaded;
  }

  async readMoreClicked() {
    this.readMoreContent = true;
    await this.trackingService.addTrackingRecord({
      timestamp: new Date(),
      trackingPoint: TrackingPoint.readMoreProfile,
      userId: this.user.userId,
      companyId: this.user.company?.companyId,
      visitorUserId: this.currentUser?.userId
    }).toPromise();
  }

  async seeCompanyContentClicked() {
    this.seeCompanyContent = true;
    await this.trackingService.addTrackingRecord({
      timestamp: new Date(),
      trackingPoint: TrackingPoint.seeAllCompanyDetails,
      userId: this.user.userId,
      companyId: this.user.company?.companyId,
      visitorUserId: this.currentUser?.userId
    }).toPromise();
  }

  public async consultantChanged(consultant, useHideAll = false, hideAll = false, searchResult: User[] = [], showLoadingScreen = true, content = null) {
    this.chaningConsultant = true;
    this.readMoreContent = false;
    this.seeCompanyContent = false;
    this.showLoadingScreen = showLoadingScreen;

    if (this.hideLoadingscreen) {
      showLoadingScreen = true;
    }

    if (!consultant) {
      this.hideAll = true;
    }

    if (useHideAll) {
      this.hideAll = hideAll;
      if (searchResult.length == 0) {
        this.hideAll = true;
      }
    }

    if (!hideAll) {
      if (this.loaded) {
        var element = document.getElementsByClassName("beeex-4-loadingContainer");
        element[0].classList.add("peace-out");
        await new Promise(r => setTimeout(r, 500));
        element[0].classList.remove("peace-out");
      }

      this.loading = true;
      try {
        this.profileid = consultant.userId;
      } catch (error) {
        console.error("Error in consultantChanged", error);
      }
      this.user = consultant;
      this.user = await this.userService.getUser(this.profileid, true).toPromise();

      this.companyImageUrl = this.companyService.getCompanyImageUrl(this.user.company.companyId);
      this.hasBasicProfile = await this.userService.hasBasicProfile(this.user.companyId);

      this.user.offeringConsultantCategories = this.sortConsultantCategories(this.user.offeringConsultantCategories);
      if (this.user.confirmedUserReferences.length > 4 && !this.isMobile()) {
        this.showMoreReferencesButton = true;
      } else {
        this.showMoreReferencesButton = false;
      }

      if (window.innerWidth > 992) {
        this.ismobile = false;
      } else {
        this.ismobile = true;
      }

      if (window.innerWidth > 1509) {
        this.bigBfi = false;
      } else {
        this.bigBfi = true;
      }

      const modal = await this.modalCtrl.getTop();
      if (modal) {
        modal.dismiss();
      }

      if (this.profileid) {
        const regExp = /[a-zA-Z]/g;
        this.setSeoData();
        this.userThumbnail = this.userService.getUserImageUrl(this.profileid, this.profileid === this.authService.currentUserValue?.userId);

        let mimeType: string;
        if (this.user?.userImage?.mimeType) {
          mimeType = this.user?.userImage?.mimeType;
          this.userThumbnail += "." + mimeType.substring(mimeType.indexOf('/') + 1);
        }

        if (this.authService.isLoggedIn()) {
          let currentuserid = this.authService.currentUserValue?.userId;
          this.currentUser = await this.userService.getUser(currentuserid, true).toPromise();

          this.loggedInUserIsViewingItsOwnProfile = this.currentUser.userId == this.user.userId;

          if (this.loggedInUserIsViewingItsOwnProfile) {
            this.user = await this.userService.getUser(this.profileid, false).toPromise();
          }

          this.currentUserBfi = this.currentUser.bfi;
        } else {
          this.currentUserBfi = this.userService.calculateBfi(this.userService.getTempBfi());
        }

        this.offeringConsultantCategories = await this.generateOfferingConsultantCategories();
        if (this.user.offeringConsultantCategories.length > 5 && this.isMobile()) {
          this.showMoreConsultantCategoriesButton = true;
        } else if (this.user.offeringConsultantCategories.length > 8 && !this.isMobile()) {
          this.showMoreConsultantCategoriesButton = true;
        } else {
          this.showMoreConsultantCategoriesButton = false;
        }

        if (this.currentUserBfi) {
          for (let slide of this.bfislider) {
            switch (slide.shortName) {
              case 'O':
                slide.val = this.compareBfi(this.currentUserBfi.bfiO, this.user.bfi.bfiO);
                break;
              case 'G':
                if (window.innerWidth > 1509 && window.innerWidth < 1952) {
                  slide.name = "Gewissen- haftigkeit";
                } else {
                  slide.name = "Gewissenhaftigkeit";
                }
                slide.val = this.compareBfi(this.currentUserBfi.bfiG, this.user.bfi.bfiG);
                break;
              case 'E':
                slide.val = this.compareBfi(this.currentUserBfi.bfiE, this.user.bfi.bfiE);
                break;
              case 'V':
                if (window.innerWidth > 1509 && window.innerWidth < 1597) {
                  slide.name = "Verträg- lichkeit";
                } else {
                  slide.name = "Verträglichkeit";
                }
                slide.val = this.compareBfi(this.currentUserBfi.bfiV, this.user.bfi.bfiV);
                break;
              case 'N':
                slide.val = this.compareBfi(this.currentUserBfi.bfiN, this.user.bfi.bfiN);
                break;
            }
          }
        }

        if (!this.authService.isLoggedIn()) {
          if (localStorage.getItem('local_longitude') && localStorage.getItem('local_latitude')) {
            this.distance = this.searchService.calculateDistanceBetweenTwoPoints(localStorage.getItem('local_latitude'), localStorage.getItem('local_longitude'), this.user.company.latitude, this.user.company.longitude);
          }
        } else {
          if (this.user.lastLongitude && this.user.lastLatitude) {
            this.distance = this.searchService.calculateDistanceBetweenTwoPoints(this.currentUser.lastLatitude, this.currentUser.lastLongitude, this.user.company.latitude, this.user.company.longitude);
          } else if (localStorage.getItem('local_longitude') && localStorage.getItem('local_latitude')) {
            this.distance = this.searchService.calculateDistanceBetweenTwoPoints(localStorage.getItem('local_latitude'), localStorage.getItem('local_longitude'), this.user.company.latitude, this.user.company.longitude);
          }

          let followedUserIds = await this.userService.getMyFollowedUsers().toPromise();
          this.followed = followedUserIds.some(id => Number(id) == this.user.userId);
        }

        this.loading = false;
        this.loaded = true;

        if (this.user.bfi && this.currentUserBfi) {
          this.matchingvalue = Math.round(this.userService.compareTwoUserBfis(this.currentUserBfi, this.user.bfi) * 100);
        }

        if (!this.loggedInUserIsViewingItsOwnProfile) {
          await this.trackingService.addTrackingRecord({
            timestamp: new Date(),
            trackingPoint: TrackingPoint.profileVisit,
            userId: this.user.userId,
            companyId: this.user.company?.companyId,
            visitorUserId: this.authService.currentUserValue?.userId
          }).toPromise();
        }
      } else if (this.authService.isLoggedIn()) {
        let currentuserid = this.authService.currentUserValue?.userId;
        this.currentUser = await this.userService
          .getUser(currentuserid, !this.loggedInUserIsViewingItsOwnProfile)
          .toPromise();

        if (this.currentUser.profileId) {
          await this.nav.navigateRoot('/berater/' + this.currentUser.profileId);
        } else {
          await this.nav.navigateRoot('/profile/' + this.currentUser.userId);
        }
      } else {
        await this.nav.navigateRoot('/');
      }
    }

    this.chaningConsultant = false;
  }

  async ngOnInit() { }

  async setDistance() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (this.authService.isLoggedIn()) {
            this.currentUser.lastLongitude = position.coords.longitude;
            this.currentUser.lastLatitude = position.coords.latitude;

            this.userService.updateUser(this.currentUser.userId, this.currentUser).subscribe();
            this.distance = this.searchService.calculateDistanceBetweenTwoPoints(this.currentUser.lastLatitude, this.currentUser.lastLongitude, this.user.company.latitude, this.user.company.longitude);
          } else {
            localStorage.setItem('local_longitude', position.coords.longitude.toString());
            localStorage.setItem('local_latitude', position.coords.latitude.toString());

            this.distance = this.searchService.calculateDistanceBetweenTwoPoints(localStorage.getItem('local_latitude'), localStorage.getItem('local_longitude'), this.user.company.latitude, this.user.company.longitude);
          }
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED || error.code === error.POSITION_UNAVAILABLE || error.code === error.TIMEOUT) {
            this.showLocationAlert();
          }
        }
      );
    } else {
      this.showLocationAlert();
    }
  }

  showLocationAlert() {
    alert('Bitte geben Sie den Standortzugriff im Browser frei, um diese Funktion zu nutzen.');
  }

  setSeoData() {
    if (this.router.url.includes('/profile')) {
      let name = this.userPipe.transform(this.user);
      this.userThumbnail = this.userService.getUserImageUrl(this.user.userId, this.user.userId === this.authService.currentUserValue?.userId);

      let mimeType: string;

      if (this.user?.userImage?.mimeType) {
        mimeType = this.user?.userImage?.mimeType;
        this.userThumbnail += "." + mimeType.substring(mimeType.indexOf('/') + 1);
      }

      if (this.user.hasActiveLicense) {
        this.seoService.updateMeta(name + " auf beeexpert", "Arbeitet bei " + this.user.company.companyName, true, name + " auf beeexpert", PageTypes.profile, this.userThumbnail, mimeType);
      } else {
        this.seoService.updateMeta(name + " auf beeexpert", "", false, name + " auf beeexpert", PageTypes.profile, this.userThumbnail);
      }
    } else {
      this.seoService.updateMeta("beeexpert - Berater*innen finden, die wirklich zu dir passen * Matching", "Finde mithilfe unseres wissenschaftlichen Matchings sowohl deine als auch die Persönlichkeitsmerkmale unserer zertifizierten Unernehmensberater*innen heraus.", true, "beeexpert - Berater*innen finden, die wirklich zu dir passen", PageTypes.website);
    }
  }

  getCountryName(isoAlpha2Code: string) {
    return countries.getName(isoAlpha2Code, 'de');
  }

  redirectContantPage() {
    this.nav.navigateRoot('/profile/inquiry/' + this.user.userId);
  }

  redirectToMatching() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        source: "profile"
      }
    };

    this.nav.navigateRoot('/profile/bfi10', navigationExtras);
  }

  convertBfiForProgressBar(value: any) {
    return +value * 2 / 10;
  }

  async showEditProfilePopover(ev: any) {
    const popover = await this.popoverController.create({
      component: EditProfilePopoverComponent,
      event: ev,
      translucent: true,
      showBackdrop: false,
    });

    await popover.present();
  }

  async shareProfile() {
    try {
      navigator.share({ url: "https://www.beeexpert.at" + this.router.url, text: "Besuche " + this.user.titlePrepended + " " + this.user.firstName + " " + this.user.lastName + " " + this.user.titleAppended + " auf beeexpert" });
    } catch (error) {
      navigator.clipboard.writeText("https://www.beeexpert.at" + this.router.url).then(async () => {
        const toast = await this.toastController.create({
          message: 'Link in der Zwischenablage gespeichert.',
          duration: 3000,
          icon: 'clipboard-outline',
          cssClass: 'beeex-toast-success'
        });
        toast.present();
      }, async () => {
        const toast = await this.toastController.create({
          message: 'Kopieren des Links fehlgeschlaten.',
          duration: 3000,
          icon: 'close-outline',
          cssClass: 'beeex-toast-failed'
        });
        toast.present();
      });
    }
  }

  compareBfi(val1: number, val2: number) {
    return this.userService.compareTwoUserBfiValue(val1, val2) * 100;
  }

  redirectToMatchingFromUserProfile() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        source: this.router.url
      }
    };

    this.nav.navigateRoot('/profile/bfi10', navigationExtras);
  }

  async trackSocialMediaLinkFollow() {
    await this.trackingService.addTrackingRecord({
      timestamp: new Date(),
      trackingPoint: TrackingPoint.socialMedia,
      userId: this.user.userId,
      companyId: this.user.company?.companyId,
      visitorUserId: this.currentUser?.userId
    }).toPromise();
  }

  isMobile() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }

  async changeFollowing() {
    if (this.currentUser) {
      if (this.followed) {
        this.removeFollowing();
      } else {
        this.addFollowing();
      }
    } else {
      const alert = await this.alertController.create({
        header: 'Bitte melde dich an!',
        message: 'Du musst dich anmelden, um einen/r Berater*in zu folgen.',
        buttons: [
          {
            text: 'Abbrechen',
            role: 'cancel',
            handler: () => { }
          },
          {
            text: 'Anmelden',
            role: 'confirm',
            handler: () => { this.nav.navigateRoot('/login'); }
          }
        ],
      });

      await alert.present();
    }

  }

  async removeFollowing() {
    this.followed = false;
    this.followingLoading = true;

    const toast = await this.toastController.create({
      message: 'Favorit wird entfernt',
      cssClass: 'beeex-toast-success',
    });

    await toast.present();

    try {
      await this.userService.unfollowUser(this.user.userId).toPromise();
    } catch (error) {
      console.error('Fehler beim Entfernen des Favoriten', error);
    } finally {
      toast.dismiss();
    }
    this.followingLoading = false;

  }

  async addFollowing() {
    this.followingLoading = true;

    this.followed = true;

    const toast = await this.toastController.create({
      message: 'Favorit wird hinzugefügt',
      cssClass: 'beeex-toast-success',
    });

    await toast.present();

    try {
      await this.userService.followUser(this.user.userId).toPromise();
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Favoriten', error);
    } finally {
      toast.dismiss();
    }

    this.followingLoading = false;

  }

  navigateToContactConsultant() {
    this.nav.navigateRoot('/profile/inquiry/' + this.user.userId);
  }

  toggleTooltip() {
    this.showTooltip = !this.showTooltip;
  }
}
