import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FilterComponentComponent } from './filter-component.component';

@NgModule({
  declarations: [
    FilterComponentComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
  ],
  exports: [
    FilterComponentComponent,
  ]
})
export class FilterModule { }
